/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import ReferenceDocumentsDetail from '../PMMAssessment/ReferenceDocumentsDetail';
import downloadGreenIcon from '../../images/icon-download-green.png';
import request from '../../services/Service';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import downloadBrownIcon from '../../images/icon-download-brown.png';
import { BaseURL } from '../../Constant';

class PotentialProjectView extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                quickFilterText: null,
                PotentialPrjtGrdDta: [],
                SCID: this.props.Assessmentdetails.ServiceComponentID,
                popUpReferenceDocuments: false,
            };
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.DownloadExcel = this.DownloadExcel.bind(this);
            this.onNavigationClick = this.onNavigationClick.bind(this);
            this.viewProjectPrioritization = this.viewProjectPrioritization.bind(this);
            this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
            this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
            this.DownloadDIGIPortal = this.DownloadDIGIPortal.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            $("#loader-div").show();
            window.addEventListener('resize', this.reSizeGrid, true);
           
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_PPView.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun=(eve)=> {
        try {



            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-6", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName) 
                // }
                var SearchedString = "";
                let contentData = {
                    "PMMProcessID": PMMProcessID,
                    "SearchedString": SearchedString,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                eve.api.showLoadingOverlay();
                request("/PotentialProjects/ViewPotentialProjectDetails", 'POST', contentData)
                    .then(response => {
                        if(response.length!==0){
                            eve.api.hideOverlay();
                        this.setState({ PotentialPrjtGrdDta: response });
                        $("#loader-div").hide();
                        }
                        else{
                            eve.api.showNoRowsOverlay(); 
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
                
            }

            if (PMMProcessDraftID > 0) {                
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-VSA-13", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName) 
                // }
                var SearchedString = "";
                let potentialProjData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "SearchedString": SearchedString,
                    "AsIsSelection": 1,
                    "ProjectSelection": 1,
                    "NA": 1,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }

                eve.api.showLoadingOverlay();
                request("/PotentialProjects/GetPotentialProjectDetails", 'POST', potentialProjData)
                    .then(response => {
                        if(response.length!==0){
                            eve.api.hideOverlay();
                        this.setState({ PotentialPrjtGrdDta: response });
                        $("#loader-div").hide();
                        }
                        else{
                            eve.api.showNoRowsOverlay(); 
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }


            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeReferenceDocumentsPopUp() {
        this.setState({
            popUpReferenceDocuments: false
        });
        $("#loader-div").hide();
    }

    showDownloadReferencePopup() {
        this.setState({
            popUpReferenceDocuments: true
        })
    }
    DownloadDIGIPortal() {
        // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-D-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
        // }
        let excelData = {
            "PMMProcessID": this.props.Assessmentdetails.PMMProcessID,
            "UserName": this.props.enterpriseId,
			"GroupID": this.props.groupId,
			"LanguageID":this.props.languageId
        }
        let jsonexcelData = JSON.stringify(excelData);
        $("#loader-div").show();
        fetch(BaseURL + "/Common/DownloadDIGIPortal", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'charset': 'utf-8',
                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
            },
            body : jsonexcelData
        })
        .then(response => response.blob())
            .then((blob) => {
                const BlobUrl = window.URL.createObjectURL(blob);
                var downLink = document.createElement("a");
                downLink.href = BlobUrl;
                downLink.download = `DIGIPortal.xlsx`;
                document.body.appendChild(downLink);
                downLink.click();
                downLink.remove();
                $("#loader-div").hide();
                alert(i18n.t("filedownloaded"));
                }
            )
            
        // request("/Common/DownloadDIGIPortal", 'POST', excelData)
        //     .then(response => {
        //         var filePath = response.Table1[0].Value;
        //         window.location.href = filePath;
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }

    async DownloadExcel() {
        try {
            // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-D-25", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
            // }
            var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
            var PMMProcessDraftID = this.props.Assessmentdetails.PMMProcessDraftID;

            if (PMMProcessID > 0) {
                let excelData = {
                    "PMMProcessID": this.props.Assessmentdetails.PMMProcessID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                let jsonexcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelTransaction", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonexcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Transaction.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
                
                // await request("/Common/DownloadtoExcelTransaction", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;
                //     })
                //     .catch(error => {
                //         console.log(error);
                //     });
            }

            if (PMMProcessDraftID > 0) {
                // if(!this.props.Assessmentdetails.DealName.toLowerCase().includes("test")){
                // window.tfo_dataevent("PMM-D-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.Assessmentdetails.DealName)
                // }
                var objThis = this;
                let excelData = {
                    "PMMProcessDraftID": PMMProcessDraftID,
                    "DealName": this.props.Assessmentdetails.DealName,
                    "SCName": this.props.Assessmentdetails.SCName,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                let jsonExcelData = JSON.stringify(excelData);
                $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonExcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Draft_.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
            
                // await request("/Common/DownloadtoExcelDraft", 'POST', excelData)
                //     .then(response => {
                //         var filePath = response.Table1[0].Value;
                //         window.location.href = filePath;
                //     })
                //     .catch(error => {
                //         window.addEventListener("beforeunload", objThis.unloadEvent);
                //         console.log(error);
                //     });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.currentTarget.getAttribute('data-content');
            let url = eve.currentTarget.getAttribute('data-url');
            if (navigationPage == "StoryBoardView") {
                this.props.history.push({
                    pathname: url,
                    state: { asToVal: this.props.location.state.asToVal }
                });
            }
            else {
                this.props.history.push(url);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    viewProjectPrioritization() {
        this.props.history.push({
            pathname: "/ProjectPrioritizationView",
            state: { asToVal: this.props.location.state.asToVal }
        });
    }

    render() {

        var PMMProcessID = this.props.Assessmentdetails.PMMProcessID;
        var columnDefs = [
            { headerName: i18n.t('PotentialProjectDraftID'), field: 'PotentialProjectDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('ProjectUniqueReferenceCode'), field: 'ProjectUniqueRefCode', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('SlNo'), field: 'ProjectId', suppressMovable: true, suppressResize: true, width: 100 },
            { headerName: i18n.t('PMMDraftID'), field: 'PMMDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('PMMProcessDraftID'), field: 'PMMProcessDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('lever'), field: 'LeverName', tooltipField: 'LeverName', suppressMovable: true, suppressResize: true, width: 150, autoHeight: true },
            { headerName: i18n.t('IndicativeProjectNamePMM'), field: 'Project', tooltipField: 'Project', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IndicativeTimemonths'), field: 'Time', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('IndicativeImpactonY'), field: 'Impact', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('ShortlistProject'), field: 'ShortlistProject', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('projectDetails'), field: 'ProjectDetails', tooltipField: 'ProjectDetails', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('Timemonths'), field: 'TimeMonths', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('ImpactonY'), field: 'ImpactOnBOI', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('Remarks'), field: 'Remarks', tooltipField: 'Remarks', suppressMovable: true, suppressResize: true, autoHeight: true },
        ];
        var PotentialPrjtData = this.state.PotentialPrjtGrdDta;

        var PotentialPrjtDataGrd = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_PPView" id="grd_PPView"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={columnDefs}
                    rowData={PotentialPrjtData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    quickFilterText={this.state.quickFilterText}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back"><img src={backArrowIcon} /> {i18n.t('GoBackToHome')}</a></Link>
                        <ul className="pagecounter">
                            <li><span title="VIEW PREVIOUS ASSESSSMENT" className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page"><Link to="/ViewPreviousAssessmentHome"></Link></span></li>
                            <li><span title="BOI / KEY BUSINESS METRIC" className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI"><Link to="/BOIView"></Link></span></li>
                            <li><span title="LEADING INDICATOR" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator"><Link to="/LIView"></Link></span></li>
                            <li><span title="PMM ASSESSMENT" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM"><Link to="/PMMAssessmentView"></Link></span></li>
                            <li onClick={this.onNavigationClick} data-url="/StoryBoardView" data-content="StoryBoardView"><span title="STORY BOARD - GAP ASSESSMENT" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="StoryBoardView"><a></a></span></li>
                            <li onClick={this.onNavigationClick} data-url="/PotentialProjectView"><span title="POTENTIAL PROJECTS" className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">...</span></li>
                            <li><span title="PROJECT PRIORITIZATION" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                        </ul>
                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <h4><div className="pull-left">{i18n.t('pOTENTIALPROJECTSVIEW')}</div>
                            <div className="pull-right">
                                        <span class='statustxt-orng-italic' title={i18n.t('OMID') + ': ' + this.props.Assessmentdetails.OMID}> OMID :{this.props.Assessmentdetails.OMID}
                                        {/* </span> &nbsp;&nbsp;||<span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.RegionName}>{this.props.Assessmentdetails.RegionName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.CountryName}>{this.props.Assessmentdetails.CountryName} */}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.Assessmentdetails.OGName}>{this.props.Assessmentdetails.OGName} */}
                                        {/* {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.Assessmentdetails.IndustryName}>{this.props.Assessmentdetails.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.CapabilityName}>{this.props.Assessmentdetails.CapabilityName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.TowerName}>{this.props.Assessmentdetails.TowerName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.SCName}>{this.props.Assessmentdetails.SCName} */}
                                        </span>
                                    </div>
                            </h4>
                            <div className="box-plain">
                                <h4>
                                    <span className="col-md-4 infoblockfilter ">
                                        <label id="lbl_search" className="col-md-3" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input id="txt_quickFilter" type="text" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </span>

                                    <div className="btn-group btn-group-pmmasmt">
                                        <button id="btn_downloadExcel" type="button" className="btn btn-default btn-downld" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                        <button id="btn_showDownloadReferencePopup" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default btn-downld" onClick={this.showDownloadReferencePopup} style={{ "marginLeft": "2px" }}>
                                            <img src={downloadGreenIcon} />
                                        </button>
                                        {(PMMProcessID > 0) ? <button id="btn_showDownloadReferencePopup" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default btn-downld" onClick={this.DownloadDIGIPortal} style={{ "marginLeft": "2px" }}>
                                            <img src={downloadBrownIcon} />
                                        </button> : null}
                                    </div>
                                </h4>
                                <div className="box-plain pmm-asmt-tbl">
                                    {PotentialPrjtDataGrd}
                                    <div className="btn-group-md">
                                        <a type="button" className="btn btn-success" onClick={this.viewProjectPrioritization}>{i18n.t('ViewProjectprioritization')}</a>
                                    </div>
                                </div>
                            </div>
                            {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={i18n.t('all')} SCID={this.state.SCID} />}
                        </div>
                    </section>
                </section>
            </div>
        );
    }
};

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
    }
}


PotentialProjectView = connect(mapStateToProps)(withRouter(withTranslation()(PotentialProjectView)))
export default PotentialProjectView
