/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import btnBackIcon from '../../../images/icon-back-arrow.png';
import keyIcon from '../../../images/key-icon.png';
import request from '../../../services/Service';
import i18n from 'i18next';
import langKeys from '../../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
var recordCount = 0;

class UnlockAccessment extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selectedDetails: [], AssessmentGridData: [],
            lstDeal: [], lstIndustry: [], lstRegion: [], lstCapability: [],
            lstCountry: [], lstTower: [], lstServiceComp: [], lstDlvryLoc: [],
            DealValue: "", IndustryValue: "", RegionValue: "", OGIndustry: "",
            // OG: "", 
             Industry: "",
            CapabilityValue: "", CountryValue: "", TowerValue: "", ServiceCompValue: "", DlvryLocValue: "",
            eblNxtBTn: false, Confirm: false, message: "", DraftID: 0, quickFilterText: null,
            pageNames: []
        };
        this.onNavigationClick = this.onNavigationClick.bind(this);
    }

    CreateEditDetails() {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = `<span><img src=${keyIcon}></span>`;
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {

        try {            
            //window.tfo_event("PMM-MC-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var objThis = this;
            this.setState({ 
            pageNames:this.props.pageName });
            $("#loader-div").show();
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {

                    this.setState({ lstDeal: res.Deal });

                })
                .catch(error => {
                    console.log("Error Encountered");
                });


        }
        catch (error) {
            console.log(error);
        }
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    SelectDeal(val) {

        try {
            this.setState({
                Confirm: false, lstServiceComp: [], ServiceCompValue: "",
                CapabilityValue: "", lstTower: [], TowerValue: "", CountryValue: "", DlvryLocValue: ""
            });
            if (val == null) {
                this.setState({ DealValue: val, eblNxtBTn: false, });
            }
            else {

                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DealValue == "" || prevState.DealValue == null ? 1 : 0) } });
                this.setState({ DealValue: val });
            }
            let ogData = {
                "DealId": val.value,
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetOGBasedOnDeal", 'POST', ogData)
                .then(res => {
                    this.setState({ OGIndustry: res })
                    if (res.OGName == null) {
                        this.setState({ eblNxtBTn: false })
                    }
                    else {
                        this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DealValue == "" || prevState.DealValue == null ? 1 : 0) } });
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

            let capabilityData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": val.value,
            }
            request("/Common/GetCapabilityBasedOnDeal", 'POST', capabilityData)
                .then(res => {

                    this.setState({ lstCapability: res })

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

            this.setState({ lstRegion: [], lstCountry: [], RegionValue: "" });
            request("/MasterScreens/GetRegionBasedOnDeal?DealID="+ val.value+"&Flag=2&GroupID="+this.props.groupId+"&LanguageID="+this.props.languageId+"&LanguageID="+this.props.languageId+"&LanguageID="+this.props.languageId,'GET')
                .then(res => {

                    this.setState({ lstRegion: res });

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

            let dropdownData = {                
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": val.value,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {

                    this.setState({ lstDlvryLoc: res.DeliveryLocation });
                    $("#loader-div").hide();

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectIndustry(val) {

        try {
            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ IndustryValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.IndustryValue == "" || prevState.IndustryValue == null ? 1 : 0) } });
                this.setState({ IndustryValue: val });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectRegion(val) {

        try {
            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ RegionValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.RegionValue == "" || prevState.RegionValue == null ? 1 : 0) } });
                this.setState({ RegionValue: val });
            }
            var dealId = this.state.DealValue;
            request("/MasterScreens/GetCountryBasedOnRegion?RegionId="+val.value +"&DealID="+dealId.value+"&GroupID="+this.props.groupId+"&LanguageID="+this.props.languageId,'GET')
                .then(res => {

                    this.setState({ lstCountry: res });
                    res.length > 0 ? this.setState({ CountryValue: { "label": i18n.t('all'), "value": "0" } }) : this.setState({ CountryValue: "" });

                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log(error);
        }
    }

    SelectCapability(val) {

        try {
            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ CapabilityValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.CapabilityValue == "" || prevState.CapabilityValue == null ? 1 : 0) } });
                this.setState({ CapabilityValue: val });
            }
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerID": 0,
                "DealId": this.state.DealValue.value,
                "CapabilityId": val.value,
            }
            request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                .then(res => {

                    this.setState({ TowerValue: "", lstTower: res, lstServiceComp: [], ServiceCompValue: "", eblNxtBTn: false });

                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log(error);
        }
    }

    SelectCountry(val) {
        try {

            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ CountryValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.CountryValue == "" || prevState.CountryValue == null ? 1 : 0) } });
                this.setState({ CountryValue: val });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectTower(val) {
        try {

            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ TowerValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.TowerValue == "" || prevState.TowerValue == null ? 1 : 0) } });
                this.setState({ TowerValue: val });
            }
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,

                "TowerID": val.value,
                "SCId": 0,
            }
            request("/Common/GetSCBasedOnTower", 'POST', scData)
                .then(res => {

                    this.setState({ ServiceCompValue: "", lstServiceComp: res, eblNxtBTn: false });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectServiceComponent(val) {

        try {
            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ ServiceCompValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.ServiceCompValue == "" || prevState.ServiceCompValue == null ? 1 : 0) } });
                this.setState({ ServiceCompValue: val });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectDlvryLoc(val) {
        try {

            this.setState({ Confirm: false });
            if (val == null) {
                this.setState({ DlvryLocValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DlvryLocValue == "" || prevState.DlvryLocValue == null ? 1 : 0) } });
                this.setState({ DlvryLocValue: val });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    checkAllSelected(prevValue) {
        try {

            this.setState({ Confirm: false });
            let stateCount = 0;

            let mandateValues = prevValue;
            for (var ctrls in this.refs) {
                if (ctrls != "Grd_ViewAssess") {



                    if (this.refs[ctrls].props != undefined) {
                        if (this.refs[ctrls].props.value != "" && this.refs[ctrls].props.value != undefined) {
                            mandateValues += 1
                        }
                    }
                    else {
                        if (this.refs[ctrls].value != "" && this.refs[ctrls].value != undefined || ctrls == "idIndustry") {
                            mandateValues += 1
                        }
                    }

                    stateCount += 1
                }
            }

            let result = mandateValues == stateCount ? true : false;
            return result
        }
        catch (error) {
            console.log(error);
        }
    }

    ValidateMandateFields(reqFrm, DraftID) {
        try {
            let AssesmentMasterDetails = {
                DealId: this.state.DealValue.value,
                RegionId: this.state.RegionValue.value,
                CountryId: this.state.CountryValue.value,
                OGName: this.state.OGIndustry.OGName,
                IndustryName: this.state.OGIndustry.IndustryName,
                ServiceComponentId: this.state.ServiceCompValue.value,
                DeliveryLocationId: this.state.DlvryLocValue.value,
                LoggedInUserId:this.props.enterpriseId,
                PMMProcessDraftID: DraftID,
                FlagId: 1,
                LanguageID:this.props.languageId
            };
            let selectedDetails = {
                DealName: this.state.DealValue,
                Region: this.state.RegionValue,
                Country: this.state.CountryValue,
                OGIndustry: this.state.OGIndustry,
                Capability: this.state.CapabilityValue,
                Tower: this.state.TowerValue,
                ServiceComponent: this.state.ServiceCompValue,
                DeliveryLocationId: this.state.DlvryLocValue,
                PMMProcessDraftID: DraftID,
                AsIs4: 0,
                NoIndProj: 0
            };

            let reqSrc = reqFrm.target.innerHTML.toUpperCase();
            var objThis = this;
            $("#loader-div").show();
            request("/Common/SaveNewAssessment", 'POST', AssesmentMasterDetails)
                .then(res => {

                    if (res.outputmessage.toLowerCase() == "success") {
                        selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;

                        this.setState({ message: res.outputmessage, DraftID: res.ScopeIDGenrated, reqSrc: reqSrc, data: AssesmentMasterDetails, selectedDetails: selectedDetails });
                        request("/Common/SaveNewAssessment", 'POST', AssesmentMasterDetails)
                            .then(res => {

                                selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                                this.props.propsPMMConfigDetails(selectedDetails);
                                objThis.props.history.push("/BOI");

                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                console.log("Error Encountered");
                                $("#loader-div").hide();
                            });
                    }
                    else {
                        selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                        this.setState({ Confirm: true, message: res.outputmessage, DraftID: res.ScopeIDGenrated, reqSrc, data: AssesmentMasterDetails, selectedDetails: selectedDetails });

                    }
                    this.props.propsPMMConfigDetails(selectedDetails);
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    reSizeGrid() {

        this.refs.Grd_ViewAssess.gridOptions.api.sizeColumnsToFit();
    }

    gridReadyFun = (eve) => {
        try {

            eve.api.showLoadingOverlay();

            var SearchedString = "";
            let viewAssessmentDraftData = {
                IsLocked: 1,
                SearchedString: SearchedString,
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId
                
            }
            let objThis = this;
            request("/BOI/ViewAssessmentDraft", 'POST', viewAssessmentDraftData)
                .then(res => {
                    if (res.length !== 0) {
                        eve.api.hideOverlay();
                        res == null ? recordCount = 0 : recordCount = res.length;
                        objThis.setState({ AssessmentGridData: res });

                        $("#loader-div").hide();
                    }
                    else {
                        eve.api.showNoRowsOverlay();
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered")
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t('EnableAssessment')) {
                if (confirm(i18n.t('areyousureyouwanttoenablethisassessment'))) {

                    var objThis = this;
                    var data = e.data;

                    var _that = this;
                    $("#loader-div").show();
                    var data = {
                        LoggedInUserId:this.props.enterpriseId,
                        PMMProcessDraftID: e.data.PMMProcessDraftID,
                        FlagId: 2,
                        LanguageID: this.props.languageId,
                        GroupID:this.props.groupId
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID="+this.props.languageId,'POST',data)
                        .then(res => {


                            alert(i18n.t('selectedassessmentgotenabledsuccessfully'));
                            _that.GetLockedAssessment();
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                            $("#loader-div").hide();
                        });

                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    onQuickFilterText(event) {
        this.setState({ quickFilterText: event.target.value });
    }

    async GetLockedAssessment() {

        try {
            var objThat = this;
            var SearchedString = "";
            let viewAssessmentDraftData ={
                UserName:this.props.enterpriseId,
                IsLocked:1,
                SearchedString:SearchedString,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId
            }
            await request("/BOI/ViewAssessmentDraft", 'POST', viewAssessmentDraftData)
                .then(res => {

                    res == null ? recordCount = 0 : recordCount = res.length;
                    objThat.setState({ AssessmentGridData: res });

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    /*Function prompts a pop up in case on unsaved data*/
    onNavigationClick(eve) {

        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('dealManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            this.props.history.push(url);
        }
        catch (error) {
            console.log(error);
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [

            { headerName: i18n.t('pMMProcessID'), field: 'PMMProcessID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('pMMTransactionID'), field: 'PMMTransactionID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('ClientName'), field: 'DealName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('Market'), field: 'RegionName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('City'), field: 'DeliveryLocationName', suppressMovable: true, suppressResize: true },

            { headerName: i18n.t('Offering/SSG'), field: 'CapabilityName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('Sub Offering'), field: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('Process'), field: 'SCName', suppressMovable: true, suppressResize: true },

            { headerName: i18n.t('LastSavedBy'), field: 'updatedBy', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('LastSavedOn'), field: 'updatedDate', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('EnableAssessment'), cellRenderer: this.CreateEditDetails.bind(this), suppressMovable: true, suppressResize: true },
        ];

        var assessmentGridData = this.state.AssessmentGridData;

        var AssessmentDataGrid = (
            <div style={{ height: '380px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_ViewAssess" enableSorting="true" id="grd_ViewAssessDataGrid"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={assessmentGridData}
                    quickFilterText={this.state.quickFilterText}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )



        return (
            <section className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={btnBackIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>

                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>

                            </li>
                            : null}
{/* 
                        {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null} */}
                        {this.state.pageNames.includes("BOIConfiguration") ? <li>
                            <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                        </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ? <li className="active">
                            <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                        </li>
                            : null}

                    </ul>

                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">

                            <h4 className="btn-group-md">{i18n.t('UnlockDraftAssessment')}
                                <span className="badge">{recordCount}</span>
                                <input type="text" id="txt_searchUnlockAssess" placeholder={i18n.t("search")} className="fright searchBox" data-field='searchOG' onChange={this.onQuickFilterText.bind(this)} />
                            </h4>



                            <div className="AsmtData viewprevassmt">
                                {AssessmentDataGrid}
                            </div>

                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}

UnlockAccessment=connect(mapStateToProps)(withRouter(UnlockAccessment));
export default UnlockAccessment;