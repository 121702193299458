/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';
import downloadIcon from "../../../images/icon-download.png";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.RegionName;

const renderSuggestion = suggestion => <span>{suggestion.RegionName}</span>;

class RegionEntry extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                regionName: "",
                regionId: 0,
                Description: "",
                IsActive: true,
                IsEditActive: false,
                IsConfirmUpdate: false,
                value: '',
                isUpdateExisting: false,
                suggestions: [],
                isEditPopUp: false,
                countriesData: [
                    {
                        Continent: "Asia",
                        CountryName: "India"
                    }, {
                        Continent: "Asia",
                        CountryName: "Bangladesh"
                    }, {
                        Continent: "Asia",
                        CountryName: "China"
                    },
                    {
                        Continent: "Europe",
                        CountryName: "Italy"
                    },
                    {
                        CountryName: "U.K",
                        Continent: "Europe"
                    },
                    {
                        CountryName: "Scotland",
                        Continent: "Europe"
                    },
                    {
                        CountryName: "Germany",
                        Continent: "Europe"
                    },
                    {
                        CountryName: "U.S.A",
                        Continent: "North America"
                    }
                ],
                regionData: [

                ],
                searchCountry: "",
                searchRegion: "",
                sameGroup: true
            };
            this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.checkSelected = this.checkSelected.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            this.onEditCell = this.onEditCell.bind(this);
            this.loadCountries = this.loadCountries.bind(this);
            this.loadRegions = this.loadRegions.bind(this);
            this.saveRegion = this.saveRegion.bind(this);
            this.resetValues = this.resetValues.bind(this);
            this.saveRegionCall = this.saveRegionCall.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.getSuggestions = this.getSuggestions.bind(this);
            this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
            this.populateValues = this.populateValues.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.passValue = this.passValue.bind(this);
            this.onRowSelect = this.onRowSelect.bind(this);
            this.sortBySelect = this.sortBySelect.bind(this);
            this.formatActive = this.formatActive.bind(this);
            this.onSearch = this.onSearch.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            //window.tfo_event("PMM-MC-4", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            this.loadCountries();
            //this.loadRegions();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.country_grid.gridOptions.api.sizeColumnsToFit();
            this.refs.region_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async loadCountries() {
        debugger;
        try {
            debugger;
            var SearchedString = "";
            let regionAndCountriesData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                CountryId:0,
                RegionId:0,
                Flag:1,
                SearchedString:SearchedString,
            }
            await request("/MasterScreens/GetRegionAndCountries", 'POST', regionAndCountriesData)
                .then(res => {
                    $("#loader-div").hide();
                    this.setState({
                        countriesData: res
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async loadRegions() {
        debugger;
        try {
            var SearchedString = "";
            let regionAndCountriesData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CountryId":0,
                "RegionId":0,
                "Flag":2,
                "SearchedString":SearchedString,
            }
            await request("/MasterScreens/GetRegionAndCountries", 'POST', regionAndCountriesData)
                .then(res => {
                    $("#loader-div").hide();

                    this.setState({
                        regionData: res,

                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCheckBoxChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.checked,
                IsEditActive: true
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun=(eve,params)=> {
        this.gridApi = eve.api;


       try{
        $("#loader-div").hide();
          var SearchedString = "";
        let regionAndCountriesData = {
            "UserName":this.props.enterpriseId,
            "GroupID":this.props.groupId,
            "LanguageID":this.props.languageId,
            "CountryId":0,
            "RegionId":0,
            "Flag":2,
            "SearchedString":SearchedString,
        }
        eve.api.showLoadingOverlay();
       request("/Common/SP_GET_AllRegionData",'GET')
        
            .then(res => {
                if(res.length!==0){
                    eve.api.hideOverlay();
                

                this.setState({
                    regionData: res,

                });
                $("#loader-div").hide();
            }
            else{
 eve.api.showNoRowsOverlay();
            }
            })
            .catch(error => {
                console.log("Error Encountered");
                $("#loader-div").hide();
            });
        eve.api.sizeColumnsToFit();

       } 
       catch (error) {
        console.log("Error Encountered");
    }

    }

    createEditCell(params) {
        try {
            let editCell = document.createElement("div");
            editCell.innerHTML = `<span><img src=${editIcon}></span>`;
            editCell.className = "col-md-12 editIcon";
            editCell.width = "100%";
            return editCell;

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onEditCell(eve) {
        try {
            if (eve.colDef.headerName == i18n.t('editRegion')) {
                this.populateValues(eve.data, false);
            }

            ;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(regionVal, isExist) {
        debugger;
        try {
            this.setState({
                value: regionVal.RegionName,
                regionName: regionVal.RegionName,
                Description: regionVal.Description,
                regionId: regionVal.RegionID,
                IsActive: regionVal.IsActive,
                isUpdateExisting: true,
                sameGroup: (regionVal.CreatedByGroupID == parseInt(this.props.groupId)
                    ) ? true : false,
            });
            if (regionVal.GroupID == parseInt(this.props.groupId)
                ) {
                let countries = regionVal.CountryIds.split(',');
                this.refs.country_grid.gridOptions.api.deselectAll();
                this.refs.country_grid.gridOptions.api.forEachNode((node) => {
                    countries.map((country, index) => {
                        if (node.data.CountryId == country) {
                            node.setSelected(true);
                        }
                    })
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkSelected(eve, params) {
        this.props.editFun(true);
    }

    onRowSelect(eve) {
        try {
            eve.node.data["IsSelected"] = eve.node.selected ? true : false;
            this.setState({
                IsEditActive: true
            })
            this.sortBySelect();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //On Change of user inputs
    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || '',
                IsEditActive: true
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    resetValues() {
        try {
            this.refs.country_grid.gridOptions.api.deselectAll();
            this.setState({
                regionName: "",
                Description: "",
                value: "",
                regionId: 0,
                IsActive: true,
                searchCountry: "",
                searchRegion: "",
                IsEditActive: false,
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                sameGroup: true
            });
            setTimeout(() => {
                this.props.editFun(false);
            }, 300);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveRegion(eve) {
        try {
            let { regionName, regionId, Description, IsActive, value } = this.state;
            let countries = this.refs.country_grid.gridOptions.api.getSelectedRows();
            let countryIds = '';
            let operation = eve && regionId != 0 ? 2 : 1;
            if (value == "" || value == null || value == undefined || countries.length == 0) {
                alert(i18n.t('fillRegionandCountrydetails'));
            } else {
                if (Description == "") {
                    alert(i18n.t('pleaseenterDescription'));
                    return false;
                }
                countries.map((country, index) => {
                    if (index == 0) {
                        countryIds = country.CountryId;
                    } else {
                        countryIds = countryIds + ',' + country.CountryId;
                    }
                });
                let regionObj = {
                    RegionName: value,
                    RegionID: eve ? regionId : 0,
                    Description: Description,
                    IsActive: IsActive,
                    CountryIds: countryIds,
                    UserName:this.props.enterpriseId,
                    GroupID:this.props.groupId,
                    LanguageID:this.props.languageId,
                    Operation: operation,
                }

                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && regionName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return;
                } else {
                    this.saveRegionCall(regionObj);

                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveRegionCall(regionObj) {
        try {

            let regionData = regionObj;
            let regionnamevalidation = inputValidator(regionData.RegionName)
            let descriptionvalidation = inputValidator(regionData.Description)

            if(regionnamevalidation === false && descriptionvalidation === false){
            $("#loader-div").show();
            request("/MasterScreens/UpsertRegionCountriesMapping", 'POST', regionData)
                .then(res => {
                    $("#loader-div").hide();
                    if (res.Outputcode == -1) {
                        alert(res.outputmessage)
                    } else {
                        this.loadRegions();
                        this.props.editFun(false);
                        this.resetValues();
                        alert(res.outputmessage);
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                    alert(error.statusText)
                });

            }

            else{
                alert(i18n.t("PleaseEntervalidValuesintheFields"));
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                return [];
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.regionData.filter(region => regex.test(region.RegionName));
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue,
                IsEditActive: true
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });

    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            let value = event.target.value.toLowerCase();
            this.state.regionData.forEach(region => {
                if (region.RegionName.toLowerCase() == value) {
                    this.populateValues(region);
                }
                return region;
            })

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value,
                regionId: value ? this.state.regionId : 0
            });
            if (value) {
                this.saveRegion(value);
            } else {
                this.saveRegion();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Sorts the table so that the selected countries are displayed in the first page */
    sortBySelect() {
        try {
            let sort = [{
                colId: 'IsSelected',
                sort: 'desc'
            }]
            this.refs.country_grid.gridOptions.api.setSortModel(sort);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }
    
    onBtExport = () => {

        const rowData = [];
       
       this.gridApi.forEachNode(node => rowData.push(node.data));
       console.log("this.gridApi",this.gridApi);
       
       
        const newRowData = rowData.map(({region_name,country_name }) => ({ region_name,country_name}));

        console.log("rowData",newRowData);
        const ws = XLSX.utils.json_to_sheet(newRowData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'Region_data.xlsx');
         
    };
    
 
    render() {
        console.log("rowData",this.state.regionData);
        debugger;
        let { t } = this.props;
        let countriesGrid, regionCountriesGrid, countryColDefs, regionCountryColDefs;
        // countryColDefs = [
        //     { headerName: t('continent'), field: 'Continent', tooltipField: 'Continent', suppressMovable: true, suppressResize: true, autoHeight: true },
        //     { headerName: t('country'), field: 'CountryName', tooltipField: 'CountryName', suppressMovable: true, suppressResize: true, autoHeight: true },
        //     {
        //         headerName: t('selectAll'), checkboxSelection: true, suppressMenu: true, suppressSorting: true, headerCheckboxSelection: true,
        //         headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, suppressResize: true, autoHeight: true
        //     },
        //     { headerName: t('isSelected'), field: 'IsSelected', hide: true, suppressMovable: true, suppressResize: true, autoHeight: true },
        // ];

        regionCountryColDefs = [
            // { headerName: t('editRegion'), cellRenderer: this.createEditCell, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: t('region'), field: 'region_name', tooltipField: 'RegionName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: t('countries'), field: 'country_name', tooltipField: 'CountryName', suppressMovable: true, suppressResize: true, autoHeight: true },
            //{ headerName: t('continents'), field: 'Continent', tooltipField: 'Continent', suppressMovable: true, suppressResize: true, autoHeight: true },
            //{ headerName: t('description'), field: 'Description', tooltipField: 'Description', suppressMovable: true, suppressResize: true, autoHeight: true },
            //{ headerName: t('active'), field: 'IsActive', valueFormatter: this.formatActive, suppressResize: true, tooltipField: 'Active', autoHeight: true }

        ];

        //countriesGrid = (
            // <div className="ag-theme-material grid-div country-name-grid">
            //     <AgGridReact ref="country_grid" id="grd_Country"
            //         columnDefs={countryColDefs}
            //         rowData={this.state.countriesData}
            //         enableSorting={true}
            //         enableFilter={true}
            //         enableColResize={false}
            //         pagination={false}
            //         paginationPageSize="10"
            //         rowmodeltype="pagination"
            //         rowSelection='multiple'
            //         suppressRowClickSelection={true}
            //         quickFilterText={this.state.searchCountry}
            //         onRowSelected={this.onRowSelect}
            //         onSelectionChanged={this.checkSelected}
            //         onGridReady={this.gridReadyFun}
            //         localeText={AgGridLocale()}
            //         overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            // </div>
        //);

        regionCountriesGrid = (
            <div className="ag-theme-material grid-div country-name-grid">
                <AgGridReact ref="region_grid" id="grd_Reg"
                    columnDefs={regionCountryColDefs}
                    rowData={this.state.regionData}
                    enableSorting={true}
                    enableFilter={true}
                    enableColResize={false}
                    paginationPageSize="10"
                    rowmodeltype="pagination"
                    rowSelection='multiple'
                    quickFilterText={this.state.searchRegion}
                    onGridReady={this.gridReadyFun}
                    onCellClicked={this.onEditCell}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`}  />
            </div>
        );

        let isDisabled = (this.state.isUpdateExisting && !this.state.sameGroup);
        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            disabled: isDisabled
        }

        return (
            <div>
                {/* <div className="box"> */}
                    {/* <div className="box-in"> */}
                        {/* {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"Region"} editValue={this.state.regionName}
                            passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />} */}
                        {/* <div className="row"> */}
                            {/* <div className="col-sm-4 form-horizontal"> */}
                                {/* <div className="form-group"> */}
                                    {/* <label className="control-label col-sm-4" id="lbl_regName">{i18n.t('regionName')}:<span className="mandatory-cls">*</span></label> */}
                                    {/* <div className="col-sm-8">
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps} />

                                    </div> */}
                                {/* </div> */}
                            {/* </div> */}
                            {/* <div className="col-sm-5 form-horizontal"> */}
                                {/* <div className="form-group"> */}
                                    {/* <label className="control-label col-sm-4" id="lbl_regDescription">{i18n.t('description')}:<span className="mandatory-cls">*</span></label>
                                    <div className="col-sm-8">
                                        <textarea ref="Description" id="textArea_descReg" data-field="Description" name="Description" rows="1" className="form-control" placeholder="" type="" value={this.state.Description} onChange={this.onInputChange} disabled={isDisabled} />
                                    </div> */}
                                {/* </div> */}
                            {/* </div> */}
                            {/* <div className="col-sm-3 form-horizontal"> */}
                                {/* <div className="form-group"> */}

                                    {/* <label className="control-label col-sm-4" id="lbl_isAct">{i18n.t('isActive')}:</label>
                                    <div className="col-sm-8">
                                        <input type="checkbox" id="chk_isActRegion" ref="IsActive" data-field="IsActive" checked={this.state.IsActive} onChange={this.onCheckBoxChange} />
                                    </div> */}
                                {/* </div> */}
                            {/* </div> */}
                        {/* </div> */}

                        {/* <div className="country-grid grid-parent">
                            <div className="search-div">
                                <h4 className="fleft">{i18n.t('cOUNTRIES')}<span className="mandatory-cls">*</span></h4>
                                <input type="text" id="txt_searchCountries" placeholder={i18n.t('searchCountries')} className="fright searchBox" data-field='searchCountry' value={this.state.searchCountry} onChange={this.onSearch} />
                            </div>
                            <div className="box-plain pmm-asmt-tbl">
                                {countriesGrid}
                            </div>
                        </div> */}
                        {/* <div className="fright btn-group-md">
                            <a type="button" className="btn btn-success" onClick={this.saveRegion}>{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.resetValues}>{i18n.t('clear')}</a>
                        </div> */}
                    {/* </div> */}
                {/* </div> */}
                <div className="btn-group btn-group-pmmasmt">
                  <button
                    type="button"
                    id="btn_downloadAssesment"
                    title={i18n.t("DownloadbelowlistinExcelformat")}
                    className="btn btn-default btn-downld"
                    onClick={this.onBtExport}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
                <div className="country-grid grid-parent">
                    <div className="search-div">
                        <h4 className="fleft">{i18n.t('rEGIONS')}</h4>
                        <input type="text" id="txt_searchRegions" placeholder={i18n.t('searchRegions')} className="fright searchBox" data-field='searchRegion' value={this.state.searchRegion} onChange={this.onSearch} />
                    </div>
                    <div className="box-plain pmm-asmt-tbl">
                        {regionCountriesGrid}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.roleName,
        groupName:state.groupName
    }
}

RegionEntry = connect(mapStateToProps)(withTranslation()(RegionEntry));
export default withRouter(RegionEntry);
