/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import { connect } from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';
import { BaseURL } from '../../../Constant';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import downloadIcon from "../../../images/icon-download.png";

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.ServiceComponentName;

const renderSuggestion = suggestion => <span>{suggestion.ServiceComponentName}</span>;

function CreateEditBox(params) {
    try {
        let eDiv = document.createElement("div");
        let groupId=params.colDef.cellRendererParams.groupId;
        if (params.data.GroupID ==parseInt(groupId) 
            ) {
            eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
        } else {
            eDiv.innerHTML = ``;
        }
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}


class ServiceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lstTower: [],
            lstCapability: [],
            TowerValue: [],
            CapabilityValue: [],
            ServiceComponentGridData: [],
            isEdit: 1,
            errors: {},
            selectedRow: {},
            ServiceComponentId: 0,
            Description: '',
            value: '',
            suggestions: [],
            isEditPopUp: false,
            IsConfirmUpdate: false,
            isUpdateExisting: false,
            ServiceComponentName: "",
            IsActive: true

        };
        this.SelectTower = this.SelectTower.bind(this);
        this.SelectCapability = this.SelectCapability.bind(this);
        this.ChangeIsActive = this.ChangeIsActive.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.SaveSCDetails = this.SaveSCDetails.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.passValue = this.passValue.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.GetDropDownsValueTower = this.GetDropDownsValueTower.bind(this);
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-10", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])   
            window.addEventListener('resize', this.reSizeGrid, true);
            this.GetDropDownsValue();
            this.FetchConnServiceComponentGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.sc_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    async GetDropDownsValue() {
        try {
            var objThis = this;
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            await request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {

                    let CapabilityByGroup = []
                    CapabilityByGroup = res.Capability != null || res.Capability != "" ? res.Capability : [];
                    objThis.setState({
                        lstCapability: CapabilityByGroup

                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    GetDropDownsValueTower(capID) {

        try {
            var objThis = this;

            $("#loader-div").show();
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerID": 0,
                "DealId": 0,
                "CapabilityId": capID,
                "DeliveryLocationId": 0,
            }
            request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                .then(res => {
                    $("#loader-div").hide();
                    var towerByGroup = [];
                    towerByGroup = res != null || res != "" ? res.filter(item => item.GroupID == this.props.groupId
                        ) : []
                    this.setState({ lstTower: towerByGroup });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



     FetchConnServiceComponentGrid() {
        try {
            var objThis = this;
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerID": 0,
                "SCId": 0,
            }
            fetch(
                BaseURL + "/Common/GetAllProcess",
                {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                  },
                }
              )
              .then((response) => {
                return response.json();
              })
                .then(res => {

                    this.setState({ ServiceComponentGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveSCDetails(eve) {
        try {
            $("#loader-div").show();
            const obj = this;
            let { ServiceComponentId, ServiceComponentName, IsActive, CapabilityValue, TowerValue, IsDeleted, value, Description } = obj.state;
            let Operation = eve && ServiceComponentId != 0 ? 2 : 1;
            let scvalidation = inputValidator(value)
            let descriptionvalidation = inputValidator(Description)

            if(scvalidation === false && descriptionvalidation === false){
            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && ServiceComponentName != "") {
                    $("#loader-div").hide();
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t('updateExistingorCreateNew'));
                }
                const requestData = obj.state.selectedRow;
                requestData["UserName"] =this.props.enterpriseId;
                requestData["Operation"] = Operation;
                requestData["ServiceComponentId"] = eve ? ServiceComponentId : 0;
                requestData["ServiceComponentName"] = value;
                requestData["Description"] = Description;


                let CapValue = this.state.CapabilityValue.value;
                let TowValue = this.state.TowerValue.value;

                if (CapValue == undefined) {
                    requestData["CapabilityId"] = this.state.CapabilityValue[0].value;
                }
                else {
                    requestData["CapabilityId"] = this.state.CapabilityValue[0].value;
                    requestData["CapabilityName"] = this.state.CapabilityValue[0].label;
                }

                if (TowValue == undefined) {
                    requestData["TowerId"] = this.state.TowerValue[0].value;

                }
                else {
                    requestData["TowerId"] = this.state.TowerValue[0].value;
                    requestData["TowerID"] = this.state.TowerValue[0].value;
                    requestData["TowerName"] = this.state.TowerValue[0].label;
                }

                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["UpdateConfirmation"] = false;
                requestData["GroupID"] =this.props.groupId;
                requestData["LanguageID"] =this.props.languageId;

                var requestDataCopy = Object.assign({}, requestData);
                requestDataCopy.UpdateConfirmation = true;

                request("/MasterScreens/UpsertSC", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode == 0 && res["outputmessage"] == "BOI/LI config deactivated.") {
                            if (confirm(i18n.t('ExistingBOILIconfigAreYouSureyouwanttoupdate'))) {

                                request("/MasterScreens/UpsertSCAfterConfirmation", 'POST', requestDataCopy)
                                    .then(res => {
                                        if (res.Outputcode != -1) {

                                            alert(res["outputmessage"]);
                                            obj.FetchConnServiceComponentGrid();
                                            obj.OnCancel();
                                            obj.props.editFun(false);
                                        }
                                        else {
                                            alert(res["outputmessage"]);
                                        }
                                    })
                                    .catch(error => {
                                        console.log("Error Encountered");
                                    });
                            }
                            else {

                            }
                        }
                        else {
                            if (res.Outputcode != -1) {

                                alert(res["outputmessage"]);
                                obj.FetchConnServiceComponentGrid();
                                obj.OnCancel();
                                obj.props.editFun(false);
                            }
                            else {
                                alert(res["outputmessage"]);
                            }
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
        }
        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));

        }
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};


            if (!this.state.value) {
                errors.ServiceComponentName = i18n.t('pleaseEnterServiceComponentName');
                alert(i18n.t('pleaseEnterServiceComponentName'));
                formIsValid = false;
            }
            if (this.state.CapabilityValue.length == 0 && formIsValid == true) {
                errors.CapabilityValue = i18n.t('pleaseSelectaCapability');
                alert(i18n.t('pleaseSelectaCapability'));
                formIsValid = false;
            }

            if (this.state.TowerValue.length == 0 && formIsValid == true) {
                errors.TowerName = i18n.t('pleaseSelectaTower');
                alert(i18n.t('pleaseSelectaTower'));
                formIsValid = false;
            }
            if (!this.refs.Description.value && formIsValid == true) {
                errors.Description = i18n.t('pleaseEnterDescription');
                alert(i18n.t('pleaseEnterDescription'));
                formIsValid = false;
            }
            this.setState({ errors: errors });

            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerID": 0,
                "SCId": 0,
            }
            params.api.showLoadingOverlay();
            request("/Common/GetServiceComponentDetails", 'POST', scData)
                .then(res => {
                    if(res.length!==0){
                        params.api.hideOverlay();
                        this.setState({ ServiceComponentGridData: res });
                        $("#loader-div").hide();
                    }

                    else{
                        params.api.showNoRowsOverlay();
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        this.setState({ TowerValue: val });;
    }

    SelectCapability(val) {
        try {
            if (val == null) {
                this.setState({
                    CapabilityValue: [],
                    TowerValue: []
                })
            } else {
                this.setState({ CapabilityValue: val, TowerValue: [] });
                this.GetDropDownsValueTower(val[0].value);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {

        try {
            if (e.colDef.headerName == i18n.t('edit')) {
                if (e.data.GroupID ==parseInt(this.props.groupId) 
                    ) {
                    this.populateValues(e.data);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(sc) {
        try {
            this.GetDropDownsValueTower(sc.CapabilityID);
            this.setState({
                isEdit: 2,
                selectedRow: sc,
                ServiceComponentId: sc.ServiceComponentId,
                ServiceComponentName: sc.ServiceComponentName,
                IsActive: sc.IsActive,
                IsDeleted: sc.IsDeleted,
                value: sc.ServiceComponentName,
                CapabilityValue: this.state.lstCapability.filter(item => item.value == sc.CapabilityID),
                TowerValue: this.state.lstTower.filter(item => item.value == sc.TowerID),
                Description: sc.Description,
                isUpdateExisting: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    ChangeIsActive(event) {
        try {
            this.setState({ IsActive: event.target.checked });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel() {
        try {
            this.setState({
                TowerValue: [],
                CapabilityValue: [],
                IsActive: false,
                isEdit: 1,
                errors: '',
                value: '',
                ServiceComponentId: 0,
                ServiceComponentName: '',
                Description: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                searchSC: '',
                lstTower: ''

            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.ServiceComponentGridData.filter(ServiceComponent => ServiceComponent.GroupID == parseInt(this.props.groupId) 
                    );
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.ServiceComponentGridData.filter(ServiceComponent => regex.test(ServiceComponent.ServiceComponentName) && ServiceComponent.GroupID == parseInt(this.props.groupId) 
                );
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        try {
            this.setState({
                suggestions: []
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionSelected(event, { suggestion }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event) {
        try {
            let value = event.target.value.toLowerCase();
            this.state.ServiceComponentGridData.forEach(sc => {
                if (sc.ServiceComponentName.toLowerCase() == value) {
                    if (sc.GroupID == parseInt(this.props.groupId) ) {
                        this.populateValues(sc);
                    }
                }
                return sc;
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveSCDetails(value);
            } else {
                this.SaveSCDetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onBtExport = () => {

        const rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        if(rowData.length>0){
       
        const newRowData = rowData.map(({main_offering_name,offering_ssg_name,sub_offering_name,process_name}) => ({ main_offering_name,offering_ssg_name,sub_offering_name,process_name}));

        console.log("rowData",newRowData);
        const ws = XLSX.utils.json_to_sheet(newRowData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'process_data.xlsx');
        }
    };
    render() {

        var columnDefs = [];
        columnDefs = [
            // { headerName: i18n.t('edit'), field: '', cellRenderer: CreateEditBox,cellRendererParams:{groupId:this.props.groupId}, suppressMovable: true, suppressResize: true, },
           
            //{ headerName: i18n.t('Description'), field: 'Description', tooltipField: i18n.t('description'), suppressMovable: true, suppressResize: true, },
           
            { headerName: i18n.t('capabilityId'), field: 'main_offering_id', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('MainOffering'), field: 'main_offering_name', tooltipField: 'MainOfferingName', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('towerId'), field: 'offering_ssg_id', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('Offering/SSG'), field: 'offering_ssg_name', tooltipField: 'OfferingName', suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('SubofferingID'), field: 'sub_offering_id', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('SubOffering'), field: 'sub_offering_name', tooltipField: i18n.t('SubOfferingName'), suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('SCID'), field: 'om_ll4_id', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('ProcessName'), field: 'process_name', tooltipField: i18n.t('servicecomponentName'), suppressMovable: true, suppressResize: true, }
            // { headerName: i18n.t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            // { headerName: i18n.t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            // { headerName: i18n.t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            // { headerName: i18n.t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            // { headerName: i18n.t('isActive'), field: 'IsActive', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, }
        ];



        var ServiceComponentDataGrid = (
            <div style={{ height: '300px'}} className="ag-theme-material grid-div">
                <AgGridReact ref="sc_grid" id="grd_ServiceComponent"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={this.state.ServiceComponentGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    quickFilterText={this.state.searchSC}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"ServiceComponentName"} editValue={this.state.ServiceComponentName}
                            passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />}
                        <div className="col-lg-5 form-horizontal">
                            {/* <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_scName">{i18n.t('serviceComponentName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="ServiceComponentName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />

                                </div>
                            </div> */}


                            {/* <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_scCapability" >{i18n.t('capabilityName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_capabilityName" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />
                                </div>
                            </div> */}

                            {/* <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_scTowerName" >{i18n.t('towerName')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="lbl_scTowerName" labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('selectPlace')} />
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_scDescription">{i18n.t('description')}:<span style={{ color: "red" }}>*</span></label>
                                <div className="col-sm-8">
                                    <textarea ref="Description" id="txtArea_desc" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label className="control-label col-sm-4" id="lbl_scIsActive">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input ref="IsActive" id="chk_isActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} defaultChecked={true} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="fright btn-group-md">
                            <a type="button" className="btn btn-success" onClick={this.SaveSCDetails.bind(this)} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel.bind(this)} >{i18n.t('clear')}</a>
                        </div> */}
                        <div className="btn-group btn-group-pmmasmt">
                  <button
                    type="button"
                    id="btn_downloadAssesment"
                    title={i18n.t("DownloadbelowlistinExcelformat")}
                    className="btn btn-default btn-downld"
                    onClick={this.onBtExport}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('Process Details')} </h4>
                            <input type="text" id="txt_searchSC" placeholder={i18n.t('search Process')} className="fright searchBox" data-field='searchSC' value={this.state.searchSC} onChange={this.onSearch} />
                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {ServiceComponentDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        enterpriseId:state.enterpriseId,
        languageId:state.languageId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName
    }
}

ServiceComponent=connect(mapStateToProps)(withRouter(ServiceComponent))
export default ServiceComponent;