/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import NavigationConfirmation from '../NavigationConfirmation';
import StoryBoardDetail from './StoryBoardDetail';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas'
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import pptIcon from '../../images/powerpoint-icon.png';
import PptxGenJS from 'pptxgenjs';
import StandardizationScore_vs_PMMScore from './StandardizationScore_vs_PMMScore';
import ReferenceDocumentsDetail from '../PMMAssessment/ReferenceDocumentsDetail';
import downloadGreenIcon from '../../images/icon-download-green.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';




class StoryBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popUpDisplay: false,
            toURL: '',
            navigationPage: "",
            popUpSBT: false,
            PMMDraftID: 0,
            popUpStandardScoreScatterChart: false,
            selectedMetric: "",
            SCID: this.props.configDetails.ServiceComponent,
            popUpReferenceDocuments: false,
            dynamicLeverData: [],
            rawData: [],
            chartList: []
        };

        this.checkLength = this.checkLength.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.SaveStoryBoard = this.SaveStoryBoard.bind(this);
        this.unloadEvent = this.unloadEvent.bind(this);
        this.closeSBTPopUp = this.closeSBTPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.StandardScoreScatterChart = this.StandardScoreScatterChart.bind(this);
        this.closeStandardScoreScatterChartPopUp = this.closeStandardScoreScatterChartPopUp.bind(this);
        this.graphAsIsStdEve = this.graphAsIsStdEve.bind(this);
        this.graphToBeStdEve = this.graphToBeStdEve.bind(this);
        this.RadarNodeSize = this.RadarNodeSize.bind(this);
        this.RadarNodeShape = this.RadarNodeShape.bind(this);
        this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
        this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
        this.dynamicLeverGraphData = this.dynamicLeverGraphData.bind(this);
        this.downloadPPT = this.downloadPPT.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.unloadEvent);
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    dynamicLeverGraphData = (response) => {
        try {
            var objThis = this;
            var leverWithValue = [];
            let barChartLeverLabels = [];
            let radarDataObj = {};
            var asisAvgcount=0;
            var tobeAvgcount=0;   
            let radarDataArray = [];
            var sumForOverAllAsIs = 0;
            var sumForOverAllToBe = 0;
            let standardizationSum = 0, standardizationCount = 0;
            let standardizationToBeSum = 0, standardizationToBeCount = 0;
            var pmmData = response.Table1;
            var distinctLevers = [...new Set(pmmData.map(x => x.LeverName))];

            for (var i = 0; i < distinctLevers.length; i++) {

                var data = pmmData.filter(x => x.LeverName == distinctLevers[i])
                var asisSum = 0;
                var tobeSum = 0;
                var asisCount = 0;
                var tobeCount = 0; 
                let labelsLever = [];
                let pmmDraftID = [];
                let asIsvalue = [];
                let toBeValue = [];
                let projectName = [];
                let idLever = [];

                data.map((eachdata, index) => {
                    asisSum += Number(eachdata.AsIsPMMScore), asisCount += 1;
                    tobeSum += Number(eachdata.TobePMMScore), tobeCount += 1;
                    if (eachdata.IsStandardization == true) {
                        standardizationSum += Number(eachdata.AsIsPMMScore), standardizationCount += 1;
                        standardizationToBeSum += Number(eachdata.TobePMMScore), standardizationToBeCount += 1;
                    }

                    let labelArr;
                    eachdata.id = `M${index + 1}`;
                    labelArr = eachdata.MaturityElement.substr(0, 24) + "...";

                    labelsLever.push(labelArr);
                    pmmDraftID.push(eachdata.PMMDraftID);
                    asIsvalue.push(eachdata.AsIsPMMScore);
                    toBeValue.push(eachdata.TobePMMScore);
                    projectName.push(eachdata.MaturityElement);
                    idLever.push(eachdata.id);
                    labelsLever = objThis.checkLength(labelsLever);
                })

                idLever = objThis.checkLength(idLever);

                let radarData = distinctLevers[i];
                radarDataObj[radarData] = { LeverName: distinctLevers[i], PMMTransactionID: pmmDraftID, labels: idLever, asIS: asIsvalue, toBe: toBeValue, projectName: projectName }
                radarDataArray = Object.values(radarDataObj);

                var StoryBoardGridDataID = "StoryBoardGridData" + [i];

                this.setState({ [StoryBoardGridDataID]: data });

                leverWithValue.push(
                    {
                        'LeverName': distinctLevers[i],
                        'RadarElementId': 'radar' + [i],
                        'LeverStoryBoardGrid': { [distinctLevers[i] + 'StoryBoardGrid']: '' }

                    }
                )

                barChartLeverLabels.push(distinctLevers[i]); //for BarGraph

                asisAvgcount += (asisCount === 0) ? 0 : (asisCount);
                tobeAvgcount += (tobeCount === 0) ? 0 : (tobeCount);
                sumForOverAllAsIs +=  parseFloat(asisSum)
                sumForOverAllToBe +=  parseFloat(tobeSum)

            }


            let AsIsPMMScore = Number(sumForOverAllAsIs / asisAvgcount).toFixed(2);

            let ToBePMMScore = Number(sumForOverAllToBe / tobeAvgcount).toFixed(2);

            objThis.setState({ dynamicLeverData: leverWithValue })

            //BarGraph Data
            let asIsArr = [];
            let toBeArr = [];
            let tableAsIsresponse = response.Table[0];
            let tableToBeresponse = response.Table[1];
            ['AvgType', 'DateOfAssessment', 'ProjectId', 'RegionName'].forEach(e => delete tableAsIsresponse[e]);
            ['AvgType', 'DateOfAssessment', 'ProjectId', 'RegionName'].forEach(e => delete tableToBeresponse[e]);
            let convertToAsIsArray = Object.values(tableAsIsresponse); //Converts object to array
            let convertToToBeArray = Object.values(tableToBeresponse);
            asIsArr = convertToAsIsArray;
            toBeArr = convertToToBeArray;
            asIsArr.unshift(AsIsPMMScore);
            toBeArr.unshift(ToBePMMScore);
            let dataObj = { asIs: asIsArr, toBe: toBeArr };
            objThis.setState({ dynamicBarChartLabels: barChartLeverLabels });
            objThis.state.dynamicBarChartLabels.unshift(i18n.t('overall'));
            objThis.BarChart(dataObj);
            objThis.RadarChart(radarDataArray);


        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {        
        // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-TPA-5", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
        // }
        $("#loader-div").show();
        window.addEventListener("beforeunload", this.unloadEvent);
        window.addEventListener('resize', this.reSizeGrid, true);
        var SearchedString = "";
        let storyBoardData = {
            PMMProcessDraftID:this.props.configDetails.PMMProcessDraftID,
            SearchedString:SearchedString,
            UserName:this.props.enterpriseId,				
			GroupID: this.props.groupId,
			LanguageID:this.props.languageId
        }
        request("/PMM/GetStoryBoardDetails", 'POST', storyBoardData)
            .then(response => {
                let asIsArr = []
                let toBeArr = []
                this.dynamicLeverGraphData(response);

                $("#loader-div").hide();
            })
            .catch(error => {
                console.log(error);
                $("#loader-div").hide();
            });
    }

    reSizeGrid() {
        this.refs.grd_storyboard.gridOptions.api.sizeColumnsToFit();
    }


    unloadEvent(e) {
        let confirmationMessage = i18n.t('leavingthispagewillresultinlossunsaveddata');
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
    }

    onCellClicked(params) {
        if (params.colDef.field == "id" || params.colDef.field == "LeverName" || params.colDef.field == "MaturityElement" || params.colDef.field == "AsIsPMMScore" || params.colDef.field == "TobePMMScore") {
            let selectedLever = 0;
            selectedLever = params.data.PMMDraftID;

            this.setState({
                popUpSBT: true,
                PMMDraftID: selectedLever,
                selectedMetric: params.data
            });

        }
    }

    closeReferenceDocumentsPopUp() {
        this.setState({
            popUpReferenceDocuments: false
        });
        $("#loader-div").hide();
    }

    showDownloadReferencePopup() {
        this.setState({
            popUpReferenceDocuments: true
        })
    }

    checkLength(arrData) {
        let arrLen = arrData.length;
        if (arrLen <= 2) {
            arrData.push("*NA");
        }
        if (arrLen <= 1) {
            arrData.push("*NA");
        }
        return arrData;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.sizeColumnsToFit();
    }

    graphAsIsStdEve() {
        let asLocalval, asLocalvalArr;
        if (sessionStorage.getItem("asToValGraph")) {
            asLocalval = sessionStorage.getItem("asToValGraph")
            asLocalvalArr = JSON.parse(asLocalval);
        }
        if (this.props.location.state == undefined) {
            if (sessionStorage.getItem("asToValGraph") !== null) {
                var node = asLocalvalArr.map((d) => {

                    return "#E65100"

                })

                return node;
            }
        } else {
            if (this.props.location.state.asToVal != undefined) {
                var node = this.props.location.state.asToVal[4].map((d) => {

                    return "#E65100"

                })

            }

            else {
                let graphStd = JSON.parse(sessionStorage.getItem("asToValGraph"));
                var node = graphStd.map((d) => {

                    return "#E65100"

                })
            }

            return node;
        }

    }

    graphToBeStdEve() {

        let asLocalval, asLocalvalArr;
        if (sessionStorage.getItem("asToValGraph")) {
            asLocalval = sessionStorage.getItem("asToValGraph")
            asLocalvalArr = JSON.parse(asLocalval);
        }
        if (this.props.location.state == undefined) {
            if (sessionStorage.getItem("asToValGraph") !== null) {
                var node = asLocalvalArr.map((d) => {

                    return "#607D8B"

                })

                return node;
            }
        } else {
            if (this.props.location.state.asToVal != undefined) {
                var node = this.props.location.state.asToVal[4].map((d) => {

                    return "#607D8B"

                })
            }

            else {
                let graphStd = JSON.parse(sessionStorage.getItem("asToValGraph"));
                var node = graphStd.map((d) => {

                    return "#607D8B"

                })
            }

            return node;
        }
    }

    RadarNodeSize() {
        let asLocalval, asLocalvalArr;
        if (sessionStorage.getItem("asToValGraph")) {
            asLocalval = sessionStorage.getItem("asToValGraph")
            asLocalvalArr = JSON.parse(asLocalval);
        }
        if (this.props.location.state == undefined) {
            if (sessionStorage.getItem("asToValGraph") !== null) {
                var nodeSize = asLocalvalArr.map((a) => {

                    return 2;

                })
                return nodeSize;
            }
        } else {

            if (this.props.location.state.asToVal != undefined) {
                var nodeSize = this.props.location.state.asToVal[4].map((a) => {

                    return 2;

                })
            }

            else {
                let graphStd = JSON.parse(sessionStorage.getItem("asToValGraph"));
                var nodeSize = graphStd.map((d) => {

                    return 2;

                })
            }

            return nodeSize;
        }
    }

    RadarNodeShape() {
        let asLocalval, asLocalvalArr;
        if (sessionStorage.getItem("asToValGraph")) {
            asLocalval = sessionStorage.getItem("asToValGraph")
            asLocalvalArr = JSON.parse(asLocalval);
        }
        if (this.props.location.state == undefined) {
            if (sessionStorage.getItem("asToValGraph") !== null) {

                var nodeStyle = asLocalvalArr.map((a) => {

                    return "circle";

                })
                return nodeStyle;
            }
        } else {
            if (this.props.location.state.asToVal != undefined) {
                var nodeStyle = this.props.location.state.asToVal[4].map((a) => {

                    return "rect";
                })
            }

            else {
                let graphStd = JSON.parse(sessionStorage.getItem("asToValGraph"));
                var nodeStyle = graphStd.map((d) => {

                    return "rect";

                })
            }

            return nodeStyle;
        }
    }



    RadarChart(rawData) {
        //For Radar Chart
        Chart.defaults.global.elements.point.radius = 2;
        Chart.defaults.global.plugins.datalabels.display = false;
        this.setState({ rawData });
        rawData.map((row, index) => {

            var radarData = {
                PMMTransactionID: row.PMMTransactionID,
                labels: row.labels,
                datasets: [{
                    type: 'radar',
                    label: i18n.t('asIsPMMScore'),
                    data: row.asIS,
                    fullLabel: row.projectName,
                    borderColor: "#FFA500",
                    backgroundColor: 'transparent',
                    pointBorderColor: this.graphAsIsStdEve(),
                    pointBackgroundColor: this.graphAsIsStdEve(),
                    pointRadius: this.RadarNodeSize(),
                    pointStyle: this.RadarNodeShape()

                },
                {
                    type: 'radar',
                    label: i18n.t('toBePMMScore'),
                    data: row.toBe,
                    fullLabel: row.projectName,
                    borderColor: "#007BA7",
                    backgroundColor: 'transparent',
                    pointBorderColor: this.graphToBeStdEve(),
                    pointBackgroundColor: this.graphToBeStdEve(),
                    pointRadius: this.RadarNodeSize(),
                    pointStyle: this.RadarNodeShape()
                }]
            }

            var radarContentID = "radar" + (index);
            var radar_ctx = document.getElementById(radarContentID).getContext('2d');
       
            var radarChart1 = new Chart(radar_ctx, {
                type: 'radar',
                data: radarData,
                options: {
                    scaleShowValues: true,
                    legend: false,
                    legendCallback: function (chart) {
                        let customLegend = [];
                        let chartData = chart.data.datasets;
                        if (chartData[0].fullLabel.length <= 1) {
                            customLegend.push('<ul class="ulStyle">');
                            customLegend.push('<li class="liStyle">');
                            customLegend.push('<span>' + i18n.t('the') + ' </span>' + row.LeverName + '<span> ' + i18n.t('Charthasonly') + ' <strong>' + i18n.t('onedatapoint') + '</strong>' + i18n.t('plsDontConsiderNAasdatapoint') + '</span>');
                            customLegend.push('</li>');
                            customLegend.push('</ul>');
                        } else if (chartData[0].fullLabel.length == 2) {
                            customLegend.push('<ul class="ulStyle">');
                            customLegend.push('<li class="liStyle">');
                            customLegend.push('<span>' + i18n.t('the') + ' <span>' + row.LeverName + '<span> ' + i18n.t('Charthasonly') + ' <strong>' + i18n.t('twodatapoints') + '</strong>' + i18n.t('plsDontConsiderNAasdatapoint') + '</span>');
                            customLegend.push('</li>');
                            customLegend.push('</ul>');
                        } else {
                            customLegend.push('<span></span>');
                        }

                        return customLegend.join('');
                    },
                    title: {
                        display: false,
                        text: this.state.dynamicLeverData.map((l) => {
                            return (l.LeverName);
                        }),
                        fontSize: 14,
                        fontStyle: 600,
                        fontColor: "#000",
                        fontFamily: "OpenSans-Regular"
                    },
                    scale: {
                        ticks: {
                            beginAtZero: true,
                            min: -1,
                            max: 4,
                            stepSize: 1,
                            fontStyle: 600,
                            fontColor: "#000000",
                        },
                        pointLabels: {
                            fontStyle: 100,
                            fontColor: "#000000",
                        }
                    },
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            label: function (tooltipItem, data) {

                                return "";
                            },
                            title: function (tooltipItem, data) {
                                let title = data.datasets[tooltipItem[0].datasetIndex].fullLabel[tooltipItem[0].index] + " - " + tooltipItem[0].yLabel;
                                return title;
                            }
                        }
                    }
                }
            });
            var levername = row.LeverName.split(/\s/).join('');

            $("#" + levername + "-legend").html(radarChart1.generateLegend());

            document.getElementById(radarContentID).onclick = (evt) => {
                let points = radarChart1.getElementsAtEvent(evt);
                if (points[0]) {
                    let data = points[0]['_chart'].config.data;
                    let index = points[0]['_index'];


                    let pmmDraftID = 0;
                    pmmDraftID = data.PMMTransactionID[index];

                    this.setState({
                        PMMDraftID: pmmDraftID
                    });
                    this.setState({
                        popUpSBT: true
                    });
                }
            };
        })


    }

    BarChart(rawData) {
        Chart.defaults.global.plugins.datalabels.display = false;
        var bar_ctx = document.getElementById('bar').getContext('2d');
        var colorDataRed = [];
        var colorDataOrange = [];
        var colorDataYellow = [];
        var colorDataGreen = [];
        for (var i = 0; i <= this.state.dynamicBarChartLabels.length - 1; i++) {
            colorDataRed.push(2);
            colorDataOrange.push(1);
            colorDataYellow.push(0.5);
            colorDataGreen.push(0.5);
        }

        var barChartData = {
            labels: this.state.dynamicBarChartLabels,
            datasets: [
                {
                    type: 'line',
                    label: i18n.t('toBePMMScore'),
                    pointStyle: "star",
                    data: rawData.toBe,
                    radius: 10,
                    pointRadius: 10,
                    pointHoverRadius: 15,
                    borderWidth: 3,
                    hoverBorderWidth: 3,
                    borderSkipped: "bottom",
                    backgroundColor: "#1E88E5",
                    borderColor: "#1E88E5",
                    showLine: false

                },
                {
                    type: 'line',
                    label: i18n.t('asIsPMMScore'),
                    pointStyle: "triangle",
                    data: rawData.asIs,
                    radius: 10,
                    pointRadius: 10,
                    pointHoverRadius: 15,
                    backgroundColor: "black",
                    borderColor: "black",
                    showLine: false

                },
                {
                    type: 'bar',
                    label: 'Scale',
                    stack: 0,
                    data: colorDataRed,
                    backgroundColor: "#ed6a6a",
                    hoverBackgroundColor: "#ed6a6a"
                },
                {
                    type: 'bar',
                    label: 'Scale',
                    stack: 0,
                    data: colorDataOrange,
                    backgroundColor: "#ff9802",
                    hoverBackgroundColor: "#ff9802"
                },
                {
                    type: 'bar',
                    label: 'Scale',
                    stack: 0,
                    data: colorDataYellow,
                    backgroundColor: "#ffe826",
                    hoverBackgroundColor: "#ffe826"
                },
                {
                    type: 'bar',
                    label: 'Scale',
                    stack: 0,
                    data: colorDataGreen,
                    backgroundColor: "#65f26b",
                    hoverBackgroundColor: "#65f26b"
                }


            ]
        }

        window.bar_chart = new Chart(bar_ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                scales: {
                    xAxes: [{
                        display: true,
                        stacked: true,
                        barThickness: 80,
                        categoryPercentage: 2,
                        barPercentage: 0.5,
                        gridLines: {
                            display: false
                        },
                        color: "#000000",
                        ticks: {
                            fontSize: 14,
                            fontStyle: 600,
                            fontColor: "#000",
                            fontFamily: "OpenSans-Regular"
                        }
                    }],
                    yAxes: [{
                        labels: {
                            show: true
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4.5,
                            stepSize: 1,
                            callback: function (label) {
                                label = label == "4.5" ? "" : label;
                                return label;
                            },
                            fontColor: "#000",
                            fontFamily: "OpenSans-Regular"
                        },
                        gridLines: {
                            display: false
                        },
                        color: "#000000"
                    }]
                },
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        usePointStyle: true,
                        padding: 20,
                        fontStyle: 100,
                        fontColor: "#000000",
                        fontFamily: "OpenSans-Regular",
                        filter: function (item, chart) {
                            return !item.text.includes('Scale');
                        }
                    }
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label;
                            if (tooltipItem.datasetIndex == 0 || tooltipItem.datasetIndex == 1) {
                                label = data.datasets[tooltipItem.datasetIndex].label + " " + tooltipItem.yLabel
                            } else {
                                label = "";
                            }
                            return label;
                        }
                    }
                },
            }
        });
    }


    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                    .then(response => {
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            alert(error.statusText);
                            $("#loader-div").hide();
                        });
                    this.props.history.push(url);
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveStoryBoard() {
        try {
            var AsIsNoIndProj = {
                AsIs4: 0,
                NoIndProj: 0
            }
            this.props.propsSetAsIsNoIndProj(AsIsNoIndProj);
            this.props.history.push({
                pathname: "/PotentialProject",
                AsIsProps: sessionStorage.getItem("PMMScore"),
                state: sessionStorage.getItem("asToVal")
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



    downloadPDF() {

    }

    downloadPPT() {
        //For whole page export
        try {                    
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-D-8", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.props.configDetails.DealName.label)
            // }
            window.scrollTo(0, 0);

            var radarCharts = document.querySelector('#radarChart');
            var chartList = radarCharts.querySelectorAll('.radar');
            var ppt = new PptxGenJS();

            html2canvas(document.getElementById("bar")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');

                var slides = ppt.addNewSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 5.0 })

            });

            for (var i = 0; i < chartList.length - 1; i++) {

                html2canvas(document.getElementById(chartList[i].id)).then(canvas_whole => {
                    let wholePag = canvas_whole.toDataURL();
                    let wholePagURI = wholePag.split(':');
                    var slides = ppt.addNewSlide();
                    slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 5.0 });

                });
            }
            for (var i = chartList.length - 1; i < chartList.length; i++) {
                html2canvas(document.getElementById(chartList[i].id)).then(canvas_whole => {
                    let wholePag = canvas_whole.toDataURL();
                    let wholePagURI = wholePag.split(':');
                    var slides = ppt.addNewSlide();
                    slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 5.0 });

                    ppt.save(i18n.t('wholeStoryBoard'));

                });

            }

        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    closeSBTPopUp() {
        this.setState({
            popUpSBT: false
        });
    }

    StandardScoreScatterChart() {
        try {
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-Ass-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            this.setState({
                popUpStandardScoreScatterChart: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeStandardScoreScatterChartPopUp() {
        try {
            this.setState({
                popUpStandardScoreScatterChart: false

            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    render() {

        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('ID'), field: 'id', cellStyle: { border: '1px solid lightgray' }, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('lever'), field: 'LeverName', cellStyle: { border: '1px solid lightgray' }, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('maturityElement'), field: 'MaturityElement', cellStyle: { border: '1px solid lightgray' }, width: 1100, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', cellStyle: { border: '1px solid lightgray' }, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('toBePMMScore'), field: 'TobePMMScore', cellStyle: { border: '1px solid lightgray' }, suppressMovable: true, suppressResize: true, autoHeight: true },
        ];

        this.state.dynamicLeverData.forEach((l, index) => {
            var gridID = l.LeverName + "StoryBoardGrid";
            var gridData = "StoryBoardGridData" + index;
            return (l.LeverStoryBoardGrid[gridID] = (
                <div style={{ width: '1000px' }} className="ag-theme-material padding5">
                    <div className="storyboardgrid">
                        <AgGridReact ref="grd_storyboard" id="storyboard_grd"
                            paginationPageSize="10"
                            enableColResize={true}
                            onGridReady={this.onGridReady.bind(this)}
                            columnDefs={columnDefs}
                            rowData={this.state[gridData]}
                            headerHeight="45"
                            gridAutoHeight={true}
                            onCellEditingStarted={this.onOverallEdit}
                            rowmodeltype="pagination"
                            localeText={AgGridLocale()}
                            onCellClicked={this.onCellClicked.bind(this)}
                            domLayout='autoHeight'
                        />
                    </div>
                </div>
            )); 
        })


        return (
            <section className="content">
                <div className="innerpage-hd"><span className="menu2" data-content="HOME" data-url="/Home"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" />{i18n.t('bACKTOHOME')}</a></span>
                    <ul className="pagecounter">

                        <li onClick={this.onNavigationClick} data-url="/CreateNewAssessment"><span title={i18n.t("takeNewAssessment")} className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Take New Assessment" data-url="/CreateNewAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/BOI"><span title={i18n.t("BOIKeyBusinessMetric")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/LeadingIndicator"><span title={i18n.t("lEADINGINDICATOR")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/PMMAssessment"><span title={i18n.t("pMMASSESSMENT")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment"></span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard">...</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} url={this.state.toURL} navigationPage={this.state.navigationPage} isEditAcitve={false} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}
                </div>
                <section className="bodydata">
                    <div className="bodydata-full story-board-div">
                        <h4><div className="pull-left">{i18n.t('sTORYBOARDGAPASSESSMENT')}</div>
                        <div className="pull-right">
                                    <span class='statustxt-orng-italic'  title={i18n.t('OMID') + ': ' + this.props.configDetails.OMID}> OMID : {this.props.configDetails.OMID} </span>
                                        {/* <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                                         &nbsp;&nbsp;|| */} 
                                         

                                    </div>
                        </h4>

                        <h4 className="btn-group-md">
                            <div className="btn-group-pmmasmt pull-right">
                                <button id="btn_downloadReferenceDocumentsForThisAssessment" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default btn-downld pull-right" onClick={this.showDownloadReferencePopup}>
                                    <img src={downloadGreenIcon} />
                                </button>
                            </div>
                            <a id="ppt" type="button" title={i18n.t("downloadgraphsinppt")} className="btn btn-info pull-right" onClick={this.downloadPPT}><img src={pptIcon} /> {i18n.t('downloadasPPT')}</a>
                            <a type="button" value="Standardization Score Vs. PMM Score" className="btn btn-info pull-right" onClick={this.StandardScoreScatterChart}> {i18n.t('StandardizationScorePMMScore')}</a>

                        </h4>
                        <div className="box-plain">
                            {this.state.popUpSBT && <StoryBoardDetail show={this.state.popUpSBT} closeCallback={this.closeSBTPopUp} PMMProcessID={0} PMMTransactionID={0} PMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} PMMDraftID={this.state.PMMDraftID} />}
                            <div id="story-board-section" className="box">
                                <div className="row" >
                                    <div className="col-sm-12" style={{ "margin": "auto" }}>
                                        <div id="customLegendDiv"></div>
                                        <canvas id="bar" width="600" height="200"> </canvas>
                                    </div>
                                    <div className="col-sm-2">
                                        <ul className="barChartLegend">
                                            <li className="liStyle">
                                                <span style={{ background: "black" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span>{i18n.t('asIsPMMScore')} </span>
                                            </li>
                                            <li className="liStyle">
                                                <span style={{ background: "#1E88E5" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span> {i18n.t('toBePMMScore')} </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* {Render Content Here} */}

                                <div id="radarChart" className="row chartMargins">
                                    <div className="row">

                                        {this.state.dynamicLeverData.map((l) => {
                                            return <Row id={l.LeverName} className="radar">

                                                <Row className="justify-content-md-center">
                                                    <div className="col-md-4">

                                                        <h4 style={{ marginTop: "40px" }}>{l.LeverName}</h4>

                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col>

                                                        <div className="col-md-4">
                                                            <canvas id=
                                                                {l.RadarElementId}
                                                                width="400" height="270"></canvas>
                                                        </div>

                                                    </Col>
                                                    <Col>
                                                        <div id="box-plain pmm-asmt-tbl" className="storyBoardBox">
                                                            {l.LeverStoryBoardGrid[l.LeverName + 'StoryBoardGrid']}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="col-md-4" id={l.LeverName.split(/\s/).join('') + "-legend"}></div>
                                                </Row>
                                            </Row>
                                        })}

                                    </div>



                                </div>
                                <div id="radarLegend" className="row">
                                    <ul className="ulStyle">
                                        <li className="liStyle">
                                            <span style={{ background: "#FFA500" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span> {i18n.t('asIsPMMScore')} </span>
                                            <span style={{ background: "#007BA7" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span> {i18n.t('toBePMMScore')} </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="btn-group-md">
                            <a type="button" className="btn btn-danger">{i18n.t('save')}</a>
                            <a type="button" className="btn btn-success" onClick={this.SaveStoryBoard}>{i18n.t('gENERATEPOTENTIALPROJECTS')}</a>
                        </div>
                        {this.state.popUpStandardScoreScatterChart && <StandardizationScore_vs_PMMScore show={this.state.popUpStandardScoreScatterChart} closeCallback={this.closeStandardScoreScatterChartPopUp} asToValData={sessionStorage.getItem("asToVal")} />}
                        {this.state.popUpSBT && <StoryBoardDetail show={this.state.popUpSBT} closeCallback={this.closeSBTPopUp} PMMProcessID={0} PMMTransactionID={0} PMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} PMMDraftID={this.state.PMMDraftID} />}
                        {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={i18n.t('all')} SCID={this.state.SCID} />}
                    </div>
                </section>
            </section >
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
     }
}


export default connect(mapStateToProps)(withRouter(StoryBoard));
