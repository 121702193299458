/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import PotentialPopup from './PotentialPopup';
import $ from 'jquery';
import NavigationConfirmation from '../NavigationConfirmation';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import ReferenceDocumentsDetail from '../PMMAssessment/ReferenceDocumentsDetail';
import downloadGreenIcon from '../../images/icon-download-green.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { inputValidator } from '../../Utils/InputElementValidation';
import { BaseURL } from '../../Constant';



var numericStatus = false;

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        let circle = document.createElement('div');
        circle.className = "circleDiv";
        spanValue.textContent = params.value;
        switch (Number(params.value)) {
            case 1:
                circle.style.backgroundColor = "#d01a1a"
                break
            case 2:
                circle.style.backgroundColor = "#cd7825"
                break
            case 3:
                circle.style.backgroundColor = "#cd7825"
                break
            case 4:
                circle.style.backgroundColor = "#80bc59"
                break
        }

        this.eGui.appendChild(circle);
        this.eGui.appendChild(spanValue);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function createCopy() {
    try {
        this.eGui = document.createElement('div');
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCopy.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.title = i18n.t("ClickcopyIndicativeProjectNameProject");
        spanValue.className = "glyphicon glyphicon-paste";
        this.eGui.appendChild(spanValue);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCopy.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function CreateTextbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.style.height = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.init = function (params) {
    try {
        var textboxElement = document.createElement("input");
        textboxElement.type = "textbox";
        textboxElement.style.width = "100%";
        textboxElement.style.height = "100%";
        this.eGui.appendChild(textboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.onChange = function (params) {

};

function CreateDropdown() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.className = "row";
        this.eGui.width = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.init = function (params) {
    try {
        var dropDownElement = document.createElement("select");
        dropDownElement.id = params.rowIndex;
        dropDownElement.className = "col-md-10";
        let asIsValue = [i18n.t('yes'), i18n.t('no')]
        asIsValue.forEach(ddValues => { return dropDownElement.options[asIsValue.indexOf(ddValues)] = new Option(ddValues, asIsValue.indexOf(ddValues)) });
        dropDownElement.selectedIndex = 1;
        dropDownElement.addEventListener('change', function (event) {

        });
        this.eGui.appendChild(dropDownElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class PotentialProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditAcitve: false,
            popUpDisplay: false,
            toURL: '',
            navigationPage: "",
            quickFilterText: null,
            asIs4: 0, noIndProj: 0, naStatus: 0,
            PotentialProjectGridData: [], popUP: false, sampleGridData: [], lstLever: [], LeverValue: "",
            asToVal: [],
            SCID: this.props.configDetails.ServiceComponent,
            popUpReferenceDocuments: false
        };
        this.SavePotentialProjects = this.SavePotentialProjects.bind(this);
        this.onOverallEdit = this.onOverallEdit.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.onCheckboxChecked = this.onCheckboxChecked.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.unloadEvent = this.unloadEvent.bind(this);
        this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
        this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
    }
    componentDidMount() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-TPA-6", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label) 
            // }
            var asIs4Stat = 0;
            var noIndProjStat = 0;
            if (this.props.AsIsNoInd != undefined) {
                if(this.props.AsIsNoInd.AsIs4 != 0){
                    asIs4Stat = 1;
                }
                if(this.props.AsIsNoInd.NoIndProj != 0){
                    noIndProjStat = 1;
                }
                this.setState({ asIs4: this.props.AsIsNoInd.AsIs4, noIndProj: this.props.AsIsNoInd.NoIndProj });
            }
            window.addEventListener("beforeunload", this.unloadEvent);
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
            var SearchedString = "";
            var AsIsSelection = asIs4Stat;
            var ProjectSelection = noIndProjStat;
            let dropdownData = {
                "ID": 0,
                "DealId": 0,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            this.setState({ PotentialProjectGridData: this.props.dropDownsValue, LeverValue: { value: 0, label: i18n.t('all') }, asToVal: this.props.location.state.asToVal });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    unloadEvent(e) {
        try {
            let confirmationMessage = i18n.t('leavePage');
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.grd_ppDetails.gridOptions.api.sizeColumnsToFit();
            this.refs.grd_sampleppDetails.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun = (eve) => {
        try {
            var asIs4Stat = 0;
            var noIndProjStat = 0;

            var SearchedString = "";
            var AsIsSelection = asIs4Stat;
            var ProjectSelection = noIndProjStat;

            if (this.props.AsIsNoInd != undefined) {
                if(this.props.AsIsNoInd.AsIs4 != 0){
                    asIs4Stat = 1;
                }
                if(this.props.AsIsNoInd.NoIndProj != 0){
                    noIndProjStat = 1;
                }
                this.setState({ asIs4: this.props.AsIsNoInd.AsIs4, noIndProj: this.props.AsIsNoInd.NoIndProj });
            }

            let potentialProjData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "SearchedString": SearchedString,
                "AsIsSelection": AsIsSelection,
                "ProjectSelection": ProjectSelection,
                "NA": 1,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }

            eve.api.showLoadingOverlay();

            request("/PotentialProjects/GetPotentialProjectDetails", 'POST', potentialProjData)
                .then(response => {
                    if (response.length !== 0) {
                        eve.api.hideOverlay();

                        this.setState({ PotentialProjectGridData: response, LeverValue: { value: 0, label: i18n.t('all') }, asToVal: this.props.location.state.asToVal });

                    }
                    else {
                        eve.api.showNoRowsOverlay();
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectLever(val) {
        try {
            this.setState({ LeverValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SavePotentialProjects(e) {
        try {
            var validRemark = true;
            var asIs4Status = this.refs.AsIs4.checked ? 1 : 0;
            var noIndProj = this.refs.noIndProj.checked ? 1 : 0;
            var AsIsNoIndProj = {
                AsIs4: asIs4Status,
                NoIndProj: noIndProj
            }
            this.props.propsSetAsIsNoIndProj(AsIsNoIndProj);
            $("#loader-div").show();
            let evtSrc = e.target.text.trim();
            this.refs.grd_ppDetails.gridOptions.api.stopEditing();
            let grdData = this.refs.grd_ppDetails.gridOptions.rowData;

            grdData.filter(item => item.hasOwnProperty('TimeMonths'))
                .map((item, i) => {
                    var TimeMonths = item.TimeMonths;
                    var ImpactOnBOI = item.ImpactOnBOI;
                    var ProjectDetails = item.ProjectDetails;
                    var Remarks = item.Remarks;
                    let RemarksValidation = inputValidator(Remarks);
                    let ProjectDetailsValidation = inputValidator(ProjectDetails);
                    if (RemarksValidation || ProjectDetailsValidation) {
                        validRemark = false;
                    }

                    if(!isNaN(parseFloat(TimeMonths)) && isFinite(TimeMonths)){
                        numericStatus = null;
                    }
                    else{
                        numericStatus = true;
                    }
                    if(!isNaN(parseFloat(ImpactOnBOI)) && isFinite(ImpactOnBOI)){
                        numericStatus = null;
                    }
                    else{
                        numericStatus = true;
                    }
                });
            if (numericStatus == true) {
                alert(i18n.t("PlentnumericvalTimemonthsImpactY"));
                $("#loader-div").hide();
                return false;
            }

            let selectedpotential = grdData.filter(row => row.ShortlistProject.trim() == i18n.t('yes'));
            let totalNoOfRecords = selectedpotential.length;
            let validPrjtDetails = selectedpotential.filter(row => (row.ProjectDetails != "" && row.TimeMonths != "" && row.ImpactOnBOI != "")).length;

            this.setState({
                isEditAcitve: false
            })
            if (totalNoOfRecords != 0) {
                if (totalNoOfRecords == validPrjtDetails) {

                    if (validRemark) {
                        var PotentialProjects = {
                            "ppDetails": this.refs.grd_ppDetails.gridOptions.rowData
                        };

                        request("/PotentialProjects/SavePotentialProjectDetails?LanguageID=" + this.props.languageId, 'POST', PotentialProjects)
                            .then(res => {
                                if (res.outputmessage == i18n.t('success')) {
                                    alert(i18n.t("PotentialProjectSavedSuccessfully"));
                                    $("#loader-div").hide();
                                    if (evtSrc != i18n.t('save')) {
                                        this.props.history.push({
                                            pathname: "/ProjectPrioritization",
                                            AsIsProps: this.props.location.AsIsProps,
                                            state: { asToVal: this.props.location.state.asToVal }
                                        });
                                    }

                                }
                            })
                            .catch(error => {
                                console.log(error);
                                $("#loader-div").hide();
                            });
                    }
                    else {
                        alert(i18n.t("PleaseEntervalidValuesintheFields"));
                        $("#loader-div").hide();
                    }
                }
                else {
                    alert(i18n.t("PlfillProjectDetailsTime"));
                    $("#loader-div").hide();
                }
            }
            else {
                alert(i18n.t("plselectatleastoneshortlistproject"));
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onSelectionChanged() {
        try {
            $("#loader-div").show();
            var SearchedString = "";

            let contentData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "PMMDraftId": this.refs.grd_ppDetails.api.getSelectedRows()[0].PMMDraftID,
                "SearchedString": SearchedString,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/PotentialProjects/GetPotentialProjectStaticContent", 'POST', contentData)
                .then(response => {
                    this.setState({ pmmdetails: true, sampleGridData: response });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.gridApi.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.headerName == i18n.t('Timemonths') || params.colDef.headerName == i18n.t('ImpactonY')) {
                var valid = (params.value.match(/^[\d.]+$/));
                if (!valid) {
                    alert(i18n.t("Plenternumericvalue"));
                    return false;
                }
                else {
                    numericStatus = false;
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOverallEdit() {
        try {
            this.setState({
                isEditAcitve: true,
                popUpDisplay: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'Post', data)
                        .then(res => {
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            alert(error.statusText);
                            $("#loader-div").hide();
                        });
                    this.props.history.push(url);
                }
                else {
                    if (navigationPage == "Storyboard") {
                        this.props.history.push({
                            pathname: url,
                            state: { asToVal: this.props.location.state.asToVal }
                        });
                    }
                    else {
                        this.props.history.push(url);
                    }

                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(params) {
        try {
            if (params.colDef.headerName == i18n.t('copy')) {
                params.data.ProjectDetails = params.data.Project;
                this.refs.grd_ppDetails.gridOptions.api.refreshCells();
                this.setState({
                    isEditAcitve: true,
                    popUpDisplay: false
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeReferenceDocumentsPopUp() {
        this.setState({
            popUpReferenceDocuments: false
        });
        $("#loader-div").hide();
    }

    showDownloadReferencePopup() {
        this.setState({
            popUpReferenceDocuments: true
        })
    }

    onCheckboxChecked(evt) {
        try {

            var asIs4Status = this.refs.AsIs4.checked ? 1 : 0;
            var noIndProj = this.refs.noIndProj.checked ? 1 : 0;

            this.setState({ asIs4: asIs4Status, noIndProj: noIndProj })
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
            var SearchedString = "";
            let dropdownData = {
                "ID": 0,
                "DealId": 0,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let potentialProjData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "SearchedString": SearchedString,
                "AsIsSelection": asIs4Status,
                "ProjectSelection": noIndProj,
                "NA": 1,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }

            Promise.all([
                // dropdown values will be fetched from redux.
                request("/PotentialProjects/GetPotentialProjectDetails", 'POST', potentialProjData)
            ])
                .then(response => {
                    this.setState({
                        lstLever: this.props.dropDownsValue,
                        PotentialProjectGridData: response[0],
                        LeverValue: { value: 0, label: i18n.t('all') },
                        pmmdetails: false
                    });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    DownloadExcel() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-D-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            window.removeEventListener("beforeunload", this.unloadEvent);
            let excelData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "DealName": this.props.configDetails.DealName.label,
                "SCName": this.props.configDetails.ServiceComponent.label,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonExcelData = JSON.stringify(excelData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonExcelData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `ProcessMaturityModel_Draft_.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // request("/Common/DownloadtoExcelDraft", 'POST', excelData)
            //     .then(response => {
            //         var filePath = response.Table1[0].Value;
            //         window.location.href = filePath;

            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var sample1 = [];
        var sample2 = [];
        let enterpriseId = this.props.enterpriseId
        sample1 = this.state.sampleGridData.map(x => x.AsIsPMMScore === -1 ?
            x.AsIsPMMScore = "NA" : x.AsIsPMMScore);
        sample2 = this.state.sampleGridData.map(x => x.TobePMMScore === -1 ?
            x.TobePMMScore = "NA" : x.TobePMMScore);
        var LoggedInUser = enterpriseId;
        var columnDefs = [];
        let columnDefs2 = [
            { headerName: i18n.t('lever'), field: 'LeverName', tooltipField: 'LeverName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('KPA'), field: 'KPA', tooltipField: 'KPA', suppressMovable: true, suppressResize: true, autoHeight: true, hide: false },
            { headerName: i18n.t('metricImpacted'), field: 'BOILIName', tooltipField: 'BOILIName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('maturityElement'), field: 'MaturityElement', tooltipField: 'MaturityElement', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('Base'), field: 'Base', tooltipField: 'Base', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('Enhanced'), field: 'Enhanced', tooltipField: 'Enhanced', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('AboveAverage'), field: 'AboveAverage', tooltipField: 'AboveAverage', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('HighPerformance'), field: 'HighPerformance', tooltipField: 'HighPerformance', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('CurrentMetricValue'), field: 'CurrentMetricValue', tooltipField: 'CurrentMetricValue', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', tooltipField: 'AsIsPMMScore', suppressMovable: true, suppressResize: true, cellRenderer: createCircelText },
            { headerName: i18n.t('FutureStateMetricValue'), field: 'FutureStateMetricValue', tooltipField: 'FutureStateMetricValue', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('toBePMMScore'), field: 'TobePMMScore', tooltipField: 'TobePMMScore', suppressMovable: true, suppressResize: true, cellRenderer: createCircelText },
            { headerName: i18n.t('Remarks'), field: 'Remarks', tooltipField: 'Remarks', suppressMovable: true, suppressResize: true, autoHeight: true }
        ];
        columnDefs = [
            { headerName: i18n.t('PotentialProjectDraftID'), field: 'PotentialProjectDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('ProjectUniqueReferenceCode'), field: 'ProjectUniqueRefCode', suppressMovable: true, suppressResize: true, hide: true },
            {
                headerName: i18n.t('SlNo'), field: 'ProjectId', suppressMovable: true, suppressResize: true, cellRenderer: function (params) {

                    params.node.data.loggedInUserId = LoggedInUser;
                    return params.node.data.ProjectId;
                }
            },
            { headerName: i18n.t('PMMDraftID'), field: 'PMMDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('PMMProcessDraftID'), field: 'PMMProcessDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('lever'), field: 'LeverName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IndicativeProjectNamePMM'), field: 'Project', tooltipField: 'Project', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('IndicativeTimemonths'), field: 'Time', suppressMovable: true, suppressResize: true },
            {
                headerName: i18n.t('IndicativeImpactonY'), field: 'Impact', cellStyle: function (params) {

                    params.node.data.loggedInUserId = enterpriseId;
                    var background = "";
                    if (params.node.data.IsAutoCalclulate == true) {
                        background = "#ffc200b8";
                    }
                    return {
                        background: background
                    };
                }, suppressMovable: true, suppressResize: true
            },
            {
                headerName: i18n.t('ShortlistProject'), field: 'ShortlistProject', editable: true, cellEditor: 'agSelectCellEditor', suppressMovable: true, suppressResize: true, cellEditorParams: {
                    values: [i18n.t('yes'), i18n.t('no')]
                }
            },
            {
                headerName: i18n.t('projectDetails'), field: 'ProjectDetails', tooltipField: 'ProjectDetails', suppressMovable: true, suppressResize: true, editable: true, cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: function (params) {
                    return {
                        maxLength: 1000,
                        rows: 5,
                        cols: 40
                    }
                }
            },
            { headerName: i18n.t('Timemonths'), field: 'TimeMonths', suppressMovable: true, suppressResize: true, editable: true },
            { headerName: i18n.t('ImpactonY'), field: 'ImpactOnBOI', suppressMovable: true, suppressResize: true, editable: true },
            {
                headerName: i18n.t('Remarks'), field: 'Remarks', suppressMovable: true, suppressResize: true, editable: true, cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: function (params) {
                    return {
                        maxLength: 1000,
                        rows: 5,
                        cols: 40
                    }
                }
            },
            { headerName: i18n.t('copy'), field: '', suppressMovable: true, suppressResize: true, cellRenderer: createCopy, width: 100 },
        ];

        var PotentialProjectDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_ppDetails" id="ppDetails_grd"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.PotentialProjectGridData}
                    rowSelection="single"
                    headerHeight="45"
                    enableSorting={false}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    quickFilterText={this.state.quickFilterText}
                    rowmodeltype="pagination"
                    singleClickEdit={true}
                    onCellEditingStarted={this.onOverallEdit}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    onGridReady={this.gridReadyFun}
                    onCellClicked={this.onCellClicked.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )
        var sampleProjectDataGrid = (
            <div style={{ height: '100px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_sampleppDetails" id="sampleppDetails_grd"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs2}
                    headerHeight="48"
                    rowData={this.state.sampleGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    enableSorting={false}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter"  >
                        <li onClick={this.onNavigationClick} data-url="/CreateNewAssessment"><span title={i18n.t("takeNewAssessment")} className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Take New Assessment" data-url="/CreateNewAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/BOI"><span title={i18n.t("BOIKeyBusinessMetric")} className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/LeadingIndicator"><span title={i18n.t("lEADINGINDICATOR")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/PMMAssessment"><span title={i18n.t("pMMASSESSMENT")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/Storyboard"><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard"></span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">...</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} navigationPage={this.state.navigationPage} isEditAcitve={this.state.isEditAcitve} url={this.state.toURL} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} asToVal={this.props.location.state.asToVal} />}
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <h4><div className="pull-left">{i18n.t('pOTENTIALPROJECTS')}</div>
                        <div className="pull-right">
                                    <span class='statustxt-orng-italic'  title={i18n.t('OMID') + ': ' + this.props.configDetails.OMID}> OMID : {this.props.configDetails.OMID} </span>
                                        {/* <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                                         &nbsp;&nbsp;|| */} 
                                         

                                    </div>
                        </h4>
                        <h4>
                            <div className="btn-group btn-group-pmmasmt">
                                <button id="btn_downloadSavedScreensForThisAssessment" type="button" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default btn-downld" onClick={this.DownloadExcel} style={{ "marginLeft": "-2px", "marginRight": "2px" }}>
                                    <img src={downloadIcon} />
                                </button>
                                <button id="btn_downloadReferenceDocumentsForThisAssessment" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default btn-downld" onClick={this.showDownloadReferencePopup}>
                                    <img src={downloadGreenIcon} />
                                </button>

                            </div>
                            <span className="col-md-4 infoblockfilter">
                                <label id="lbl_search" className="col-md-2" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                <input id="txt_quickFilter" type="text" className="col-md-8 searchText pull-right" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />

                            </span>
                            <div className="col-md-3">
                                <ul className="grid-legend ulStyle">
                                    <li className="liStyle">
                                        <span className="formula-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span className="formula-span-gap"> {i18n.t('IndicativeImpactautocalculatedtool')} </span>
                                    </li>
                                </ul>
                            </div>
                            <span className="col-md-4 infoblockfilter pull-right">
                                <div className="col-md-4">
                                    <label id="lbl_asIs4" className="col-md-8" style={{ marginTop: "10px", fontSize: "14px" }}>{i18n.t('AsIs4')}:</label>
                                    <input id="txt_asIs4" type="checkbox" ref="AsIs4" style={{ marginTop: "14px" }} checked={this.state.asIs4} onClick={this.onCheckboxChecked} />
                                </div>
                                <div className="col-md-8">
                                    <label id="lbl_noIndicativeProject" className="col-md-9" style={{ marginTop: "10px", fontSize: "14px" }}>{i18n.t('NoIndicativeProject')}:</label>
                                    <input id="txt_noIndProj" type="checkbox" ref="noIndProj" style={{ marginTop: "14px" }} checked={this.state.noIndProj} onClick={this.onCheckboxChecked} />
                                </div>
                            </span>
                        </h4>
                        {this.state.pmmdetails ? <div style={{ marginTop: "20px", minHeight: "150px;", backgroundColor: "white" }}>{sampleProjectDataGrid}</div> : null}
                        <div className="box-plain pmm-asmt-tbl">
                            <div className="potential-project-div">
                                {PotentialProjectDataGrid}
                            </div>
                        </div>
                        <div className="btn-group-md">
                            <a type="button" className="btn btn-danger" onClick={this.SavePotentialProjects}>{i18n.t('save')}</a>
                            <a type="button" className="btn btn-success" onClick={this.SavePotentialProjects}>{i18n.t('GenerateProjectPrioritization')} </a>
                        </div>
                    </div>
                </section>
                <div>
                    {this.state.popUP ? <PotentialPopup /> : null}
                    {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={i18n.t('all')} SCID={this.state.SCID} />}
                </div>
            </section >
        );
    }
}

function mapStateToProps(state) {
    return {
        dropDownsValue: state.dropDownsValue,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        languageId: state.languageId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName,
    }
}

PotentialProject = connect(mapStateToProps)(withRouter(PotentialProject))
export default PotentialProject;