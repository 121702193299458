/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import ReactSelect from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import EditConfirmation from '../EditConfirmation';
import NavigationConfirmation from '../../../PMM/NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../../images/icon-back-arrow.png';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';

class DealRegionMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionName: "",
            dealRegionId: 0,
            IsActive: true,
            countriesData: [],
            regionCountries: [],
            dealRegionData: [],
            regionOptions: [],
            dealOptions: [],
            countryOptions: [],
            regionSelected: [],
            dealSelected: [],
            countrySelected: [],
            searchCountry: "",
            searchRegion: "",
            IsEditActive: false,
            isEditPopUp: false,
            isUpdateExisting: false,
            isUnSaved: false,
            popUpDisplay: false,
            toURL: '',
            navigationPage: '',
            pageNames: []
        };
        this.loadDropDownValues = this.loadDropDownValues.bind(this);
        this.onRegionChange = this.onRegionChange.bind(this);
        this.onDealChange = this.onDealChange.bind(this);
        this.loadCountries = this.loadCountries.bind(this);
        this.saveDealRegionMap = this.saveDealRegionMap.bind(this);
        this.resetValues = this.resetValues.bind(this);
        this.saveDealRegionMapCall = this.saveDealRegionMapCall.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.populateValues = this.populateValues.bind(this);
        this.populateCountries = this.populateCountries.bind(this);
        this.passValue = this.passValue.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.loadDealRegions = this.loadDealRegions.bind(this);
        this.loadRegionCountries = this.loadRegionCountries.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.addCountry = this.addCountry.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.resetValues = this.resetValues.bind(this);
        this.formatActive = this.formatActive.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.closeEditPopUp = this.closeEditPopUp.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-16", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            this.setState({ pageNames: this.props.pageName
            });
            this.loadDropDownValues();
            this.loadCountries();
            //this.loadDealRegions();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.reSizeGrid, true);
    }

    reSizeGrid() {
        try {
            this.refs.country_grid.gridOptions.api.sizeColumnsToFit();
            this.refs.region_deal_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    loadDropDownValues() {
        try {
            $("#loader-div").show();
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(res => {
                    $("#loader-div").hide();
                    let countries
                    if (res.Country != undefined && res.Country != '') {
                        countries = res.Country.filter(country => country.value != 0)
                    }
                    this.setState({
                        regionOptions: res.Region,
                        dealOptions: res.Deal,
                        dealId: res.Deal[0].value,
                        countryOptions: countries,
                        allCountryOptions: countries
                    });
                    this.loadDealRegions(0);
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async loadCountries() {
        try {
            var SearchedString = "";
            let regionAndCountriesData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                CountryId:0,
                RegionId:0,
                Flag:1,
                SearchedString:SearchedString,
            }
            await request("/MasterScreens/GetRegionAndCountries", 'POST', regionAndCountriesData)
                .then(res => {
                    $("#loader-div").hide();


                    this.setState({
                        countriesData: res
                    });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    async loadRegionCountries(DealId, RegionId) {

        try {
            var SearchedString = "";
            let masterRegionData = {
                UserName:this.props.enterpriseId,
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                DealId:DealId,
                RegionId:RegionId,
                SearchedString:SearchedString,
            }
            await request("/MasterScreens/GetMasterRegionCountriesForDeal", 'POST', masterRegionData)
                .then(res => {
                    $("#loader-div").hide();

                    if (res != []) {

                        let values = res[0];
                        if (values.DealRegionFlag != "") {

                            this.setState({
                                dealRegionId: values.DealRegionId
                            });
                        }
                        else {
                            this.setState({
                                dealRegionId: 0
                            });
                        }
                        if (RegionId !== undefined) {
                            this.populateCountries(values);
                        }
                    }
                    else {
                        this.setState({
                            regionCountries: [],
                        })
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async loadDealRegions(dealId) {
        try {

            var SearchedString = "";
            let dealRegionData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "DealId":dealId,
                "RegionId":0,
                "Flag":0,
                "SearchedString":SearchedString,
            }
            await request("/MasterScreens/GetDealRegionDetails", 'POST', dealRegionData)
                .then(res => {
                    $("#loader-div").hide();
                    if (res == "") {
                        this.setState({
                            dealRegionData: []
                        });
                    }
                    else {
                        this.setState({
                            dealRegionData: res
                        });
                    }
                    if (this.state.dealSelected[0].length != 0) {
                        this.loadRegionCountries(dealId, this.state.regionSelected[0].value);
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCheckBoxChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.checked,
                isUnSaved: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    //On Change of user inputs
    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || '',
                IsEditActive: true,
                isUnSaved: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun = (dealId) => {
        try {
            $("#loader-div").hide();
            var SearchedString = "";
            let dealRegionData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "DealId":dealId,
                "RegionId":0,
                "Flag":0,
                "SearchedString":SearchedString,
            }
            dealId.api.showLoadingOverlay();
            request("/MasterScreens/GetDealRegionDetails", 'POST', dealRegionData)
                .then(res => {
                    if (res.length !== 0) {
                        dealId.api.hideOverlay();
                    $("#loader-div").hide();
                    if (res == "") {
                        this.setState({
                            dealRegionData: []
                        });
                    }
                    else {
                        this.setState({
                            dealRegionData: res
                        });
                    }
                    if (this.state.dealSelected[0].length != 0) {
                        this.loadRegionCountries(dealId, this.state.regionSelected[0].value);
                    }
                }
                else {
                    dealId.api.showNoRowsOverlay();
                }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
                this.gridApi = dealId.api;
                this.gridColumnApi = dealId.columnApi;
                dealId.api.sizeColumnsToFit();
        }
        
        catch (error) {
            console.log("Error Encountered");
        }    
    }

    onDealChange(value) {
        try {
            //this.state.dealRegionData.filter(item => item.DealId == value[0].value)
            this.setState({
                dealSelected: value,
                isUnSaved: true,
                regionSelected: [],
                regionCountries: [],
                countryOptions: this.state.allCountryOptions
            });
            this.loadDealRegions(value[0].value);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRegionChange(value) {
        try {
            this.setState({
                regionSelected: value,
                isUnSaved: true
            });
            this.loadRegionCountries(this.state.dealSelected[0].value, value[0].value);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCountryChange(value) {
        try {
            this.setState({
                countrySelected: value,
                isUnSaved: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    addCountry(eve) {
        try {
            let { regionCountries, countrySelected, countryOptions, countriesData } = this.state;
            let addCountry;
            let newCountry = countrySelected.length != 0 ? countriesData.filter(country => country.CountryId == countrySelected[0].value) : 0
            if (countrySelected[0] != undefined && countrySelected[0].length != 0) {
                const isCountryPresent = regionCountries.some(el => el.CountryId === countrySelected[0].value);
                if (!isCountryPresent) {
                    regionCountries.push(newCountry[0]);
                    countryOptions = countryOptions.filter(country => country.value != countrySelected[0].value)
                    this.setState({
                        regionCountries: regionCountries,
                        countryOptions: countryOptions,
                        countrySelected: [],
                        isUnSaved: true
                    });
                    addCountry = this.refs.country_grid.gridOptions.api.updateRowData({ add: [newCountry[0]] });
                    addCountry.add.forEach((node) => {
                        node.setSelected(true);
                    })
                }
                else {
                    alert(i18n.t('countryIsAlreadyAddedforTheSelectedRegion'));
                }
            } else {
                alert(i18n.t('selectCountryToAddToTheRegion'));
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onRowSelect(eve) {
        try {
            eve.node.data["IsSelected"] = eve.node.selected ? true : false;
            if (!eve.node.selected) {
            }
            this.setState({
                IsEditActive: true,
                isUnSaved: true
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    resetValues() {
        try {
            this.setState({
                regionSelected: [],
                dealSelected: [],
                countrySelected: [],
                IsActive: true,
                IsEditActive: false,
                regionCountries: [],
                countryOptions: this.state.allCountryOptions,
                isUnSaved: false
            });
            this.refs.country_grid.gridOptions.api.deselectAll();
            this.loadDealRegions(0);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateValues(dealRegObj) {
        try {
            let dealRegionObj = dealRegObj;
            let dealObj = [{
                value: dealRegionObj.DealId,
                label: dealRegionObj.DealName,
                GroupID: dealRegionObj.GroupID
            }]
            let regObj = [{
                value: dealRegionObj.RegionID,
                label: dealRegionObj.RegionName,
                GroupID: dealRegionObj.GroupID
            }]
            this.populateCountries(dealRegionObj, true);
            this.setState({
                dealRegionId: dealRegionObj.DealRegionId,
                regionSelected: regObj,
                dealSelected: dealObj,
                countrySelected: [],
                IsActive: dealRegionObj.IsActive,
                IsEditActive: true,
                isUpdateExisting: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    populateCountries(data) {
        try {
            let countryIds = data.CountryIds.split(',');
            let regionCountries = [];
            let filteredCountries = this.state.allCountryOptions;
            countryIds.map((countryId) => {
                this.state.countriesData.forEach((country, index) => {
                    if (country.CountryId == countryId) {
                        country["IsSelected"] = true;
                        regionCountries.push(country);
                        return regionCountries;
                    }
                });
                filteredCountries = filteredCountries.filter(country => country.value != countryId);
            });
            this.setState({
                regionCountries: regionCountries,
                countryOptions: filteredCountries
            });
            this.refs.country_grid.gridOptions.api.selectAll();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    createEditCell(params) {
        try {           
            let editCell = document.createElement("div");
            let groupId=params.colDef.cellRendererParams.groupId;
            if (params.data.GroupID == 
                parseInt(groupId)
                ) {
                editCell.innerHTML = `<span><img src=${editIcon}></span>`;
            } else {
                editCell.innerHTML = ``;
            }

            editCell.className = "col-md-12 editIcon";
            editCell.width = "100%";
            return editCell;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(event) {
        try {
            if (event.colDef.headerName == i18n.t('editRegion')) {
                if (event.data.GroupID == parseInt(this.props.groupId)
                    ) {
                    this.populateValues(event.data);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveDealRegionMap(eve) {
        try {
            let { dealRegionId, IsActive, regionSelected, dealSelected, isUpdateExisting } = this.state;
            let regionId = regionSelected[0].value,
                dealId = dealSelected[0].value;
            let countries = this.refs.country_grid.gridOptions.api.getSelectedRows();
            let countryIds = '';
            let operation = dealRegionId == 0 || dealRegionId == undefined ? 1 : 2;
            countries.map((country, index) => {
                if (index == 0) {
                    countryIds = country.CountryId;
                } else {
                    countryIds = countryIds + ',' + country.CountryId;
                }
            });
            if (dealSelected[0].length <= 0 || dealSelected == undefined || regionSelected == undefined || regionSelected[0].length <= 0 || countries.length <= 0) {
                alert(i18n.t('pleaseSelectAllMandatoryValues'));
            } else {

                let dealRegObj = {
                    UserName:this.props.enterpriseId,
                    GroupID:this.props.groupId,
                    LanguageID:this.props.languageId,
                    DealRegionId: dealRegionId,
                    DealId: dealId,
                    RegionId: regionId,
                    CountryIds: countryIds,
                    Operation: operation,
                    IsActive: IsActive,
                }
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting) {
                    this.setState({
                        isEditPopUp: true
                    });
                    return;
                } else {
                    this.saveDealRegionMapCall(dealRegObj, dealSelected);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveDealRegionMapCall(dealRegObj, dealSelected) {
        try {
            let postData = dealRegObj;
            let dealSel = dealSelected;
            $("#loader-div").show();
            request("/MasterScreens/UpsertDealRegionMapping", 'POST', postData)
                .then(res => {
                    $("#loader-div").hide();
                    if (res.Outputcode == -1) {
                        alert(res.outputmessage)
                    } else {
                        this.resetValues();
                        this.loadDealRegions(dealRegObj.DealId);
                        alert(res.outputmessage);
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                    alert(error.statusText)
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                dealRegionId: value ? this.state.dealRegionId : 0
            });
            if (value) {
                this.saveDealRegionMap(value);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the Edit popup */
    closeEditPopUp() {
        this.setState({
            isEditPopUp: false
        })
    }

    /*Function closes the Navigation popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /* Function formats the active column data in the grid */
    formatActive(params) {
        return params.value ? i18n.t('yes') : i18n.t('no');
    }

    /*Function prompts a pop up in case on unsaved data*/
    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('dealManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isUnSaved) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        let { t } = this.props;
        let countriesGrid, dealRegionGrid, countryColDefs, dealRegionDefs;
        countryColDefs = [
            { headerName: t('continents'), field: 'Continent', tooltipField: 'Continent', suppressMovable: true, suppressResize: true },
            { headerName: t('countriesLocations'), field: 'CountryName', tooltipField: 'CountryName', suppressMovable: true, suppressResize: true },
            {
                headerName: t('selectAll'), checkboxSelection: true, suppressMenu: true, suppressSorting: true, headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true, suppressMovable: true, suppressResize: true
            },
            { headerName: t('isSelected'), field: 'IsSelected', hide: true, suppressMovable: true, suppressResize: true, }
        ];

        dealRegionDefs = [
            { headerName: i18n.t('editRegion'), cellRenderer: this.createEditCell,cellRendererParams:{groupId:this.props.groupId}, suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('Client Name'), field: 'DealName', tooltipField: 'DealName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('region'), field: 'RegionName', tooltipField: 'RegionName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('countriesLocations'), field: 'CountryName', tooltipField: 'CountryName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('continents'), field: 'Continent', tooltipField: 'Continent', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('active'), field: 'IsActive', valueFormatter: this.formatActive, suppressResize: true, tooltipField: 'IsActive' },
            { headerName: i18n.t('createdBy'), field: 'CreatedBy', suppressMovable: true },
            { headerName: i18n.t('createdDate'), field: 'CreatedDate', suppressMovable: true },
            { headerName: i18n.t('updatedBy'), field: 'UpdatedBy', suppressMovable: true },
            { headerName: i18n.t('updatedDate'), field: 'UpdatedDate', suppressMovable: true }
        ];

        countriesGrid = (
            <div className="ag-theme-material sel-country-div grid-div country-name-grid">
                <AgGridReact ref="country_grid" id="grid_country"
                    columnDefs={countryColDefs}
                    rowData={this.state.regionCountries}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={false}
                    paginationPageSize="10"
                    rowmodeltype="pagination"
                    rowSelection='multiple'
                    suppressRowClickSelection={true}
                    quickFilterText={this.state.searchCountry}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        );

        dealRegionGrid = (
            <div className="ag-theme-material grid-div country-name-grid">
                <AgGridReact ref="region_deal_grid" id="grid_region_deal"
                    columnDefs={dealRegionDefs}
                    rowData={this.state.dealRegionData}
                    enableSorting={true}
                    enableFilter={true}
                    paginationPageSize="10"
                    rowmodeltype="pagination"
                    rowSelection='multiple'
                    onCellClicked={this.onCellClicked}
                    quickFilterText={this.state.searchRegion}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        );

        return (
            <div className="content">
                <div className="innerpage-hd">
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" />{i18n.t('bACKTOHOME')}</a>
                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li>
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("DealManagement") ?
                            <li className="active">
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('Client Management')}</a>
                            </li>
                            : null}
                        {this.state.pageNames.includes("BOIConfiguration") ? <li>
                            <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                        </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ? <li>
                            <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                        </li>
                            : null}

                    </ul>
                </div>

                <section className="multiple-tabs-section">
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="" data-content="Create Deal" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('createClient')}</a>

                            </li>
                            <li className="nav-item">
                                <a className="active" data-content="Deal Region Mapping" data-url="/DealRegionMapping" onClick={this.onNavigationClick}>{i18n.t('ClientRegionMapping')}</a>

                            </li>
                        </ul>
                        {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                            navigationPage={this.state.navigationPage} isEditAcitve={this.state.isUnSaved}
                            url={this.state.toURL} closeCallback={this.closeNavigationPopUp} />}
                        {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={i18n.t('dealRegion')}
                            editValue={this.state.dealSelected[0].label + " - " + this.state.regionSelected[0].label}
                            passValue={this.passValue} closeCallback={this.closeEditPopUp} />}
                        <div className="box">
                            <div className="box-in">
                                <div className="row">
                                    <div className="col-sm-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_dealName" className="control-label col-sm-4">{i18n.t('Client Name')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="rs_dealChange" ref="deal" name="form-field-name" labelKey="label" onChange={this.onDealChange.bind(this)} options={this.state.dealOptions} selected={this.state.dealSelected} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-sm-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_regionName" className="control-label col-sm-4">{i18n.t('regionName')}:<span className="mandatory-cls">*</span></label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="rs_regionChange" ref="deal" name="form-field-name" labelKey="label" onChange={this.onRegionChange.bind(this)} options={this.state.regionOptions} selected={this.state.regionSelected} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 form-horizontal">
                                        <div className="form-group link">
                                            <Link to="/PMMConfiguration/Region">{i18n.t('addNewRegion')}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_isActive" className="control-label col-sm-4">{i18n.t('is_Active')}:</label>
                                            <div className="col-sm-8">
                                                <input id="txt_checkBoxChange" type="checkbox" ref="IsActive" data-field="IsActive" checked={this.state.IsActive} onChange={this.onCheckBoxChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 form-horizontal">
                                        <div className="form-group">
                                            <label id="lbl_country" className="control-label col-sm-4">{i18n.t('country')}:</label>
                                            <div className="col-sm-8">
                                                <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="rs_countryChange" ref="deal" name="form-field-name" labelKey="label" onChange={this.onCountryChange.bind(this)} options={this.state.countryOptions} selected={this.state.countrySelected} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t('select')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 form-horizontal">
                                        <div className="form-group">
                                            <input id="txt_addCountrytoRegion" type="button" className="btn btn-info" value={i18n.t('addCountrytoRegion')} onClick={this.addCountry} />
                                        </div>
                                    </div>
                                </div>
                                <div className="country-grid grid-parent">
                                    <div className="search-div">
                                        <h4 className="fleft">{i18n.t('cOUNTRIES')}<span className="mandatory-cls">*</span></h4>
                                        <input id="txt_searchCountries" type="text" placeholder={i18n.t('searchCountries')} className="fright searchBox" data-field='searchCountry' value={this.state.searchCountry} onChange={this.onSearch} />
                                    </div>
                                    <div className="box-plain pmm-asmt-tbl">
                                        {countriesGrid}
                                    </div>
                                </div>
                                <div className="fright btn-group-md">
                                    <a type="button" className="btn btn-success" onClick={this.saveDealRegionMap}>{i18n.t('save')}</a>
                                    <a type="button" className="btn btn-danger" onClick={this.resetValues}>{i18n.t('clear')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="country-grid grid-parent">
                            <div className="search-div">
                                <h4 className="fleft">{i18n.t('CLIENT-REGIONS')}</h4>
                                <input id="txt_searchRegions" type="text" placeholder={i18n.t('searchRegions')} className="fright searchBox" data-field='searchRegion' value={this.state.searchRegion} onChange={this.onSearch} />
                            </div>
                            <div className="box-plain pmm-asmt-tbl">
                                {dealRegionGrid}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName

    }
}
DealRegionMapping = connect(mapStateToProps)(withTranslation()(DealRegionMapping));
export default withRouter(DealRegionMapping);
