import i18next from 'i18next';
/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import { connect } from 'react-redux';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import request from '../../services/Service';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as DOMPurify from 'dompurify';


class ConfirmationBox extends React.Component {
    constructor(props) {
        super(props)
        try {
            this.state = { show: this.props.show };
            this.handleClose = this.handleClose.bind(this);
            this.handleResult = this.handleResult.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };
    componentWillReceiveProps() {
        this.setState({ show: this.props.show })
    }
    handleClose() {
        this.setState({ show: false });
    };
    handleResult(event) {
        try {
            let selectedDetails = this.props.selectedDetails;
            if ((this.props.reqSrc == i18n.t('save') && event.target.textContent == i18n.t('no')) || (this.props.reqSrc == i18n.t('startAssessment') && event.target.textContent == i18n.t('no'))) {
                this.props.data.PMMProcessDraftID = this.props.DraftId;
                request("/Common/SaveNewAssessment?LanguageID="+this.props.languageId, 'POST',this.props.data)
                .then(res => {
                           selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                            this.props.propsPMMConfigDetails(selectedDetails);
                            if (this.props.reqSrc != i18n.t('save')) {
    
                                this.props.history.push("/BOI");
                             } else {
                                 this.setState({ show: false });
                            }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            }
            else {
                if (this.props.reqSrc == i18n.t('save')) {
                    this.setState({ show: false });
                } else {
                    this.props.data.PMMProcessDraftID = this.props.DraftId;
                    var data= this.props.data;
                    request("/Common/UpsertProcessDraft?LanguageID="+this.props.languageId, 'POST',data)
                .then(res => {
                            selectedDetails.PMMProcessDraftID = this.props.DraftId;
                            this.props.propsPMMConfigDetails(selectedDetails);
                            if (this.props.reqSrc != i18n.t('save')) {

                                this.props.history.push("/BOI");
                            } else {
                                this.setState({ show: false });
                            }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    
    render() {
        let cleanedBodyMessage = DOMPurify.sanitize(this.props.bodyMessage);
        console.log("this.props.bodyMessage",this.props.bodyMessage)
        return (<div>
            <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <h2>{i18n.t('confirm')}</h2>
                </Modal.Header>
                
                <Modal.Body dangerouslySetInnerHTML={{ __html: cleanedBodyMessage }}></Modal.Body>
                <Modal.Footer>
                    {this.props.propsIsLocked == 2 ?
                        <div>
                            <Button id="btn_ok" bsStyle="success" bsSize="large" onClick={this.handleClose}>{i18n.t('ok')}</Button>
                        </div> :
                        <div>
                            <Button id="btn_yes" bsStyle="success" bsSize="large" onClick={this.handleResult}>{i18n.t('yes')}</Button>
                            <Button id="btn_no" bsStyle="danger" bsSize="large" onClick={this.handleResult}>{i18n.t('No')}</Button>
                        </div>}
                </Modal.Footer>
            </Modal>
        </div>)
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
    }
}

ConfirmationBox = connect(mapStateToProps)(withRouter(ConfirmationBox))
export default ConfirmationBox;