/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Autosuggest from 'react-autosuggest';
import EditConfirmation from '../EditConfirmation';
import { withRouter } from 'react-router';
import $ from 'jquery';
import editIcon from '../../../images/icon-edit-active.png';
import request from '../../../services/Service';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { AgGridLocale } from '../../../Utils/AgGridLocale';
import {connect} from 'react-redux';
import { inputValidator } from '../../../Utils/InputElementValidation';
import { BaseURL } from '../../../Constant';
import downloadIcon from "../../../images/icon-download.png";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const getSuggestionValue = suggestion => suggestion.CapabilityName;

const renderSuggestion = suggestion => <span>{suggestion.CapabilityName}</span>;

var CapabilityData = []

function CreateEditBox(params) {
   
   let groupId=params.colDef.cellRendererParams.groupId
    let eDiv = document.createElement("div");
    if (params.data.GroupID == parseInt(groupId)
        ) {
        eDiv.innerHTML = `<span><img src=${editIcon}></span>`;
    }
    else {
        eDiv.innerHTML = ``;
    }
    eDiv.className = "col-md-12";
    eDiv.width = "100%";
    return eDiv;
}


class Capability extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                CapabilityName: '',
                Description: '',
                CapabilityGridData: [],
                isEdit: 1,
                errors: {},
                selectedRow: {},
                CapabilityID: 0,
                IsDeleted: false,
                value: '',
                suggestions: [],
                isEditPopUp: false,
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                IsActive: true
            };
            this.ChangeIsActive = this.ChangeIsActive.bind(this);
            this.OnCancel = this.OnCancel.bind(this);
            this.onInputChange = this.onInputChange.bind(this);
            this.onChange = this.onChange.bind(this);
            this.onBlur = this.onBlur.bind(this);
            this.getSuggestions = this.getSuggestions.bind(this);
            this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
            this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
            this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
            this.populateValues = this.populateValues.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.SaveCapabilityDetails = this.SaveCapabilityDetails.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.passValue = this.passValue.bind(this);
            this.formatActive = this.formatActive.bind(this);
            this.onSearch = this.onSearch.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillMount() {
        try {
           
            this.setState({ CapabilityGridData: CapabilityData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            $("#loader-div").hide();

            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            var objThis = this;
            var SearchedString = "";
            let capData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": 0,
                "SearchedString": SearchedString,

            }
            params.api.showLoadingOverlay();
            fetch(
                BaseURL + "/Common/GetAllCapability",
                {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                  },
                }
              )
              .then((response) => {
                return response.json();
              })
                .then(res => {
                    console.log("res",res);
                    if(res.length!==0){
                        params.api.hideOverlay();
                        objThis.setState({ CapabilityGridData: res });
                        $("#loader-div").hide();
                    }
                   
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-MC-5", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])    
            window.addEventListener('resize', this.reSizeGrid, true);
            //this.FetchConnCapabilityGrid();
        }
        catch (error) {
            console.log("Error Encountered");
        }

    }

    reSizeGrid() {
        try {
            this.refs.capabillity_grid.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        try {
            if (e.colDef.headerName == i18n.t("edit")) {
                if (e.data.GroupID == parseInt(this.props.groupId)
                    ) {
                    this.populateValues(e.data);
                }
            }
        }
        catch (error) {
            console.error("Error Encountered");
        }
    }

    populateValues(cap) {

        try {
            this.setState({
                isEdit: 2,
                selectedRow: cap,
                CapabilityID: cap.CapabilityId,
                IsActive: cap.IsActive,
                IsDeleted: cap.IsDeleted,
                value: cap.CapabilityName,
                CapabilityName: cap.CapabilityName,
                Description: cap.Description,
                isUpdateExisting: true
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ChangeIsActive(event) {
        try {
            this.setState({ IsActive: event.target.checked });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    onInputChange(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onSearch(eve) {
        try {
            let attr = eve.target.getAttribute("data-field");
            this.setState({
                [attr]: eve.target.value || ''
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnCancel(event) {
        try {
            this.setState({
                isEdit: 1,
                errors: '',
                IsActive: true,
                value: '',
                IsConfirmUpdate: false,
                isUpdateExisting: false,
                CapabilityName: "",
                Description: "",
                CapabilityID: 0
            });

            this.props.editFun(false);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async FetchConnCapabilityGrid() {
        try {
            var objThis = this;
            var SearchedString = "";
            let capData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": 0,
                "SearchedString": SearchedString,

            }
            await request("/MasterScreens/GetCapabilityDetails", 'POST', capData)
                .then(res => {
                    objThis.setState({ CapabilityGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveCapabilityDetails(eve) {
        try {
            const obj = this;
            let { isEdit, CapabilityID, CapabilityName, IsActive, IsDeleted, value, Description } = obj.state;
            let Operation = eve && CapabilityID != 0 ? 2 : 1;
            let capabilitynamevalidation = inputValidator(value)
            let descriptionvalidation = inputValidator(Description)

            if (value.includes(",")) {
                alert(i18n.t('capabilitynameshouldnotcontain'));
                return false;
            }
            if(capabilitynamevalidation === false && descriptionvalidation === false ){
            if (obj.IsFormValid()) {
                if (eve && typeof (eve) != "boolean" && this.state.isUpdateExisting && CapabilityName != "") {
                    this.setState({
                        isEditPopUp: true
                    })
                    return alert(i18n.t('updateExistingorCreateNew'));
                }
                const requestData = obj.state.selectedRow;
                requestData["UserName"] =this.props.enterpriseId;
                requestData["CapabilityId"] = eve ? CapabilityID : 0;
                requestData["CapabilityName"] = value;
                requestData["Description"] = Description;
                requestData["Operation"] = Operation;
                requestData["IsActive"] = IsActive;
                requestData["IsDeleted"] = IsDeleted;
                requestData["GroupID"] =this.props.groupId;
                requestData["LanguageID"] =this.props.languageId;
                request("/MasterScreens/UpsertCapability", 'POST', requestData)
                    .then(res => {
                        if (res.Outputcode != -1) {
                            alert(res["outputmessage"]);
                            obj.FetchConnCapabilityGrid();
                            obj.OnCancel();
                            obj.props.editFun(false);
                        }
                        else {
                            alert(res["outputmessage"]);
                        }
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                    });
            }


        }
        else{
            alert(i18n.t("PleaseEntervalidValuesintheFields"));

        }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    IsFormValid() {
        try {
            let formIsValid = true;
            let errors = {};
            let reg = /^[A-Za-z\d\s]+$/;
            let regForAlphabets = /^[a-zA-Z]+$/;
            if (!this.state.value) {
                errors.ServiceComponentName = 'Please Enter Capability Name';
                alert(i18n.t('pleaseEnterCapabilityName'));
                formIsValid = false;
            }
            if (!this.refs.Description.value && formIsValid == true) {
                errors.Description = 'Please enter description for Capability';
                alert(i18n.t('pleaseEnterDescriptionforCapability'));
                formIsValid = false;
            }
            this.setState({ errors: errors });
            console.log(errors);
            return formIsValid;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Changes for Auto Suggest */
    getSuggestions(value) {
        try {
            const escapedValue = escapeRegexCharacters(value.trim());
            if (escapedValue === '') {
                let newSuggestions = this.state.CapabilityGridData.filter(Capability => Capability.GroupID == parseInt(this.props.groupId));
                return newSuggestions;
            } else {
                const regex = new RegExp('^' + escapedValue, 'i');
                let newSuggestions = this.state.CapabilityGridData.filter(Capability => regex.test(Capability.CapabilityName) && Capability.GroupID == parseInt(this.props.groupId));
                
                return newSuggestions;
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onChange = (event, { newValue }) => {
        try {
            this.setState({
                value: newValue
            });
            $(event.target).attr('type', 'new');
            this.props.editFun(true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        try {
            this.setState({
                suggestions: this.getSuggestions(value)
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionsClearRequested = () => {
        try {
            this.setState({
                suggestions: []
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        try {
            if (event.target.tagName == "SPAN") {
                $(event.target).parent().parent().parent().parent().find('input').attr('type', 'existing');
            } else {
                $(event.target).parent().parent().parent().find('input').attr('type', 'existing');
            }
            this.populateValues(suggestion);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onBlur(event, { suggestion }) {
        try {
            let type = $(event.target).attr('type');
            let value = event.target.value.toLowerCase();
            this.state.CapabilityGridData.forEach(capability => {
                if (capability.CapabilityName.toLowerCase() == value) {
                    if (capability.GroupID == parseInt(this.props.groupId)){
                        this.populateValues(capability);
                    }
                }
                return capability;
            })
            if (type == "new") {
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                isEditPopUp: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    passValue(value) {
        try {
            this.setState({
                isUpdateExisting: false,
                IsConfirmUpdate: value
            });
            if (value) {
                this.SaveCapabilityDetails(value);
            } else {
                this.SaveCapabilityDetails();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    formatActive(params) {
       return params.value == true ? i18n.t('yes') : i18n.t('no');
    }
    onBtExport = () => {

        const rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        if(rowData.length>0){
       
        const newRowData = rowData.map(({main_offering_name}) => ({ main_offering_name }));

        console.log("rowData",newRowData);
        const ws = XLSX.utils.json_to_sheet(newRowData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: "application/octet-stream" }), 'mainOffering_data.xlsx');
        }
    };

    render() {
        let { t } = this.props;
        var columnDefs = [];
        columnDefs = [
            // { headerName: t('edit'), field: '', cellRenderer: CreateEditBox,cellRendererParams:{groupId:this.props.groupId}, suppressMovable: true, suppressResize: true, },
            { headerName: t('MainOffering ID'), field: 'main_offering_id', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: t('MainOffering Name'), field: 'main_offering_name', suppressMovable: true, suppressResize: true, },
            // { headerName: t('description'), field: 'Description', suppressMovable: true, suppressResize: true, },
            // { headerName: t('createdBy'), field: 'CreatedBy', suppressMovable: true, suppressResize: true, },
            // { headerName: t('createdDate'), field: 'CreatedDate', suppressMovable: true, suppressResize: true, },
            // { headerName: t('updatedBy'), field: 'UpdatedBy', suppressMovable: true, suppressResize: true, },
            // { headerName: t('updatedDate'), field: 'UpdatedDate', suppressMovable: true, suppressResize: true, },
            // { headerName: t('headerIsActive'), field: 'active_flag', valueFormatter: this.formatActive, suppressMovable: true, suppressResize: true, },
        ];

        var TowerGridData = this.state.TowerGridData;

        var CapabilityDataGrid = (
            <div className="ag-theme-material grid-div">
                <AgGridReact ref="capabillity_grid" id="capabillity_Grid"
                    enableSorting="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.CapabilityGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    enableFilter='true'
                    localeText={AgGridLocale()}
                    quickFilterText={this.state.searchCapability}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`}/>
            </div>
        )

        let { value, suggestions } = this.state;

        let inputProps = {
            value,
            onChange: this.onChange,
            onBlur: this.onBlur
        }

        return (
            <div>
                <div className="box">
                    <div className="box-in">
                        <div className="col-lg-5 form-horizontal">
                            {/* <div className="form-group">
                                <label id="lbl_capabilityName" className="control-label col-sm-4">{i18n.t('capabilityName')}:<span className="mandatory-cls">*</span></label>
                                <div className="col-sm-8">
                                    <Autosuggest ref="CapabilityName"
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps} />
                                </div>
                            </div> */}
                            {/* {this.state.isEditPopUp && <EditConfirmation show={this.state.isEditPopUp} type={"Capability"} editValue={this.state.CapabilityName}
                                passValue={this.passValue} closeCallback={this.closeNavigationPopUp} />} */}
                        </div>

                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_description" className="control-label col-sm-2">{i18n.t('description')}:<span className="mandatory-cls">*</span></label>
                                <div className="col-sm-8">
                                    <textarea ref="Description" id="txtArea_description" name="Description" data-field="Description" rows="1" className="form-control" placeholder="" type="" onChange={this.onInputChange} value={this.state.Description} />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-5 form-horizontal">
                            <div className="form-group">
                                <label id="lbl_isActive" className="control-label col-sm-2">{i18n.t('isActive')} </label>
                                <div className="col-sm-8">
                                    <div className="commoncheckbox">
                                        <input id="txt_isActive" ref="IsActive" type="checkbox" name="IsActive" className="chkBox" checked={this.state.IsActive} onChange={this.ChangeIsActive} />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="btn-group-md fright">
                            <a type="button" className="btn btn-success" onClick={this.SaveCapabilityDetails} >{i18n.t('save')}</a>
                            <a type="button" className="btn btn-danger" onClick={this.OnCancel} >{i18n.t('clear')}</a>
                        </div> */}
                         <div className="btn-group btn-group-pmmasmt">
                  <button
                    type="button"
                    id="btn_downloadAssesment"
                    title={i18n.t("DownloadbelowlistinExcelformat")}
                    className="btn btn-default btn-downld"
                    onClick={this.onBtExport}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
                        <div className="search-div">
                            <h4 className="fleft">{i18n.t('listDetails')}</h4>
                            <input id="txt_search" type="text" placeholder={t("search Main Offering")} className="fright searchBox" data-field='searchCapability' value={this.state.searchCapability} onChange={this.onSearch} />

                        </div>
                        <div className="AsmtData data-LeadingInd">
                            <div className="box">
                                {CapabilityDataGrid}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.roleName,
        groupName:state.groupName
    }
}

Capability = connect(mapStateToProps)(withTranslation()(Capability));
export default withRouter(Capability);

