/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import ConfirmSubmit from './ConfirmSubmit';
import '../../Script/chartjs-plugin-datalabels.min';
import NavigationConfirmation from '../NavigationConfirmation';
import html2canvas from 'html2canvas';
import $ from 'jquery';
import { Chart } from 'chart.js';
import PptxGenJS from 'pptxgenjs';
import backArrowIcon from '../../images/icon-back-arrow.png';
import graphIcon from '../../images/icon-graph.png';
import listDefaultIcon from '../../images/icon-list-default.png';
import graphListIcon from '../../images/icon-graphlist.png';
import pptIcon from '../../images/powerpoint-icon.png';
import AssessmentEvalAidPopUp from './AssessmentEvalAidPopup';
import ReferenceDocumentsDetail from '../PMMAssessment/ReferenceDocumentsDetail';
import downloadGreenIcon from '../../images/icon-download-green.png';
import request from '../../services/Service';
import ReportDocumentUpload from './ReportDocumentUpload';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';



class ProjectPrioritization extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                popUpDisplay: false,
                toURL: '',
                navigationPage: "",
                popUpAssessmentEvalAid: false,
                isLIMetricCritical: false,
                isBOIMetricCritical: false,
                PMMAllDetails: [], Confirm: false, reqSrc: "",
                popUpReferenceDocuments: false,
                ProjectPrioritizationGridData: [], lstLever: [], LeverValue: "", graph: true, grid: false,
                SCID: this.props.configDetails.ServiceComponent,
                popUpReportDocuments: false,
                reportDocumentsCount: 0
            };

            this.onNavigationClick = this.onNavigationClick.bind(this);
            this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
            this.SaveFinalAssessment = this.SaveFinalAssessment.bind(this);
            this.toggleGraph = this.toggleGraph.bind(this);
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.unloadEvent = this.unloadEvent.bind(this);
            this.closeAssessmentEvalAidPopup = this.closeAssessmentEvalAidPopup.bind(this);
            this.AssessmentEvalAid = this.AssessmentEvalAid.bind(this);
            this.checkCriticalMetric = this.checkCriticalMetric.bind(this);
            this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
            this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
            this.closeSavedDocumentsPopUp = this.closeSavedDocumentsPopUp.bind(this);
            this.showSavedDocumentsPopup = this.showSavedDocumentsPopup.bind(this);
            this.showReportDocumentUploadPopup = this.showReportDocumentUploadPopup.bind(this);
            this.closeReportDocumentUploadPopup = this.closeReportDocumentUploadPopup.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    componentWillMount() {

    }

    unloadEvent(e) {
        try {
            let confirmationMessage = i18n.t('leavingthispagewillresultinlossunsaveddata');
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    unloadEvent(e) {
        try {
            let confirmationMessage = i18n.t('leavingthispagewillresultinlossunsaveddata');
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_BOI.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-TPA-7", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            $("#loader-div").show();
            var objThis = this;
            window.addEventListener("beforeunload", this.unloadEvent);
            var SearchedString = "";
            let dropdownData = {
                "ID": 0,
                "DealId": 0,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId

            }
            let gridData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "SearchedString": SearchedString,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            Promise.all([request("/Common/DropDownsValue", 'POST', dropdownData),
            request("/PotentialProjects/GetProjectPrioritizationGrid", 'POST', gridData)])
                .then(response => {
                    response[0].lever.unshift({ value: 0, label: i18n.t('all') })
                    this.setState({ lstLever: response[0].lever, ProjectPrioritizationGridData: response[1], LeverValue: { value: 0, label: i18n.t('all') } });
                    let graphDataArr = []
                    response[1].map(row => { if (row.ShortlistProject == i18n.t('yes')) { let dataobj = { x: row.TimeMonths, y: row.ImpactOnBOI, name: row.ProjectId, projectDetails: row.ProjectDetails }; graphDataArr.push(dataobj) } })
                    if (graphDataArr.length >= 1) {
                        this.BubbleChart(graphDataArr);
                    }
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            var SearchedString = "";
            let BOIDetailsData = {
                "BOILIID": 0,
                "BOILIMappingId": 0,
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "IndustryID": this.props.configDetails.OMID,
                "SCID": this.props.configDetails.ServiceComponent,
                "SearchedString": SearchedString,
                "UserName": this.props.enterpriseId,
    			"GroupID": this.props.groupId,
	    		"LanguageID":this.props.languageId
            }
            request("/BOI/GetBOIDetails", 'POST', BOIDetailsData)
                .then(response => {

                    objThis.setState({ BOIDetailsData: response })
                    let boiMetric = objThis.state.BOIDetailsData.some(item => item.IsCriticalMetric == true && item.IsSelected == true)

                    if (boiMetric) {
                        objThis.setState({
                            isBOIMetricCritical: true
                        })
                    }
                    else {
                        objThis.setState({
                            isBOIMetricCritical: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            var SearchedString = "";
            let leadingIndicatorData = {
                BOILIId:0,
                PMMProcessDraftID:this.props.configDetails.PMMProcessDraftID,
                IndustryID:this.props.configDetails.OMID,
                SCID:this.props.configDetails.ServiceComponent,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/LeadingIndicator/GetLeadingIndicatorDetails", 'POST', leadingIndicatorData)
                .then(response => {
                    objThis.setState({ LIDetailsData: response });
                    let liMetric = objThis.state.LIDetailsData.some(item => item.IsCriticalMetric == true && item.IsSelected == true)

                    if (liMetric) {
                        objThis.setState({
                            isLIMetricCritical: true
                        })
                    }
                    else {
                        objThis.setState({
                            isLIMetricCritical: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            let projData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "PMMProcessID": 0,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/PotentialProjects/GetReportDocumentNames", 'POST', projData)
                .then(response => {
                    let lstReportOutputDocumentNames = [];
                    if (response != "") {
                        lstReportOutputDocumentNames = response
                    }
                    objThis.setState({
                        reportDocumentsCount: lstReportOutputDocumentNames.length
                    });
                })
                .catch(error => {
                    console.log(error);
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveFinalAssessment(e) {

        try {

            let evtSrc = e.target.text.trim();
            var PMMAllDetails = {
                LoggedInUserId: this.props.enterpriseId
                , PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID
            };
            if (evtSrc == i18n.t("SubmitFinalAssessment")) {
                if (this.state.reportDocumentsCount > 0 || this.props.groupId == 1) {
                    this.setState({ popUpReportDocuments: false, popUpReferenceDocuments: false, Confirm: true, reqSrc: evtSrc, PMMAllDetails: PMMAllDetails });
                }
                else {
                    alert(i18n.t("pleaseuploadReportOutputDocuments"))
                }
            } else {
                this.setState({ popUpReportDocuments: false, popUpReferenceDocuments: false, Confirm: true, reqSrc: evtSrc, PMMAllDetails: PMMAllDetails });
            }
        }
        catch (error) {
            console.log(" Error Encountered");
        }
    }

    SelectLever(val) {
        try {
            this.setState({ LeverValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowTooltip(e) {
        try {
            var eDiv = document.createElement("div");
            if (e.colDef.headerName == i18n.t('projectNamePMM')) {
                var strProjectName = e.data.ProjectName;
                eDiv.textContent = '<span title="' + strProjectName + '">' + strProjectName + '</span>';
            }
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    BubbleChart(rawData) {
        try {
            Chart.defaults.global.elements.point.radius = 15;
            Chart.defaults.global.plugins.datalabels.display = true;
            var xarr = [], yarr = [];
            // Get time and impact array to find the maximum value
            $(rawData).each(function (index, datum) {
                xarr.push(datum.x);
                yarr.push(datum.y);
            });
            var maxTime = Math.max.apply(null, xarr);
            var maxImpact = Math.max.apply(null, yarr);

            //Calculate the levels for differentiating the project points
            var level1, level2;
            level1 = +(((maxImpact / 2) / maxTime).toFixed(2));
            level2 = +((maxImpact / (maxTime / 2)).toFixed(2));
            var bubbleData = {
                datasets: [{
                    backgroundColor: function (context) {
                        var data = context.dataset.data[context.dataIndex];
                        var val = +((data.y / data.x).toFixed(2));
                        return (val <= level1) ? '#E57373' : (val > level1 && val <= level2) ? '#FFEB3B' : '#81C784';
                    },
                    borderColor: function (context) {
                        var data = context.dataset.data[context.dataIndex];
                        var val = +((data.y / data.x).toFixed(2));
                        return (val <= level1) ? '#E57373' : (val > level1 && val <= level2) ? '#FFEB3B' : '#81C784';
                    },
                    label: ["Time Vs Impact"],
                    data: rawData
                }]

            };

            if (window.bubbleChart != null) {
                $("#bubbleDiv").empty();
                $("#bubbleDiv").append("<canvas id='bubble' width='400' height='150' ></canvas>");
            }

            //Construction of Charts
            var bubble_ctx = document.getElementById('bubble').getContext('2d');
            window.bubbleChart = new Chart(bubble_ctx, {
                type: 'bubble',
                data: bubbleData,
                options: {
                    plugins: {
                        datalabels: {
                            anchor: 'center',
                            align: 'center',
                            color: 'black',
                            font: {
                                weight: 'bold'
                            },
                            formatter: function (value, context) {
                                return context.dataset.data[context.dataIndex].name;
                            },
                            offset: 2,
                            padding: 0
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0,
                                max: maxTime + 1,
                                stepsize: 1
                            },
                            fontColor: "#000",
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Timemonths"),
                                fontSize: 14,
                                fontStyle: 600,
                                fontColor: "#000",
                                fontFamily: "OpenSans-Regular"
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: maxImpact + 10,
                                stepsize: 10
                            },
                            fontColor: "#000",
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("ImpactonY"),
                                fontSize: 14,
                                fontStyle: 600,
                                fontColor: "#000",
                                fontFamily: "OpenSans-Regular"
                            }
                        }]
                    },

                    legend: false,
                    legendCallback: function (chart) {
                        var customLegend = [];
                        customLegend.push('<ul class="ulStyle">');
                        customLegend.push('<li class="liStyle"><span style="background:#81C784">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 1</p>');
                        customLegend.push('<span style="background:#FFEB3B">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 2</p>');
                        customLegend.push('<span style="background:#E57373">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><p>' + i18n.t('Priority') + ' 3</p></li>');
                        customLegend.push('</ul>');
                        return customLegend.join('');
                    },

                    tooltips: {
                        enabled: true,
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label;
                                label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].projectDetails;
                                return label;
                            }
                        }
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    toggleGraph(e) {
        try {
            this.setState({ popUpReferenceDocuments: false, popUpReportDocuments: false });
            let graph, grid, graphgrid;
            if (e.currentTarget.id == "graph") {
                graph = true
                grid = false
            }
            else if (e.currentTarget.id == "grid") {
                grid = true
                graph = false
                let activeBtn = document.getElementById("graph");
                activeBtn.className = activeBtn.className.replace("active", "");
            }
            else {
                grid = true
                graph = true
            }
            this.setState({ graph, grid });
            this.setState({ Confirm: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeReferenceDocumentsPopUp() {
        try {
            this.setState({
                popUpReferenceDocuments: false,
                popUpReportDocuments: false
            });
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showDownloadReferencePopup() {
        try {
            this.setState({
                popUpReferenceDocuments: true,
                popUpReportDocuments: false,
                Confirm: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    closeSavedDocumentsPopUp() {
        this.setState({
            popUpSavedDocuments: false
        });
        $("#loader-div").hide();
    }
    showSavedDocumentsPopup() {
        this.setState({
            popUpSavedDocuments: true
        })
    }
    closeReportDocumentUploadPopup(reportDocumentsCount) {
        try {
            this.setState({
                popUpReportDocuments: false,
                reportDocumentsCount: reportDocumentsCount
            });
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showReportDocumentUploadPopup() {
        try {
            this.setState({
                popUpReportDocuments: true,
                Confirm: false,
                popUpReferenceDocuments: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    AssessmentEvalAid() {
        try {

            this.setState({
                popUpAssessmentEvalAid: true,
                popUpReferenceDocuments: false,
                popUpReportDocuments: false
            })

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeAssessmentEvalAidPopup() {
        try {
            this.setState({
                popUpAssessmentEvalAid: false

            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            this.setState({ popUpReferenceDocuments: false, popUpReportDocuments: false });
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage,
                        Confirm: false
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                    .then(res => {
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            alert(error.statusText);
                            $("#loader-div").hide();
                        });
                    this.props.history.push(url);
                }
                else {
                    if (navigationPage == "Storyboard" || navigationPage == "PotentialProject" || navigationPage == "Potential Projects") {
                        this.props.history.push({
                            pathname: url,
                            state: { asToVal: this.props.location.state.asToVal }
                        });
                    }
                    else {
                        this.props.history.push(url);
                    }
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }



    downloadPDF() {


    }

    downloadPPT() {
        //For whole page export
        try {
            //window.tfo_event("PMM-D-9", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])            
            window.scrollTo(0, 0);
            html2canvas(document.getElementById("bubbleCanvasDiv")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');
                var ppt = new PptxGenJS();
                var slides = ppt.addNewSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.5, w: 9.0, h: 4.0 });
                ppt.save(i18n.t('projectPrioritization'));
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('ProjectID'), field: 'ProjectId', tooltipField: 'ProjectID', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('lever'), field: 'LeverName', tooltipField: 'Lever', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('projectDetails'), field: 'ProjectDetails', width: 677, tooltipField: 'ProjectName', suppressMovable: true, suppressResize: true, autoHeight: true },
            { headerName: i18n.t('Timemonths'), field: 'TimeMonths', tooltipField: 'Time', suppressMovable: true, suppressResize: true, width: 150 },
            { headerName: i18n.t('ImpactonY'), field: 'ImpactOnBOI', tooltipField: 'ImpactOnY', suppressMovable: true, suppressResize: true, width: 150 },
            { headerName: i18n.t('Remarks'), field: 'Remarks', tooltipField: 'Remarks', suppressMovable: true, suppressResize: true, width: 200 }
        ];

        var ProjectPrioritizationDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_prjctprior" id="prjctprior_grd"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.ProjectPrioritizationGridData}
                    enableSorting={false}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd"><span className="menu2" data-content="HOME" data-url="/Home"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home"
                    onClick={
                        (this.state.isBOIMetricCritical || this.state.isLIMetricCritical) ? this.AssessmentEvalAid : this.onNavigationClick}>
                    <img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a></span>
                    <ul className="pagecounter"  >

                        <li onClick={(this.state.isBOIMetricCritical || this.state.isLIMetricCritical) ? this.AssessmentEvalAid : this.onNavigationClick} data-url="/CreateNewAssessment"><span title="" className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Take New Assessment" data-url="/CreateNewAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/BOI"><span title={i18n.t("BOIKeyBusinessMetric")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/LeadingIndicator"><span title={i18n.t("lEADINGINDICATOR")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/PMMAssessment"><span title={i18n.t("pMMASSESSMENT")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/Storyboard"><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/PotentialProject"><span title={i18n.t("pOTENTIALPROJECTS")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects" data-url="/PotentialProject"></span></li>
                        <li><span title={i18n.t('pROJECTPRIORITIZATION')} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">...</span></li>

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} url={this.state.toURL} navigationPage={this.state.navigationPage} isEditAcitve={false} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <h4><div className="pull-left">{i18n.t('pROJECTPRIORITIZATION')}</div>
                        <div className="pull-right">
                                    <span class='statustxt-orng-italic'  title={i18n.t('OMID') + ': ' + this.props.configDetails.OMID}> OMID : {this.props.configDetails.OMID} </span>
                                        {/* <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                                         &nbsp;&nbsp;|| */} 
                                         

                                    </div>
                        </h4>

                        <h4>
                            <div className="btn-group-md">
                                <a type="button" title={i18n.t("downloadgraphinppt")} className="btn btn-info pull-left" onClick={this.downloadPPT}><img src={pptIcon} /> {i18n.t('downloadasPPT')}</a>

                            </div>
                            <div className="btn-group btn-group-pmmasmt">
                                {
                                (this.props.groupId != "1") ?
                                
                                    <button id="btnUploadReport" type="button" className="btn btn-default" title={i18n.t("UploadReportOutputDocumentsforthisAssessment")} onClick={this.showReportDocumentUploadPopup.bind(this)}>
                                        <span class="glyphicon glyphicon-open" style={{ fontSize: "25px" }}></span>
                                    </button>
                                    : null
                                }
                                <button id="graph" type="button" className="btn btn-default btn-graph active" onClick={this.toggleGraph}>
                                    <img src={graphIcon} />
                                </button>
                                <button id="grid" type="button" className="btn btn-default btn-list" onClick={this.toggleGraph}>
                                    <img src={listDefaultIcon} />
                                </button>
                                <button id="graphgrid" type="button" className="btn btn-default btn-graphlist icon2type" onClick={this.toggleGraph}>
                                    <img src={graphListIcon} />
                                </button>
                                <button id="btn_downloadReferenceDocumentsForThisAssessment" type="button" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default" onClick={this.showDownloadReferencePopup.bind(this)}>
                                    <img src={downloadGreenIcon} />
                                </button>
                            </div>
                            <div className="btn-group btn-group-pmmasmt">
                                {this.state.Confirm ? <ConfirmSubmit show={this.state.Confirm} reqSrc={this.state.reqSrc} PMMAllDetails={this.state.PMMAllDetails} /> : null}
                            </div>
                        </h4>
                        <div className="box-plain pmm-asmt-tbl">
                            {this.state.grid ? <div className="box">
                                {ProjectPrioritizationDataGrid}
                            </div> : null}

                            <div id="bubbleCanvasDiv" className="row" style={{ display: this.state.graph ? "block" : "none" }}>
                                <div className="col-md-10">
                                    <div id="bubbleDiv">
                                        <canvas id="bubble" width="400" height="150"></canvas>
                                    </div>
                                </div>
                                <div id="bubbleLegend" style={{ "text-align": "center" }} className="col-md-2">
                                    <span style={{ "text-orientation": "sideways", "transform": "rotate(270deg)", "text-align": "center", "line-height": "50px", "writing-mode": "vertical-lr" }} >{i18n.t('Impact')}</span>
                                    <svg height="100" width="150" style={{ border: "1px solid #95a5a6" }}>
                                        <path d="M0 0 L0 100 L80 0 Z" fill="#81C784" stroke="#95a5a6" stroke-width="1" />
                                        <path d="M0 100 L80 0 L150 0 L150 50Z" fill="#FFEB3B" stroke="#95a5a6" stroke-width="1" />
                                        <path d="M150 100 L0 100 L150 50 Z" fill="#E57373" stroke="#95a5a6" stroke-width="1" />
                                        <text x="3" y="30" font-size="10">{i18n.t('Priority')} 1</text>
                                        <text x="80" y="30" font-size="10">{i18n.t('Priority')} 2</text>
                                        <text x="82" y="85" font-size="10">{i18n.t('Priority')} 3</text>
                                        {i18n.t('SorryourbrowsersupportinlineSVG')}
                                    </svg>
                                    <p className="timeCls">{i18n.t('Time')}</p>
                                </div>
                            </div>



                        </div>
                        <div className="btn-group-md">
                            <a type="button" className="btn btn-danger" onClick={(this.state.isBOIMetricCritical || this.state.isLIMetricCritical) ? this.AssessmentEvalAid : this.SaveFinalAssessment.bind(this)}>{i18n.t('SaveasDraft')}</a>
                            <a type="button" className="btn btn-success" onClick={(this.state.isBOIMetricCritical || this.state.isLIMetricCritical) ? this.AssessmentEvalAid : this.SaveFinalAssessment.bind(this)}>{i18n.t('SubmitFinalAssessment')}</a>
                        </div>
                    </div>
                    {this.state.popUpAssessmentEvalAid && <AssessmentEvalAidPopUp show={this.state.popUpAssessmentEvalAid} closeCallback={this.closeAssessmentEvalAidPopup} selectedBOI={this.state.BOIDetailsData} selectedLI={this.state.LIDetailsData} selectedPMMScore={this.props.location.AsIsProps} selectedPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} countOfReportOutDocuments={this.state.reportDocumentsCount} onReportOutPopup={this.state.popUpAssessmentEvalAid} />}
                    {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={i18n.t('all')} SCID={this.state.SCID} />}
                    {this.state.popUpReportDocuments && <ReportDocumentUpload show={this.state.popUpReportDocuments} closeCallback={this.closeReportDocumentUploadPopup.bind(this)} selectedPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}
                    <div id="ServiceComponent"></div>
                </section>
            </section >
        );
    }
}


function mapStateToProps(state){
    return{
		languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
    }
}

ProjectPrioritization = connect(mapStateToProps)(withRouter(ProjectPrioritization))
export default ProjectPrioritization;
