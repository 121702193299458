/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component, createRef } from "react";
import $ from "jquery";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import EditConfirmation from "../../Master/PMMConfiguration/EditConfirmation";
import NavigationConfirmation from "../../PMM/NavigationConfirmation";
import backArrowIcon from "../../images/icon-back-arrow.png";
import editIcon from "../../images/icon-edit-active.png";
import request from "../../services/Service";
import ConfirmUserAddition from "./ConfirmUserAddition";
import { Typeahead } from "react-bootstrap-typeahead";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { AgGridLocale } from "../../Utils/AgGridLocale";
import { connect } from "react-redux";
import { inputValidator } from "../../Utils/InputElementValidation";
import { isEmpty } from "lodash";

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const getSuggestionValue = (suggestion) => suggestion.EnterpriseID;

const renderSuggestion = (suggestion) => <span>{suggestion.EnterpriseID}</span>;

var flag = false;

class UserManagement extends Component {
  constructor(props) {
    super(props);
    try {
      this.onRoleChange = this.onRoleChange.bind(this);

      this.createUpdateUser = this.createUpdateUser.bind(this);
      this.createEditCell = this.createEditCell.bind(this);
      this.onEditCell = this.onEditCell.bind(this);
      this.userUpdateCall = this.userUpdateCall.bind(this);
      this.onInputChange = this.onInputChange.bind(this);
      this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
      this.checkValues = this.checkValues.bind(this);
      this.onSearchUser = this.onSearchUser.bind(this);
      this.clearInputs = this.clearInputs.bind(this);
      this.reSizeGrid = this.reSizeGrid.bind(this);
      this.gridReadyFun = this.gridReadyFun.bind(this);
      this.onOGChange = this.onOGChange.bind(this);
      this.formatActive = this.formatActive.bind(this);
      this.onDelLocChange = this.onDelLocChange.bind(this);
      this.filterRoles = this.filterRoles.bind(this);
      this.getSuggestions = this.getSuggestions.bind(this);
      this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
      this.onSuggestionsFetchRequested =
        this.onSuggestionsFetchRequested.bind(this);
      this.onSuggestionsClearRequested =
        this.onSuggestionsClearRequested.bind(this);
      this.onNavigationClick = this.onNavigationClick.bind(this);
      this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
      this.closeEditPopUp = this.closeEditPopUp.bind(this);
      this.passValue = this.passValue.bind(this);
      this.populateValues = this.populateValues.bind(this);
      this.onChange = this.onChange.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.onSelectYes = this.onSelectYes.bind(this);
      this.onSelectNo = this.onSelectNo.bind(this);
      this.langChanged = this.langChanged.bind(this);

      this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
      this.langChanged = this.langChanged.bind(this);
      this.state = {
        UserID: 0,
        UserName: "",
        FirstName: "",
        LastName: "",
        RoleName: "",
        IsActive: true,
        roleSelected: [],
        userGridData: [],
        roleOptions: [],
        langOptions: [
          {
            label: "English",
            value: 1,
            languagekey: "en",
          },
          {
            label: "Japanese",
            value: 2,
            languagekey: "ja",
          },
        ],
        IsSaveActive: false,
        OGSelected: [],
        OGOptions: [],
        DelLocOptions: [],
        DelLocationSelected: [],
        showOGSelect: false,
        userRole: "",
        isEditPopUp: false,
        isUpdateExisting: false,
        isUnSaved: false,
        popUpDisplay: false,
        toURL: "",
        navigationPage: "",
        value: "",
        suggestions: [],
        pageNames: [],
        Confirm: false,
        reqSrc: "",
        userDetails: [],
        langID: [],
        defaultLanguage: [],
      };
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentWillMount() {}

  componentDidMount() {
    try {
      //window.tfo_event("PMM-MC-12", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      let objThis = this;
      this.setState({
        userRole: this.props.roleName,
        pageNames: this.props.pageName,
      });
      this.props.languageId == 1
        ? objThis.state.langID.push(objThis.state.langOptions[0])
        : objThis.state.langID.push(objThis.state.langOptions[1]);
      //this.loadUsers();
      this.getValues();

      window.addEventListener("resize", this.reSizeGrid, true);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentWillUnmount() {
   // debugger;
    try {
      window.removeEventListener("resize", this.reSizeGrid, true);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  reSizeGrid() {
  //  debugger;
    try {
      this.refs.user_grid.gridOptions.api.sizeColumnsToFit();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //Fetches Users
  loadUsers() {
    try {
      let objThis = this;
      $("#loader-div").show();
      let userData = {
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
        UserID: 0,
        RoleID: 0,
      };
      request("/Common/GetUserDetailsUserManagement", "POST", userData)
        .then((res) => {
          $("#loader-div").hide();
          this.setState({
            userGridData: res,
          });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //Fetches role and OG values
  getValues() {
    try {
      $("#loader-div").show();
      let dropdownData = {
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
        ID: 0,
        DealId: 0,
      };

      request("/Common/DropDownsValue", "POST", dropdownData)
        .then((response) => {
          $("#loader-div").hide();
        //  debugger;
          let roleOptions = this.filterRoles(response.Role);
          this.setState({
            roleOptions: roleOptions,
            OGOptions: response.OperatingGroup,
            DelLocOptions: response.DeliveryLocation,
          });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  filterRoles(roleArr) {
    try {
      let newRoleArr = [],
        roles = roleArr;

      if (this.state.userRole == "Super Admin") {
        newRoleArr = roles;
      } else if (this.state.userRole == "Admin" && this.props.groupId == 1) {
        newRoleArr = roles.filter(
          (role) => role.label != "Admin" && role.label != "Super Admin"
        );
      } else if (this.state.userRole == "Admin" && this.props.groupId == 2) {
        newRoleArr = roles.filter((role) => role.label != "Super Admin");
      }

      this.setState({ Confirm: false });
      return newRoleArr;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //On change of role
  onRoleChange(value) {
    console.log(value);

    try {
      let showOG = value.value == 4 ? true : false;
      this.setState({
        roleSelected: value,
        showOGSelect: showOG,
        OGSelected: [],
        DelLocationSelected: [],
        isUnSaved: true,
        Confirm: false,
      });
      this.checkValues();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onOGChange(value) {
    try {
      this.setState({
        OGSelected: value,
        isUnSaved: true,
      });
      this.checkValues();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onDelLocChange(value) {
    try {
      this.setState({
        DelLocationSelected: value,
        isUnSaved: true,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //Constructs the user object for saving
  createUpdateUser(eve) {
    try {
      let {
        UserID,
        UserName: EnterpriseID,
        FirstName,
        LastName,
        IsActive,
        roleSelected,
        isUpdateExisting,
        OGSelected,
        DelLocationSelected,
        langID,
      } = this.state;
      let OG = "";
      let DelLocs = "";
console.log(eve,this.state)
      let logginUser = this.props.enterpriseId;
      let Operation = 0;
      if (
        EnterpriseID == "" ||
        EnterpriseID == undefined ||
        // FirstName == "" ||
        // FirstName == undefined ||
        // LastName == "" ||
        // LastName == undefined ||
        roleSelected == undefined ||
        roleSelected.length <= 0
      ) {
        alert(i18n.t("pleasefillallvaluestosavetheuser"));
      } else {
        if (DelLocationSelected && DelLocationSelected.length > 0) {
          DelLocationSelected.map((DelLoc, index) => {
            if (index == 0) {
              DelLocs = DelLoc.value;
            } else {
              DelLocs = DelLocs + "," + DelLoc.value;
            }
          });
        } else {
          DelLocs = "";
        }
        if (UserID != 0) {
          Operation = 2;
        } else {
          Operation = 1;
        }
        let userObj = {
          UserID: UserID,
          UserName: logginUser,
          EnterpriseID: EnterpriseID,
          FirstName: FirstName,
          LastName: LastName,
          RoleId: roleSelected[0].value,
          RoleName:"string",
          Operation: Operation,
          OGIds: "string",
          OGName:"string",
          DeliveryLocationIds: "string",
          DeliveryLocationName:"string",
          CreatedBy:"string",
          CreateDate:"string",
          UpdatedBy:"string",
          UpdatedDate:"string",
          IsActive: IsActive,
          OGID:"string",
          Flag: flag,
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
          LanguageName:"string",
          langID: langID == 0 || langID.length == 0 ? 1 : langID[0].value,
        };

        if (
          eve &&
          typeof eve != "boolean" &&
          isUpdateExisting &&
          EnterpriseID != "" &&
          UserID != 0
        ) {
          this.setState({
            isEditPopUp: true,
          });
        } else {
          this.userUpdateCall(userObj);
        }
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //Calls the API to save the user
  userUpdateCall(userObj) {
    try {
      let userBodyData = userObj;
      let EntId = inputValidator(userObj.EnterpriseID);
      let FirstName = inputValidator(userObj.FirstName);
      let LastName = inputValidator(userObj.LastName);
      let reqSrc = "Save";
console.log(userObj,"user")
      if (EntId === false && FirstName == false && LastName ==false) {
        $("#loader-div").show();
        request("/Common/UpsertUsers", "POST", userBodyData)
          .then((res) => {
            $("#loader-div").hide();
            if (res.Outputcode == -1) {
              alert(res.outputmessage);
            } else if (
              res.outputmessage == i18n.t("success") ||
              res.outputmessage == i18n.t("failed") ||
              res.outputmessage != i18n.t("success") ||
              res.outputmessage != i18n.t("failed")
            ) {
              if (res.ReasonPhrase != null) {
                alert(i18n.t("PleaseEntervalidValuesintheFields"));
              } else {
                alert(res.outputmessage);
              }
              this.clearInputs();
            } else {
              this.setState({
                Confirm: true,
                message: res.outputmessage,
                reqSrc,
              });
            }
            flag = false;
            this.loadUsers();
          })
          .catch((error) => {
            console.log("Error Encountered");
          });
      } else {
        alert(i18n.t("PleaseEntervalidValuesintheFields"));
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //Resets the form
  clearInputs() {
    try {
      this.setState({
        UserID: 0,
        UserName: "",
        FirstName: "",
        LastName: "",
        RoleName: "",
        IsActive: true,
        roleSelected: [],
        OGSelected: [],
        DelLocationSelected: [],
        showOGSelect: false,
        isUnSaved: false,
        searchUser: "",
        Confirm: false,
        langID: [],
      });
      this.checkValues();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onSelectYes(eve) {
    try {
      flag = true;
      this.createUpdateUser(eve);
      this.clearInputs();
      this.loadUsers();
      this.setState({ Confirm: false });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onSelectNo() {
    try {
      this.clearInputs();
      this.setState({ Confirm: false });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  //On Change of user inputs
  onInputChange(eve) {
    try {
      let pattern = /^[A-Za-z ]+$/;
      let attr = eve.target.getAttribute("data-field");
      let val = "";
      val = eve.target.value != "" ? eve.target.value : null;

      if (attr != "UserName") {
        if (pattern.test(val) || val == "") {
          this.setState({
            [attr]: val,
            isUnSaved: true,
            Confirm: false,
          });
        }
      } else {
        this.setState({
          [attr]: val,
          isUnSaved: true,
          Confirm: false,
        });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onCheckBoxChange(eve) {
    try {
      let attr = eve.target.getAttribute("data-field");
      this.setState({
        [attr]: eve.target.checked,
        isUnSaved: true,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  createEditCell() {
    try {
      let editCell = document.createElement("div");
      editCell.innerHTML = `<span><img src=${editIcon}></span>`;
      editCell.className = "col-md-12 editIcon";
      editCell.width = "100%";
      return editCell;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onEditCell(eve) {
    try {
      if (eve.colDef.headerName == i18n.t("editUser")) {
        this.populateValues(eve.data);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  populateValues(userObj) {
    try {
      if (
        this.state.userRole == "Admin" &&
        (userObj.RoleId == 1 || userObj.RoleId == 2) &&
        this.props.groupId == 1
      ) {
        alert(i18n.t("youdonthaveaccesstoeditthisuser"));
        return;
      }
      let OGDisplay = userObj.RoleId == 4 ? true : false;
      let OGSelected = [],
        DelLocSelected = [];
      let OGs =
        userObj.OGIds && userObj.OGIds.length != 0
          ? userObj.OGIds.split(",")
          : [];
      let OGNames =
        userObj.OGName && userObj.OGName.length != 0
          ? userObj.OGName.split(",")
          : [];
      let DLIds =
        userObj.DeliveryLocationIds && userObj.DeliveryLocationIds.length != 0
          ? userObj.DeliveryLocationIds.split(",")
          : [];
      let DLNames =
        userObj.DeliveryLocationName && userObj.DeliveryLocationName.length != 0
          ? userObj.DeliveryLocationName.split(",")
          : [];

      OGs.map((OGId, index) => {
        let OGObj = {
          label: OGNames[index],
          value: parseInt(OGId),
        };
        OGSelected.push(OGObj);
      });

      DLIds.map((DLId, index) => {
        let DLObj = {
          label: DLNames[index],
          value: parseInt(DLId),
        };
        DelLocSelected.push(DLObj);
      });
      var language = [];
      if (userObj.langID == 1) {
        language.push(this.state.langOptions[0]);
      } else {
        language.push(this.state.langOptions[1]);
      }
      this.setState({
        UserID: userObj.UserID,
        UserName: userObj.EnterpriseID || "",
        FirstName: userObj.FirstName || "",
        LastName: userObj.LastName || "",
        RoleName: userObj.RoleName || "",
        IsActive: userObj.IsActive || false,
        langID: language,
        roleSelected: [
          {
            value: userObj.RoleId,
            label: userObj.RoleName,
          },
        ],
        OGSelected: userObj.RoleId == 4 ? OGSelected : [],
        DelLocationSelected: userObj.RoleId == 4 ? DelLocSelected : [],
        showOGSelect: OGDisplay,
        isUnSaved: true,
        isUpdateExisting: true,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  checkValues() {
    try {
      if (
        this.state.UserName != "" &&
        this.state.UserName != null &&
        this.state.roleSelected != [] &&
        this.state.roleSelected != null
      ) {
        this.setState({
          IsSaveActive: true,
        });
      } else {
        this.setState({
          IsSaveActive: false,
        });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onSearchUser(eve) {
    try {
      this.setState({
        searchUser: eve.target.value,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  gridReadyFun(eve) {
   // debugger;
    try {
      $("#loader-div").hide();
      let objThis = this;
      $("#loader-div").show();
      let userData = {
        UserName: this.props.enterpriseId,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
        UserID: 0,
        RoleID: 0,
      };
      eve.api.showLoadingOverlay();
      request("/Common/GetUserDetailsUserManagement", "POST", userData)
        .then((res) => {
          if (res.length !== 0) {
            eve.api.hideOverlay();
            $("#loader-div").hide();
            this.setState({
              userGridData: res,
            });
          } else {
            eve.api.showNoRowsOverlay();
          }
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
      eve.api.sizeColumnsToFit();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  passValue(value) {
    try {
      this.setState({
        isUpdateExisting: false,
        DealID: value ? this.state.UserName : 0,
      });
      if (value)
        
        {
          console.log(value)
        this.createUpdateUser(value);
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  /*Function closes the Edit popup */
  closeEditPopUp() {
    try {
      this.setState({
        isEditPopUp: false,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  /*Function closes the Navigation popup */
  closeNavigationPopUp() {
    try {
      this.setState({
        popUpDisplay: false,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  /* Function formats the active column data in the grid */
  formatActive(params) {
    try {
      return params.value ? i18n.t("yes") : i18n.t("no");
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  /*Function prompts a pop up in case on unsaved data*/
  onNavigationClick(eve) {
    try {
      let navigationPage = eve.target.getAttribute("data-content");
      // if(navigationPage == i18n.t('dealManagement')){
      //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      // }
      // if(navigationPage == i18n.t('pMMConfiguration')){
      //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      // }
      let url = eve.target.getAttribute("data-url");
      if (this.state.isUnSaved) {
        this.setState({
          popUpDisplay: true,
          toURL: url,
          navigationPage: navigationPage,
        });
      } else {
        if (navigationPage == "HOME") {
          this.setState({
            popUpDisplay: true,
            toURL: url,
            navigationPage: navigationPage,
          });
        } else {
          this.props.history.push(url);
        }
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  /*Changes for Auto Suggest */
  getSuggestions(value) {
    try {
      const escapedValue = escapeRegexCharacters(value.trim());
      if (escapedValue === "") {
        return this.state.userGridData;
      } else {
        const regex = new RegExp("^" + escapedValue, "i");
        let newSuggestions = this.state.userGridData.filter((user) =>
          regex.test(user.EnterpriseID)
        );
        return newSuggestions;
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onChange = (event, { newValue }) => {
    try {
      this.setState({
        UserName: newValue,
        isUnSaved: true,
      });
      $(event.target).attr("type", "new");
    } catch (error) {
      console.log("Error Encountered");
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    try {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  };

  onSuggestionsClearRequested = () => {
    try {
      this.setState({
        suggestions: [],
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  };

  onSuggestionSelected(event, { suggestion }) {
    try {
      if (event.target.tagName == "SPAN") {
        $(event.target)
          .parent()
          .parent()
          .parent()
          .parent()
          .find("input")
          .attr("type", "existing");
      } else {
        $(event.target)
          .parent()
          .parent()
          .parent()
          .find("input")
          .attr("type", "existing");
      }
      this.populateValues(suggestion);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onBlur(event, { suggestion }) {
    try {
      let type = $(event.target).attr("type");

      let value = event.target.value.toLowerCase();
      this.setState({ Confirm: false });
      this.state.userGridData.map((user) => {
        if (user.EnterpriseID.toLowerCase() === value) {
          this.populateValues(user);
        }
      });
      if (type == "new") {
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  filterCallbackTypeahead(option, props) {
    try {
      if (props.selected.length) {
        return true;
      }
      return (
        option[props.labelKey]
          .toLowerCase()
          .indexOf(props.text.toLowerCase()) !== -1
      );
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  langChanged(e) {
    if (e == null || e == "") {
      return false;
    }
    this.setState({ langID: e });
  }

  render() {
    let { t } = this.props;
    var columnDefs = [];
    var IsSaveEnabled = false;
    columnDefs = [
      {
        headerName: t("editUser"),
        cellRenderer: this.createEditCell,
        suppressResize: true,
        suppressMovable: true,
      },
      {
        headerName: t("userName"),
        field: "EnterpriseID",
        suppressResize: true,
        tooltipField: "User Name",
        suppressMovable: true,
      },
      {
        headerName: t("firstName"),
        field: "FirstName",
        suppressResize: true,
        tooltipField: "First Name",
        suppressMovable: true,
      },
      {
        headerName: t("lastName"),
        field: "LastName",
        suppressResize: true,
        tooltipField: "Last Name",
        suppressMovable: true,
      },
      {
        headerName: t("role"),
        field: "RoleName",
        suppressResize: true,
        tooltipField: "Role",
        suppressMovable: true,
      },
      {
        headerName: t("active"),
        field: "IsActive",
        valueFormatter: this.formatActive,
        suppressResize: true,
        tooltipField: "Active",
        suppressMovable: true,
      },
      {
        headerName: t("preferredLanguage"),
        field: "LanguageName",
        suppressResize: true,
        tooltipField: "Language Name",
        suppressMovable: true,
      },
    ];

    var userGrid = (
      <div className="ag-theme-material grid-div">
        <AgGridReact
          ref="user_grid"
          id="grd_user"
          columnDefs={columnDefs}
          rowData={this.state.userGridData}
          enableSorting={true}
          enableFilter={true}
          onGridReady={this.gridReadyFun}
          paginationPageSize="10"
          rowmodeltype="pagination"
          enableColResize="true"
          onCellClicked={this.onEditCell}
          quickFilterText={this.state.searchUser}
          localeText={AgGridLocale()}
          overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t(
            "dataLoading"
          )}</span>`}
        />
      </div>
    );

    if (
      this.state.UserName != "" &&
      this.state.UserName != null &&
      this.state.roleSelected != [] &&
      this.state.roleSelected != null
    ) {
      IsSaveEnabled = true;
    } else {
      IsSaveEnabled = false;
    }

    let { suggestions, UserName: value } = this.state;
    let inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };
    console.log(this.state.roleOptions);
    return (
      <section className="content" id="user-mgt-section">
        <div className="innerpage-hd">
          <a
            type="button"
            className="btn btn-info btn-back pull-right"
            data-content="HOME"
            data-url="/Home"
            onClick={this.onNavigationClick}
          >
            <img src={backArrowIcon} data-content="HOME" data-url="/Home" />{" "}
            {i18n.t("bACKTOHOME")}
          </a>
          <ul className="adminMenu">
            {this.state.pageNames.includes("PMMConfiguration") ? (
              <li>
                <a
                  className="menu3"
                  data-content="MASTERS Home"
                  data-url="/PMMConfiguration"
                  onClick={this.onNavigationClick}
                >
                  {i18n.t("mASTERS")}
                </a>
              </li>
            ) : null}

            {this.state.pageNames.includes("UserManagement") ? (
              <li className="active">
                <a
                  className="menu1"
                  data-content="User Management"
                  data-url="/UserManagement"
                  onClick={this.onNavigationClick}
                >
                  {i18n.t("userManagement")}
                </a>
              </li>
            ) : null}

            {/* {this.state.pageNames.includes("DealManagement") ? (
              <li>
                <a
                  className="menu4"
                  data-content="Deal Management"
                  data-url="/DealManagement"
                  onClick={this.onNavigationClick}
                >
                  {i18n.t("dealManagement")}
                </a>
              </li>
            ) : null} */}

            {this.state.pageNames.includes("BOIConfiguration") ? (
              <li>
                <a
                  className="menu2"
                  data-content="PMM Configuration"
                  data-url="/BOIConfiguration"
                  onClick={this.onNavigationClick}
                >
                  {i18n.t("pMMConfiguration")}
                </a>
              </li>
            ) : null}

            {this.state.pageNames.includes("UnlockAssessment") ? (
              <li>
                <a
                  className="menu5"
                  data-content="Unlock Assessment"
                  data-url="/UnlockAssessment"
                  onClick={this.onNavigationClick}
                >
                  {i18n.t("unlockAssessment")}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
        <section className="multiple-tabs-section">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="active"
                data-content="User Management"
                data-url="/UserManagement"
                onClick={this.onNavigationClick}
              >
                {i18n.t("createUser")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className=""
                data-content="Deal Mapping"
                data-url="/DealMapping"
                onClick={this.onNavigationClick}
              >
                {i18n.t("userAccessMapping")}
              </a>
            </li>
          </ul>
          {this.state.Confirm ? (
            <ConfirmUserAddition
              show={this.state.Confirm}
              bodyMessage={this.state.message}
              reqSrc={this.state.reqSrc}
              onSelectYes={this.onSelectYes}
              onSelectNo={this.onSelectNo}
            />
          ) : null}
          {this.state.popUpDisplay && (
            <NavigationConfirmation
              show={this.state.popUpDisplay}
              navigationPage={this.state.navigationPage}
              isEditAcitve={this.state.isUnSaved}
              url={this.state.toURL}
              closeCallback={this.closeNavigationPopUp}
            />
          )}
          {this.state.isEditPopUp && (
            <EditConfirmation
              show={this.state.isEditPopUp}
              type={"User"}
              editValue={this.state.UserName}
              passValue={this.passValue}
              closeCallback={this.closeEditPopUp}
            />
          )}
          <div className="box">
            <div id="userCreate" className="box-in">
              <div className="row">
                <div className="col-sm-12">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <div className="row">
                        <label className="control-label col-sm-4" id="lbl_eid">
                          {i18n.t("enterpriseID")}:
                          <span className="mandatory-cls">*</span>
                        </label>
                        <div className="col-sm-8">
                          <Autosuggest
                            ref="userName"
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={
                              this.onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              this.onSuggestionsClearRequested
                            }
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_fname"
                        >
                          {i18n.t("firstName")}:
                          {/* <span className="mandatory-cls">*</span> */}
                        </label>
                        <div className="col-sm-8">
                          <input
                            ref="firstName"
                            id="txt_firstName"
                            data-field="FirstName"
                            pattern="[A-Za-z]"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.FirstName}
                            onChange={this.onInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_lName"
                        >
                          {i18n.t("lastName")}:
                          {/* <span className="mandatory-cls">*</span> */}
                        </label>
                        <div className="col-sm-8">
                          <input
                            ref="lastName"
                            id="txt_lastName"
                            data-field="LastName"
                            pattern="[A-Za-z]"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.LastName}
                            onChange={this.onInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <div className="row">
                        <label className="control-label col-sm-4" id="lbl_role">
                          {i18n.t("role")}:
                          <span className="mandatory-cls">*</span>
                        </label>
                        <div className="col-sm-8">
                          <Typeahead
                            emptyLabel={i18n.t("noMatchesFound")}
                            ref="role"
                            id="ddl_roleUserMgmt"
                            data-field="Role"
                            name="form-field-name"
                            filter={true}
                            labelKey="label"
                            onChange={this.onRoleChange.bind(this)}
                            options={this.state.roleOptions}
                            selected={this.state.roleSelected}
                            filterBy={this.filterCallbackTypeahead.bind(this)}
                            placeholder={i18n.t("selectPlace")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="col-sm-5 ">
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_language"
                        >
                          {i18n.t("language")}
                        </label>
                        <div className="col-sm-8">
                          <Typeahead
                            emptyLabel={i18n.t("noMatchesFound")}
                            ref="langauge"
                            id="ddl_language"
                            options={this.state.langOptions}
                            placeholder={i18n.t("selectPlace")}
                            filter={true}
                            labelKey="label"
                            selected={this.state.langID}
                            onChange={this.langChanged}
                            filterBy={this.filterCallbackTypeahead.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_isActiveUser"
                        >
                          {i18n.t("isActive")}:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="checkbox"
                            ref="IsActive"
                            id="chk_isAct"
                            data-field="IsActive"
                            checked={this.state.IsActive}
                            onChange={this.onCheckBoxChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="saveDiv row">
            <div className="btn-group-md">
              <input
                type="button"
                id="btn_save"
                className="btn btn-success"
                value={i18n.t("save")}
                onClick={this.createUpdateUser}
              />
              <input
                type="button"
                id="btn_clear"
                className="btn btn-danger"
                value={i18n.t("clear")}
                onClick={this.clearInputs}
              />
              <a
                className=""
                data-content="User Deal Mapping"
                data-url="/DealMapping"
                onClick={this.onNavigationClick}
              >
                <input
                  type="button"
                  id="btn_next"
                  className="btn btn-info"
                  value={i18n.t("next")}
                  data-content="User Deal Mapping"
                  data-url="/DealMapping"
                />
              </a>
            </div>
            
          </div>
          <div id="userDetailsDiv">
            <div>
              <h4 className="fleft">{i18n.t("uSERDETAILS")}</h4>
              <input
                type="text"
                id="txt_searchUserMgmt"
                placeholder={i18n.t("searchUser")}
                className="fright searchBox"
                value={this.state.searchUser}
                onChange={this.onSearchUser}
              />
            </div>
            <div className="box-plain pmm-asmt-tbl">{userGrid}</div>
          </div>
        </section>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    languageId: state.languageId,
    enterpriseId: state.enterpriseId,
    groupId: state.groupId,
    roleName: state.roleName,
    pageName: state.pageName,
    groupName: state.groupName,
  };
}
UserManagement = connect(mapStateToProps)(withTranslation()(UserManagement));
export default withRouter(UserManagement);
