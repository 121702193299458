/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import NavigationConfirmation from '../NavigationConfirmation';
import BenchmarkPopup from '../BenchmarkPopup';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import downloadBrownIcon from '../../images/icon-download-brown.png';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { inputValidator } from '../../Utils/InputElementValidation';
import { BaseURL } from '../../Constant';

var numericStatus = true;
var editActive = false;


function CreateCheckbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.width = "100%";
    }
    catch (error) {
        console.log("error encountered");
    }
}

CreateCheckbox.prototype.init = function (params) {
    try {
        var checkboxElement = document.createElement("input");
        checkboxElement.type = "checkbox";
        checkboxElement.id = params.data.BOIId;
        checkboxElement.className = "findColumns";

        checkboxElement.checked = params.data.IsSelected;
        checkboxElement.addEventListener('change', function (event) {
            editActive = true;
            params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => { if (index == params.rowIndex) { eachRow["IsSelected"] = event.currentTarget.checked } })
        });

        this.eGui.appendChild(checkboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.onChange = function (params) {

};

function CreateTextbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.style.height = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.init = function (params) {
    try {
        var textboxElement = document.createElement("input");
        textboxElement.type = "textbox";
        textboxElement.style.width = "100%"
        textboxElement.style.height = "100%"

        if(params.colDef.headerName == "Display Name"){
            textboxElement.value = params.data.DisplayName;
        }
        else{
            textboxElement.value = "";
        }
        if(params.colDef.headerName == i18n.t("As-Is")){
            textboxElement.value = params.data.AsIs;
        }
        else{
            textboxElement.value = "";
        }
        if(params.colDef.headerName == i18n.t("To-Be")){
            textboxElement.value = params.data.ToBe;
        }
        else{
            textboxElement.value = "";
        }
        textboxElement.addEventListener('change', function (event) {
            params.data[params.colDef.field] = event.target.value;

        });
        this.eGui.appendChild(textboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}



class BOIGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickFilterText: null,
            boiGridData: [],
            isEditAcitve: false,
            popUpDisplay: false,
            toURL: '',
            navigationPage: "",
            popUpBenchmark: false,
            selectedMetric: ""
        };
        this.SaveBOIDetails = this.SaveBOIDetails.bind(this);
        this.deleteBOIDetails = this.deleteBOIDetails.bind(this);
        this.onOverallEdit = this.onOverallEdit.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.DownloadReferenceContentExcel = this.DownloadReferenceContentExcel.bind(this);
        this.valueChange = this.valueChange.bind(this);
        this.checkCells = this.checkCells.bind(this);
        this.closeBenchmarkPopUp = this.closeBenchmarkPopUp.bind(this);
    }

    reSizeGrid() {

        try {
            this.refs.Grd_BOI.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    unloadEvent(e) {
        try {
            let confirmationMessage = i18n.t("leavingthispagewillresultinlossunsaveddata");
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    componentDidMount() {

        try {
            var objThis = this;  
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test"))
            // {
            // window.tfo_dataevent("PMM-TPA-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.props.configDetails.DealName.label)
            // }
            window.addEventListener("beforeunload", this.unloadEvent);
            window.addEventListener('resize', this.reSizeGrid, true);
            $("#loader-div").show();
            sessionStorage.setItem("PMMProcessDraftID", this.props.configDetails.PMMProcessDraftID);
      
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    valueChange(params) {
        try {

            let props = {
                force: true,
                rowNode: params.node
            }
            this.checkCells();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkCells() {
        try {
            let objGridDetails = this.refs.Grd_BOI.gridOptions.rowData;
            var isErrorThere = false;
            var numStatus = false;


            objGridDetails.map((row, i) => {
                let AsIs = parseFloat(row.AsIs);
                let ToBe = parseFloat(row.ToBe);
                let isAsIsErr = false, isToBeErr = false;
                //Max Min Validation without Formula
                if (objGridDetails[i].Formula == "" || objGridDetails[i].Formula == null) {
                    if (objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null && objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "") {
                        var max = parseFloat(objGridDetails[i].Maximum);
                        var min = parseFloat(objGridDetails[i].Minimum);

                        if (AsIs < min) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                        if (AsIs > max) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                            isAsIsErr = true;
                            isErrorThere = true;
                        }
                        if (ToBe < min) {
                            row["ToBeError"] = true;
                            row["ToBeMsg"] = i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                            isToBeErr = true;
                            isErrorThere = true;
                        }
                        if (ToBe > max) {
                            row["ToBeError"] = true;
                            row["ToBeMsg"] = i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                            isToBeErr = true;
                            isErrorThere = true;
                        }
                    }

                    //Less than Equal to validation
                    if (objGridDetails[i].LessThanEqulaTo != null) {
                        if (objGridDetails[i].LessThanEqulaTo == true) {
                            if (AsIs < ToBe) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue")
                                isAsIsErr = true;
                                isErrorThere = true;
                            }
                        }
                        else if (objGridDetails[i].LessThanEqulaTo == false) {
                            if (AsIs > ToBe) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbelessthanequaltoToBevalue")
                                isAsIsErr = true;
                                isErrorThere = true;
                            }
                        }
                    }
                }


                //Zero Validation
                var k = 0;
                if (objGridDetails[i].IsZero != "" && objGridDetails[i].IsZero != null) {
                    for (k = 0; k < objGridDetails.length; k++) {
                        if (objGridDetails[k].UniqueFormulaID == objGridDetails[i].IsZero) {
                            if (objGridDetails[k].AsIs != null && objGridDetails[k].AsIs.toString() == "0") {
                                objGridDetails[i].AsIs = 0;
                            }
                            if (objGridDetails[k].ToBe != null && objGridDetails[k].ToBe.toString() == "0") {
                                objGridDetails[i].ToBe = 0;
                            }
                        }
                    }
                }

                // Formula Validation

                if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == true) {
                    var FinalAsIS = "";
                    var FinalToBe = "";
                    var FormulaAsIs = objGridDetails[i].Formula;
                    var FormulaToBe = objGridDetails[i].Formula;
                    var Formula = objGridDetails[i].Formula;
                    var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                    var j = 0;

                    for (j = 0; j < formulaFields.length; j++) {
                        // Checking For Formula Field Type (Number or Metric Unique ID) 
                        !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            var result = objGridDetails.filter(function (el) {
                                return (el.UniqueFormulaID === formulaFields[j]);
                            });

                            if (result.length > 0) {
                                // Blank Value Check for Formula Fields.
                                if (result[0].AsIs == null || result[0].AsIs === "") {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                    // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                    objGridDetails[i].AsIs = "";
                                }
                                else {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                    // Formula >= and <= for AsIs

                                    if (FormulaAsIs.includes(">=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    row["AsIsError"] = true;

                                                    row["AsIsMsg"] = i18n.t("AsIsvalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoAsIsvalueofmetric") + result[0].BOILIName + ".";
                                                    isAsIsErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaAsIs.includes("<=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    row["AsIsError"] = true;
                                                    row["AsIsMsg"] = i18n.t("AsIsvalueofmetric") + objGridDetails[i].BOILIName + i18n.t("ShouldbetoAsisvalofmatric") + result[0].BOILIName + ".";
                                                    isAsIsErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                }

                                if (result[0].ToBe == null || result[0].ToBe === "") {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                    objGridDetails[i].ToBe = "";
                                }
                                else {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                    // Formula >= and <= for ToBe: Start

                                    if (FormulaToBe.includes(">=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    row["ToBeError"] = true;
                                                    row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofmetric") + result[0].BOILIName + ".";
                                                    isToBeErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaToBe.includes("<=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    row["ToBeError"] = true;
                                                    row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofetric") + result[0].BOILIName + ".";
                                                    isToBeErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    // Formula >= and <= for ToBe: End
                                }
                            }
                        }
                    }

                    try {
                        FinalAsIS = eval(FormulaAsIs);
                        FinalToBe = eval(FormulaToBe);
                    }
                    catch (err) {
                        console.log(err);
                    }

                    //Max Min Validation after Formula calculation
                    var num = false;
                    !isNaN(parseFloat(FinalAsIS)) && isFinite(FinalAsIS) ? num = true : num = false;

                    if (num == true) {
                        if (objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "" && objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null) {
                            var max = parseFloat(objGridDetails[i].Maximum);
                            var min = parseFloat(objGridDetails[i].Minimum);

                            if (FinalAsIS < min) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                                isAsIsErr = true;
                                isErrorThere = true;
                            }
                            if (FinalAsIS > max) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                                isAsIsErr = true;
                                isErrorThere = true;
                            }
                            if (FinalToBe < min) {
                                row["ToBeError"] = true;
                                row["ToBeMsg"] = i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                                isToBeErr = true;
                                isErrorThere = true;
                            }
                            if (FinalToBe > max) {
                                row["ToBeError"] = true;
                                row["ToBeMsg"] = i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                                isToBeErr = true;
                                isErrorThere = true;
                            }
                        }
                        objGridDetails[i].AsIs = FinalAsIS;
                        objGridDetails[i].ToBe = FinalToBe;

                        //Less than Equal to validation after Formula calculation

                        if (objGridDetails[i].LessThanEqulaTo != null) {
                            if (objGridDetails[i].LessThanEqulaTo == true) {
                                if ((FinalAsIS != "" && FinalToBe != "") && (FinalAsIS < FinalToBe)) {
                                    row["AsIsError"] = true;
                                    row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue")
                                    isAsIsErr = true;
                                    isErrorThere = true;

                                }
                            }
                            else if (objGridDetails[i].LessThanEqulaTo == false) {
                                if ((FinalAsIS != "" && FinalToBe != "") && (FinalAsIS > FinalToBe)) {
                                    row["AsIsError"] = true;
                                    row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbelessthanequaltoToBevalue")
                                    isAsIsErr = true;
                                    isErrorThere = true;

                                }
                            }
                        }
                    }
                }

                // Formula Validation For >=Metric and <=Metric

                if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == false) {
                    var FinalAsIS = "";
                    var FinalToBe = "";
                    var FormulaAsIs = objGridDetails[i].Formula;
                    var FormulaToBe = objGridDetails[i].Formula;
                    var Formula = objGridDetails[i].Formula;
                    var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                    var j = 0;
                    for (j = 0; j < formulaFields.length; j++) {
                        !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            var result = objGridDetails.filter(function (el) {
                                return (el.UniqueFormulaID === formulaFields[j]);
                            });

                            if (result.length > 0) {

                                // Blank Value Check for Formula Fields.
                                if (result[0].AsIs == null || result[0].AsIs === "") {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                    // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                    objGridDetails[i].AsIs = "";
                                }
                                else {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                    // Formula >= and <= for AsIs

                                    if (FormulaAsIs.includes(">=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    row["AsIsError"] = true;
                                                    row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoAsIsvalueofmetric") + result[0].BOILIName + ".";
                                                    isAsIsErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaAsIs.includes("<=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    row["AsIsError"] = true;
                                                    row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("ShouldbetoAsisvalofmatric") + result[0].BOILIName + ".";
                                                    isAsIsErr = true;
                                                    isErrorThere = true;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                }

                                if (result[0].ToBe == null || result[0].ToBe === "") {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                    objGridDetails[i].ToBe = "";
                                }
                                else {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                    // Formula >= and <= for ToBe: Start

                                    if (FormulaToBe.includes(">=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    row["ToBeError"] = true;
                                                    row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofmetric") + result[0].BOILIName + ".";
                                                    isToBeErr = true;
                                                    isErrorThere = true;

                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaToBe.includes("<=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    row["ToBeError"] = true;
                                                    row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofetric") + result[0].BOILIName + ".";
                                                    isToBeErr = true;
                                                    isErrorThere = true;

                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    // Formula >= and <= for ToBe: End
                                }
                            }
                        }
                    }

                    //Less than Equal to validation

                    if (objGridDetails[i].LessThanEqulaTo != null) {
                        if (objGridDetails[i].LessThanEqulaTo == true) {
                            if (AsIs < ToBe) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue")
                                isAsIsErr = true;
                                isErrorThere = true;

                            }
                        }
                        else if (objGridDetails[i].LessThanEqulaTo == false) {
                            if (AsIs > ToBe) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbelessthanequaltoToBevalue")
                                isAsIsErr = true;
                                isErrorThere = true;

                            }
                        }
                    }
                }


                if (!isAsIsErr) {
                    row["AsIsError"] = false;
                    row["AsIsMsg"] = i18n.t("AsIsvalueisvalid");
                }
                if (!isToBeErr) {
                    row["ToBeError"] = false;
                    row["ToBeMsg"] = i18n.t("ToBevalueisvalid");
                }
            })
            this.refs.Grd_BOI.gridOptions.api.setRowData(objGridDetails);
            this.setState({
                boiGridData: objGridDetails
            });
            let params = {
                force: true
            }
            this.refs.Grd_BOI.gridOptions.api.refreshCells(params);
            return !isErrorThere;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CheckFormula(objGridDetails, editRow) {
        try {
            var i = 0;
            var updatedGridDetails = [];
            var numStatus = false;
            for (i = 0; i < objGridDetails.length; i++) {

                var AsIs = parseFloat(objGridDetails[i].AsIs);
                var ToBe = parseFloat(objGridDetails[i].ToBe);



                if (objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null && objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "") {
                    var max = parseFloat(objGridDetails[i].Maximum);
                    var min = parseFloat(objGridDetails[i].Minimum);

                    if (AsIs < min) {
                        alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan ") + min.toString());
                        return false
                    }
                    if (AsIs > max) {
                        alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan ") + max.toString());
                        return false
                    }
                    if (ToBe < min) {
                        alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan ") + min.toString());
                        return false
                    }
                    if (ToBe > max) {
                        alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan ") + max.toString());
                        return false
                    }
                }

                //Less than Equal to validation

                if (objGridDetails[i].LessThanEqulaTo != null) {
                    if (objGridDetails[i].LessThanEqulaTo == true) {
                        if (AsIs < ToBe) {
                            alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue"));
                            return false
                        }
                    }
                    else if (objGridDetails[i].LessThanEqulaTo == false) {
                        if (AsIs > ToBe) {
                            alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbelessthanequaltoToBevalue"));
                            return false
                        }
                    }
                }

                //Zero Validation
                var k = 0;
                if (objGridDetails[i].IsZero != "" && objGridDetails[i].IsZero != null) {
                    for (k = 0; k < objGridDetails.length; k++) {
                        if (objGridDetails[k].UniqueFormulaID == objGridDetails[i].IsZero) {
                            if (objGridDetails[k].AsIs != null && objGridDetails[k].AsIs.toString() == "0") {
                                objGridDetails[i].AsIs = 0;
                            }
                            if (objGridDetails[k].ToBe != null && objGridDetails[k].ToBe.toString() == "0") {
                                objGridDetails[i].ToBe = 0;
                            }
                        }
                    }
                }

                // Formula Validation

                if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == true) {
                    var FinalAsIS = "";
                    var FinalToBe = "";
                    var FormulaAsIs = objGridDetails[i].Formula;
                    var FormulaToBe = objGridDetails[i].Formula;
                    var Formula = objGridDetails[i].Formula;
                    var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                    var j = 0;

                    for (j = 0; j < formulaFields.length; j++) {
                        // Checking For Formula Field Type (Number or Metric Unique ID) 
                        !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            var result = objGridDetails.filter(function (el) {
                                return (el.UniqueFormulaID === formulaFields[j]);
                            });

                            if (result.length > 0) {
                                // Blank Value Check for Formula Fields.
                                if (result[0].AsIs == null || result[0].AsIs === "") {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                    // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                    objGridDetails[i].AsIs = "";
                                }
                                else {
                                    FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                    // Formula >= and <= for AsIs

                                    if (FormulaAsIs.includes(">=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    alert(i18n.t("AsIsvalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoAsIsvalueofmetric") + result[0].BOILIName + ".");
                                                    return false;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaAsIs.includes("<=")) {
                                        if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                            FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                            try {
                                                FinalAsIS = eval(FormulaAsIs);
                                                if (FinalAsIS == false) {
                                                    alert(i18n.t("AsIsvalueofmetric") + objGridDetails[i].BOILIName + i18n.t("ShouldbetoAsisvalofmatric") + result[0].BOILIName + ".");
                                                    return false;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                }

                                if (result[0].ToBe == null || result[0].ToBe === "") {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                    objGridDetails[i].ToBe = "";
                                }
                                else {
                                    FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                    // Formula >= and <= for ToBe: Start

                                    if (FormulaToBe.includes(">=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    alert(i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofmetric") + result[0].BOILIName + ".");
                                                    return false;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    else if (FormulaToBe.includes("<=")) {
                                        if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                            FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                            try {
                                                FinalToBe = eval(FormulaToBe);
                                                if (FinalToBe == false) {
                                                    alert(i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoToBevalueofetric") + result[0].BOILIName + ".");
                                                    return false;
                                                }
                                            }
                                            catch (err) {
                                                console.log(err);
                                            }
                                        }
                                    }
                                    // Formula >= and <= for ToBe: End
                                }
                            }
                        }
                    }

                    try {
                        FinalAsIS = eval(FormulaAsIs);
                        FinalToBe = eval(FormulaToBe);
                    }
                    catch (err) {
                        console.log(err);
                    }

                    //Max Min Validation after Formula calculation
                    var num = false;
                    !isNaN(parseFloat(FinalAsIS)) && isFinite(FinalAsIS) ? num = true : num = false;

                    if (num == true) {
                        if (objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "" && objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null) {
                            var max = parseFloat(objGridDetails[i].Maximum);
                            var min = parseFloat(objGridDetails[i].Minimum);

                            if (FinalAsIS < min) {
                                alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString());
                                return false
                            }
                            if (FinalAsIS > max) {
                                alert(i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString());
                                return false
                            }
                            if (FinalToBe < min) {
                                alert(i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString());
                                return false
                            }
                            if (FinalToBe > max) {
                                alert(i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString());
                                return false
                            }
                        }
                        objGridDetails[i].AsIs = FinalAsIS;
                        objGridDetails[i].ToBe = FinalToBe;
                    }
                }

                updatedGridDetails.push(objGridDetails[i]);
            }
            this.refs.Grd_LeadInd.gridOptions.api.setRowData(updatedGridDetails);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellEdit(params) {
        try {
            if (params.colDef.headerName == i18n.t("As-Is") || params.colDef.headerName == i18n.t("To-Be")) {


                let BOIDetails = this.refs.Grd_BOI.gridOptions.rowData;
                this.checkCells();

            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveBOIDetails(e) {
        try {
            var ValidDisplayName = true;
            $("#loader-div").show();
            this.refs.Grd_BOI.gridOptions.api.stopEditing();
            let evtSrc = e.target.text.trim();
            let grdData = this.refs.Grd_BOI.gridOptions.rowData;
            let selectedBOI = grdData.filter(row => row.IsSelected);

            numericStatus = true;
            let numStatus = true;
            selectedBOI.filter(item => item.hasOwnProperty('AsIs'))
                .map((item, i) => {
                    var valAsIs = item.AsIs;
                    var valToBe = item.ToBe;
                    var DisplayName = item.DisplayName;
                    let DisplayNameValidation = inputValidator(DisplayName);
                    if (DisplayNameValidation) {
                        ValidDisplayName = false;
                    }
                    if (evtSrc == i18n.t('save') && valAsIs != "" && valAsIs != null) {
                        if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs))
                        {
                            numStatus = null;
                        }
                        else{
                            numStatus = false;
                        }
                    }
                    if (evtSrc == i18n.t('save') && valToBe != "" && valToBe != null) {
                        if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                            numStatus = null;
                        }
                        else{
                            numStatus = false;
                        }
                    }
                    if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs)){
                        numericStatus = null;
                    }
                    else{
                        numericStatus = false;
                    }
                    if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                        numericStatus = null;
                    }
                    else{
                        numericStatus = false;
                    }
                });

            if (evtSrc == i18n.t('save') && numStatus == false) {
                alert(i18n.t("PleaseenternumericvaluesAsIsandToBe"));
                $("#loader-div").hide();
                return false;
            }

            if (evtSrc != i18n.t('save') && numericStatus == false) {
                alert(i18n.t("PleaseenternumericvaluesAsIsandToBe"));
                $("#loader-div").hide();
                return false;
            }

            if (evtSrc != i18n.t('save')) {
                let notSelectedBOI = grdData.filter(row => !row.IsSelected);
                notSelectedBOI.filter(item => item.hasOwnProperty('AsIs'))
                    .map((item, i) => {
                        var valAsIs = item.AsIs;
                        var valToBe = item.ToBe;
                        if (valAsIs != "" && valAsIs != null) {
                            if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs)){
                                numStatus = null;
                            }
                            else{
                                numStatus = false;
                            }
                        }
                        if (valToBe != "" && valToBe != null) {
                            if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                                numStatus = null;
                            }
                            else{
                                numStatus = false;
                            }
                        }
                    });

                if (numStatus == false) {
                    alert(i18n.t("PleaseenternumericvaluesAsIsandToBe"));
                    $("#loader-div").hide();
                    return false;
                }
            }


            if (selectedBOI.length >= 1) {
                var BOIDetails = {
                    "boiDetails": grdData,
                };
                this.setState({
                    isEditAcitve: false
                })
                editActive = false;
                var ValidStatusFormula = true;
                if (evtSrc != i18n.t('save')) {
                    ValidStatusFormula = this.checkCells();
                }
                if (ValidStatusFormula) {
                    if (ValidDisplayName) {
                        var data = JSON.stringify(BOIDetails);
                        request("/BOI/SaveBOIDraftDetails?LanguageID=" + this.props.languageId, 'POST', BOIDetails)
                            .then(res => {
                                alert(i18n.t("BOIKeyBusinessMetricsSavedSuccessfully"));
                                if (evtSrc != i18n.t('save')) {
                                    this.props.history.push("/LeadingIndicator")
                                }
                                $("#loader-div").hide();
                            })
                            .catch(error => {
                                alert(error.statusText);
                                $("#loader-div").hide();
                            });
                    }
                    else {
                        alert(i18n.t("PleaseEntervalidValuesintheFields"));
                        $("#loader-div").hide();
                    }
                }
                else {

                    alert(i18n.t("PleaseentervalidAsIsToBevalue"));
                    $("#loader-div").hide();
                }
            }
            else {
                alert(i18n.t("PleaseselectatleastoneBOIDetails"));
                $("#loader-div").hide();
            }


        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    deleteBOIDetails() {
        try {
            let grdData = this.refs.Grd_BOI.gridOptions.rowData
            let selectedRows = Array.from(document.getElementsByClassName("findColumns")).filter(x => { if (x.checked == true) { return x.id } }).map(filteredData => filteredData.id)
            let selectedrowindex = [];
            selectedRows.forEach(rows => selectedrowindex.push(grdData.findIndex(x => x.BOIId == rows)))
            selectedrowindex.forEach(rowindex => { 
                if (rowindex != undefined) { 
                    let deleteindex = rowindex > 0 ? 1 : 0; 
                    return (grdData.splice(rowindex, 1));
                } 
            })
            this.refs.Grd_BOI.gridOptions.api.setRowData(grdData);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOverallEdit(e) {
        try {

            editActive = true;
            this.setState({
                isEditAcitve: true,
                popUpDisplay: false
            });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(params) {
        try {
            if (params.colDef.field == "BOILIName") {
                this.setState({
                    popUpBenchmark: true,
                    selectedMetric: params.data.BOILIName
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2,
                        LanguageID: this.props.languageId
                    };
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2,
                        LanguageID: this.props.languageId
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                        .then(res => {

                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            alert(error.statusText);
                            $("#loader-div").hide();
                        });
                    this.props.history.push(url);
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    boiGridData =(eve) => {
        console.log("this.props.configDetails",this.props.configDetails,this.props)
        console.log("1")
        var SearchedString ="";
       
        
        let data = {
           
            "UserName": this.props.enterpriseId,
            "BOILIID": 0,
            "BOILIMappingId": 0,
            "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
            "IndustryID": this.props.configDetails.OMID,
            "SCID": this.props.configDetails.ServiceComponent,
            "SearchedString": SearchedString,
            "GroupID": this.props.groupId,
            "LanguageID": this.props.languageId
        }
       
            $("#loader-div").hide();
            let objThis = this;
           
            console.log("2")

            eve.api.showLoadingOverlay();
            console.log("3")

            // request("/BOI/GetBOIDetails", 'POST', data)
            fetch(BaseURL + "/BOI/GetBOIDetails", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken"),

                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    console.log("response",response)
                    return response.json();
                })
                .then(responseData => {
                    console.log("responseData",responseData);
                    if (responseData.length !== 0) {

                        eve.api.hideOverlay();

                        objThis.setState({ boiGridData: responseData });
                        objThis.checkCells();
                    }

                    else {
                        eve.api.showNoRowsOverlay();
                    }

                })
                .catch(error => {
                    console.log("error encountered");
                });
            eve.api.sizeColumnsToFit();
       


    }

    gridReadyFun = (eve) => {
       this.boiGridData(eve);
       

        
    }

    closeBenchmarkPopUp() {
        try {
            this.setState({
                popUpBenchmark: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    DownloadExcel() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test"))
            // {
            // window.tfo_dataevent("PMM-D-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            var objThis = this;
            window.removeEventListener("beforeunload", this.unloadEvent);

            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "DealName": this.props.configDetails.DealName.label,
                "SCName": this.props.configDetails.ServiceComponent.label,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonExcelData = JSON.stringify(excelData);

            // request("/Common/DownloadtoExcelDraft", 'POST', excelData)
            //     .then(response => {

            //         var filePath = response.Table1[0].Value;
            //         window.location.href = filePath;

            //     })
            //     .catch(error => {
            //         window.addEventListener("beforeunload", objThis.unloadEvent);
            //         console.log("Error Encountered");
            //     });
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelDraft", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonExcelData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            .catch (error => {
                console.log("Error Encountered");
                alert(i18n.t("Something went wrong with this error " +error));
            });
            
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    DownloadReferenceContentExcel() {
        try {        
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test"))
            // {
            // window.tfo_dataevent("PMM-D-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            window.removeEventListener("beforeunload", this.unloadEvent);

            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "DealName": this.props.configDetails.DealName.label,
                "SCName": this.props.configDetails.ServiceComponent.label,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonexcelData = JSON.stringify(excelData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelConfig", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonexcelData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `PMMMaster_Config.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // request("/Common/DownloadtoExcelConfig", 'POST', excelData)
            //     .then(response => {

            //         var filePath = response.Table1[0].Value;
            //         window.location.href = filePath;
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");

            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

   

    render() {
        console.log("BOI render",this.props)

        var columnDefs = [];
        let enterpriseId = this.props.enterpriseId
        columnDefs = [
            { headerName: i18n.t('select'), field: 'Select', suppressMovable: true, suppressResize: true, cellRenderer: CreateCheckbox, width: 100, tooltipField: 'errorMsg' },
            {
                headerName: i18n.t('BOIKeyBusinessMetrics'), field: 'BOILIName', suppressMovable: true, suppressResize: true, width: 600, tooltipField: 'BOILIDefinition', cellStyle: function (params) {
                    params.node.data.loggedInUserId = enterpriseId
                    var background = "";
                    if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                        background = "#ffc200b8";
                    }

                    return {
                        background: background
                    };
                }
            },
            { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
            { headerName: i18n.t('ExternalBenchmark'), field: 'Median', suppressMovable: true, suppressResize: true, hide: true, tooltipField: 'Median', autoHeight: true },
            {
                headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, suppressResize: true, editable: function (params) {
                    let IsEditable = true;
                    if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                        IsEditable = false;
                    }
                    return IsEditable
                }, cellStyle: function (params) {
                    if (params.node.data.Formula == "") {
                        let backgroundVal = params.data.AsIsError ? "#E57373" : "#4990a052"
                        return { background: backgroundVal }
                    }
                },
                tooltipField: 'AsIsMsg'
            },
            {
                headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, suppressResize: true, editable: function (params) {
                    let IsEditable = true;
                    if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                        IsEditable = false;
                    }
                    return IsEditable
                }, cellStyle: function (params) {

                    if (params.node.data.Formula == "") {
                        let backgroundVal = params.data.ToBeError ? "#E57373" : "#4990a052"
                        return { background: backgroundVal }
                    }
                },
                tooltipField: 'ToBeMsg'
            },
            { headerName: i18n.t('Remark'), field: 'DisplayName', suppressMovable: true, suppressResize: true, width: 527, editable: true, autoHeight: true }
        ];

        var boiGridData = this.state.boiGridData;

        var BOIDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_BOI" id="grd_BOI"

                    enableSorting={false}
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={boiGridData}
                    rowSelection="single"

                    onGridReady={this.gridReadyFun}
                    quickFilterText={this.state.quickFilterText}
                    rowmodeltype="pagination"
                    suppressScrollOnNewData={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    singleClickEdit={true}
                    onCellEditingStarted={this.onOverallEdit.bind(this)}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><span className="menu2" onClick={this.onNavigationClick} data-content="HOME" data-url="/Home"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home"><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a></span>
                        <ul className="pagecounter"  >
                            <li onClick={this.onNavigationClick} data-url="/CreateNewAssessment"><span title="" className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content={i18n.t("takeNewAssessment")} data-url="/CreateNewAssessment"></span></li>
                            <li><span title={i18n.t("BOIKeyBusinessMetric")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI">...</span></li>
                            <li><span title={i18n.t("lEADINGINDICATOR")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator">2</span></li>
                            <li><span title={i18n.t("pMMASSESSMENT")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment">3</span></li>
                            <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard">4</span></li>
                            <li><span title={i18n.t("pOTENTIALPROJECTS")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                            <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>


                        </ul>
                        {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} url={this.state.toURL} navigationPage={this.state.navigationPage} isEditAcitve={this.state.isEditAcitve} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}

                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <div className="box-plain">
                                <h4><div className="pull-left" title={i18n.t('boiGridText')}>
                                    {i18n.t('BOIKeyBusinessMetric')}
                                </div>
                                    <div className="pull-right">
                                    <span class='statustxt-orng-italic'  title={i18n.t('OMIDD') + ': ' + this.props.configDetails.OMID}> OMID : {this.props.configDetails.OMID} </span>
                                        {/* <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                                         &nbsp;&nbsp;|| */} 
                                         

                                    </div>
                                </h4>



                                <div className="row">
                                    <div className="col-md-4 infoblockfilter ">
                                        <label id="lbl_search" className="col-md-3" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input type="text" id="txt_searchText" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </div>
                                    <div className="col-md-7">
                                        <ul className="grid-legend ulStyle">
                                            <li className="liStyle">
                                                <span className="formula-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span className="formula-span-gap"> {i18n.t('Metricautocalculatedbytool')} </span>
                                                <span className="error-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span> {i18n.t('ValidationErrorRecheckAsIs')} </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-1 btn-group">
                                        <button id="btn_referencePMMTemplate" type="button" title={i18n.t("ReferencePMMTemplate")} className="btn btn-default downloadCommon pull-right" onClick={this.DownloadReferenceContentExcel}>
                                            <img src={downloadBrownIcon} />
                                        </button>
                                        <button id="btn_downloadSavedScreens" type="button" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default downloadCommon pull-right" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                    </div>
                                </div>
                                <div className="box-plain pmm-asmt-tbl BOI-div">
                                    {BOIDataGrid}

                                    <div className="btn-group-md">
                                        <a type="button" className="btn btn-danger" onClick={this.SaveBOIDetails.bind(this)}>{i18n.t('save')}</a>
                                        <a type="button" className="btn btn-success" onClick={this.SaveBOIDetails.bind(this)}>{i18n.t('MovetoLeadIndicatorscreen')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                {this.state.popUpBenchmark && <BenchmarkPopup show={this.state.popUpBenchmark} closeCallback={this.closeBenchmarkPopUp} selectedMetric={this.state.selectedMetric} dealId={this.props.configDetails.DealName.value} industry={this.props.configDetails.OGIndustry.IndustryName} />}
            </div >
        );
    }
};

function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName,
    }
}

BOIGrid = connect(mapStateToProps)(withRouter(BOIGrid))
export default BOIGrid;