/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import ConfirmationBox from "./Confirmation";
import UpdateOMIDAndFTEs from "./UpdateOMIDAndFTEs";
import UpdateOMIDAndFTEsConfirm from "./UpdateOMIDAndFTEsConfirm";
import $ from "jquery";
import backArrowIcon from "../../images/icon-back-arrow.png";
import downloadIcon from "../../images/icon-download.png";
import viewIcon from "../../images/icon-view.png";
import request from "../../services/Service";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "i18next";
import langKeys from "../../Utils/LangKeys";
import { withTranslation } from "react-i18next";
import { AgGridLocale } from "../../Utils/AgGridLocale";
import { BaseURL } from "../../Constant";
import { datalist, i, label, s, th } from "react-dom-factories";
import { Tooltip } from "react-bootstrap";
import { set } from "lodash";
import { Dropdown } from "bootstrap";

var recordCount = 0;

class CreateNewAssessment extends React.Component {
  constructor(props) {
    super(props);
    try {
      this.state = {
        selectedDetails: [],
        AssessmentGridData: [],
        lstDeal: [],
        lstIndustry: [],
        lstRegion: [],
        lstCapability: [],
        lstCountry: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        DealValue: [],
        IndustryValue: "",
        RegionValue: [],
        OGIndustry: "",
        OG: "",
        Industry: "",
        CapabilityValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        TowerValue: [],
        ServiceCompValue: [],
        DlvryLocValue: [],
        eblNxtBTn: false,
        Confirm: false,
        message: "",
        DraftID: 0,
        quickFilterText: null,
        IsLockedStatus: "",
        lstOMID: [],
        OMIDValue: [],
        OMIDNewValue: "",

        OMIDFtes: false,
        OMIDFtesConfirm: false,
        selectedRowDetails: [],
        lstMMSID: [],
        MMSID: [],
        clientName: "",
        contractName: "",
        IndustryName: "",
        ProcessName: [],
        SubIndustryName: "",
        Market: "",
        MarketUnit: "",
        Country: "",
        City: "",
        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: "",
      };
      this.time = null;
      this.handleOMIDChange = this.handleOMIDChange.bind(this);
      this.ValidateOMID = this.ValidateOMID.bind(this);
      this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
      this.DownloadExcel = this.DownloadExcel.bind(this);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  CreateEditDetails() {
    try {
      let eDiv = document.createElement("div");
      eDiv.innerHTML = `<span><img src=${viewIcon} /></span>`;
      eDiv.className = "col-md-12";
      eDiv.width = "100%";
      return eDiv;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  componentDidMount() {
    try {
      var objThis = this;
      $("#loader-div").show();
      //window.tfo_event("PMM-TPA-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
      let dealData = {
        UserName: this.props.enterpriseId,
        DealId: 0,
        PermissionLevel: 3,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetDealBasedOnUserAccess", "POST", dealData)
        .then((response) => {
          response.length >= 0
            ? this.setState({ lstDeal: response })
            : this.setState({ lstDeal: [] });
        })
        .catch((error) => {
          console.log("Error Encountered");
          $("#loader-div").hide();
        });

      sessionStorage.setItem("PMMProcessDraftID", "");
      var SearchedString = "";
    } catch (error) {
      console.log("Error Encountered");
    } finally {
      $("#loader-div").hide();
    }
  }

  // try {
  //   var objThis = this;

  //   $("#loader-div").show();
  //   request("Common/GetOMIdDetails1l=" + this.props.OMID, "GET")
  //   .then((response) => {
  //     this.setState({ lstDeal: response });

  //     $("#loader-div").hide();
  //   })
  //   .catch((error) => {
  //     console.log("Error Encountered");
  //     $("#loader-div").hide();
  //   });
  // }
  // catch (error) {
  //   console.log("Error Encountered");
  // }
  // }

  componentWillUnmount() {
    try {
      window.removeEventListener("beforeunload", this.unloadEvent);
      window.removeEventListener("resize", this.reSizeGrid, true);
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectDeal(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
        lstServiceComp: [],
        ServiceCompValue: [],
        lstCapability: [],
        CapabilityValue: [],
        lstTower: [],
        TowerValue: [],
        CountryValue: "",
        DlvryLocValue: [],
        lstOMID: [],
        OMIDValue: [],
        MMSID: [],
        lstMMSID: [],
      });
      if (val.length == 0) {
        this.setState({ DealValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.DealValue == "" || prevState.DealValue == null ? 1 : 0
            ),
          };
        });
        this.setState({ DealValue: val });
      }

      let ogData = {
        UserName: this.props.enterpriseId,
        DealId: val[0].value,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetOGBasedOnDeal", "POST", ogData)
        .then((response) => {
          this.setState({ OGIndustry: response[0] });
          if (response[0].OGName == null) {
            this.setState({ eblNxtBTn: false });
          } else {
            this.setState((prevState) => {
              return {
                eblNxtBTn: this.checkAllSelected(
                  prevState.DealValue == "" || prevState.DealValue == null
                    ? 1
                    : 0
                ),
              };
            });
          }
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
      let dealData = {
        UserName: this.props.enterpriseId,
        DealID: val[0].value,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetMMSIDBasedOnDeal", "POST", dealData)
        .then((response) => {
          response != null
            ? this.setState({ lstMMSID: response })
            : this.setState({ lstMMSID: [] });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log("Error Encountered");
        });

      this.setState({ lstRegion: [], lstCountry: [], RegionValue: [] });

      request(
        "/MasterScreens/GetRegionBasedOnDeal?DealID=" +
          val[0].value +
          "&Flag=2&GroupID=" +
          this.props.groupId +
          "&LanguageID=" +
          this.props.languageId,
        "GET"
      )
        .then((response) => {
          this.setState({ lstRegion: response });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });

      let dropdownData = {
        UserName: this.props.enterpriseId,
        DeliveryLocationId: 0,
        DealId: val[0].value,
        PermissionLevel: "3",
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/DLDropDownBasedOnUserAccess", "POST", dropdownData)
        .then((response) => {
          response.length > 0
            ? this.setState({ lstDlvryLoc: response })
            : this.setState({ lstDlvryLoc: [] });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log("Error Encountered");
        });

      let omidData = {
        UserName: this.props.enterpriseId,
        ID: 0,
        DealID: val[0].value,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetOMIDBasedOnDeal", "POST", omidData)
        .then((response) => {
          response != null
            ? this.setState({ lstOMID: response })
            : this.setState({ lstOMID: [] });
          $("#loader-div").hide();
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  SelectIndustry(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
      if (val == null) {
        this.setState({ IndustryValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.IndustryValue == "" || prevState.IndustryValue == null
                ? 1
                : 0
            ),
          };
        });
        this.setState({ IndustryValue: val });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectRegion(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
      if (val == null) {
        this.setState({ RegionValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.RegionValue == "" || prevState.RegionValue == null
                ? 1
                : 0
            ),
          };
        });
        this.setState({ RegionValue: val });
      }
      var dealId = this.state.DealValue;

      request(
        "/MasterScreens/GetCountryBasedOnRegion?DealId=" +
          dealId.value +
          "&RegionId=" +
          val[0].value +
          "&GroupID=" +
          this.props.groupId +
          "&LanguageID=" +
          this.props.languageId,
        "GET"
      )
        .then((response) => {
          this.setState({ lstCountry: response });
          response.length > 0
            ? this.setState({
                CountryValue: { label: i18n.t("all"), value: "0" },
              })
            : this.setState({ CountryValue: "" });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectCapability(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
      if (val == null) {
        this.setState({ CapabilityValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.CapabilityValue == "" ||
                prevState.CapabilityValue == null
                ? 1
                : 0
            ),
          };
        });
        this.setState({ CapabilityValue: val });
      }

      let towerData = {
        UserName: this.props.enterpriseId,
        TowerID: 0,
        DealId: this.state.DealValue[0].value,
        CapabilityId: val[0].value,
        DeliveryLocationId: 0,
        DLId: this.state.DlvryLocValue[0].value,
        PermissionLevel: "3",
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetTowerBasedOnUserAccess", "POST", towerData)
        .then((response) => {
          this.setState({
            TowerValue: [],
            lstTower: response,
            lstServiceComp: [],
            ServiceCompValue: [],
            eblNxtBTn: false,
          });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectCountry(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
      if (val == null) {
        this.setState({ CountryValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.CountryValue == "" || prevState.CountryValue == null
                ? 1
                : 0
            ),
          };
        });
        this.setState({ CountryValue: val });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectTower(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });

      if (val == null) {
        this.setState({ TowerValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.TowerValue == "" || prevState.TowerValue == null ? 1 : 0
            ),
          };
        });
        this.setState({ TowerValue: val });
      }
      let scData = {
        UserName: this.props.enterpriseId,
        TowerID: val[0].value,
        SCId: 0,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetSCBasedOnTower", "POST", scData)
        .then((response) => {
          let SCList = response != "" ? response : [];
          this.setState({
            ServiceCompValue: [],
            lstServiceComp: SCList,
            eblNxtBTn: false,
          });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectDlvryLoc(val) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
        lstServiceComp: [],
        ServiceCompValue: [],
        lstCapability: [],
        CapabilityValue: [],
        lstTower: [],
        TowerValue: [],
      });
      if (val == null) {
        this.setState({ DlvryLocValue: val, eblNxtBTn: false });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.DlvryLocValue == "" || prevState.DlvryLocValue == null
                ? 1
                : 0
            ),
          };
        });
        this.setState({ DlvryLocValue: val });
      }

      let capabilityData = {
        UserName: this.props.enterpriseId,
        ID: 0,
        DealId: this.state.DealValue[0].value,
        DLId: val[0].value,
        PermissionLevel: 3,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      request("/Common/GetCapabilityBasedOnUserAccess", "POST", capabilityData)
        .then((response) => {
          response.length > 0
            ? this.setState({ lstCapability: response })
            : this.setState({ lstCapability: [] });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SelectOMID(val) {
    try {
      if (val == null || val == "") {
        this.setState({
          OMIDValue: val,
          eblNxtBTn: false,
          Confirm: false,
          OMIDFtes: false,
          OMIDFtesConfirm: false,
        });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.OMIDValue == [] || prevState.OMIDValue == null ? 1 : 0
            ),
          };
        });
        this.setState({
          OMIDValue: val,
          Confirm: false,
          OMIDFtes: false,
          OMIDFtesConfirm: false,
        });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  SelectMMSID(val) {
    try {
      if (val == null) {
        this.setState({
          MMSID: val,
          eblNxtBTn: false,
          Confirm: false,
          OMIDFtes: false,
          OMIDFtesConfirm: false,
        });
      } else {
        this.setState((prevState) => {
          return {
            eblNxtBTn: this.checkAllSelected(
              prevState.MMSID == [] || prevState.MMSID == null ? 1 : 0
            ),
          };
        });
        this.setState({
          MMSID: val,
          Confirm: false,
          OMIDFtes: false,
          OMIDFtesConfirm: false,
        });
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  handleFTEsChange(val) {
    try {
      this.setState({
        FTEs: val.currentTarget.value,
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  handleOMIDChange(e) {
    if (e.target.value == "") {
      this.setState({
        OMIDNewValue: "",
        clientName: "",
        contractName: "",
        IndustryName: "",
        SubIndustryName: "",
        Market: "",
        MarketUnit: "",
        Country: "",
        City: "",
        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        FTEs: "",
        ProcessName: [],
      });
    } else {
      this.setState({
        OMIDNewValue: e.target.value,
      });
    }
  }
  ValidateOMID() {
    if (this.state.OMIDNewValue == "" || this.state.OMIDValue == undefined) {
      alert("Please enter an Active OMID to proceed with the PMM Assessment");
      return false;
    } else {
      fetch(
        BaseURL + "/Common/GetOMIdDetails1l?OMID=" + this.state.OMIDNewValue,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => {
          return response.json();
        })

        .then((data) => {
          console.log("data", data[0].
          om_ll4_id);  
          this.setState({
            clientName: data[0].client_name_1,
            contractName: data[0].contract_name,
            IndustryName: data[0].industry_description,
            SubIndustryName: data[0].industry_segment_description,
            Market: data[0].market_name,
            MarketUnit: data[0].omid_market_unit_name,
            Country: data[0].omid_country_name,
            City: data[0].omid_city_name,
            MainOffering: data[0].main_offering_name,
            Offering_SSG: data[0].offering_ssg_name,
            SubOffering: data[0].sub_offering_name,
            City_Id: data[0].city_id,
            Region_Id: data[0].market_id,
            Country_Id: data[0].country_id,
           
          });
        })

        .catch((error) => {
          alert(
            "Please enter an Active OMID to proceed with the PMM Assessment"
          );

          console.log("Error Encountered");
        });

      fetch(
        BaseURL +
          "/Common/GetOMIdProcessDetails1l?OMID=" +
          this.state.OMIDNewValue,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          function getProcessName(val) {
            let processName = [];
            val.forEach((element) => {
              processName.push({
                id: element.om_ll4_id,
                label: element.process_name,
              });
            });
            return processName;
          }
          this.setState({
            ProcessName: getProcessName(data),
          });
        })

        .catch((error) => {
          console.log("Error Encountered");
        });
    }
  }
  // SelectServiceComponent(val) {
  //   if (val == "") {
  //     {
  //       this.setState({
  //         val: "",
  //         defaultProcessName: "",
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       defaultProcessName: val,
  //       eblNxtBTn: true,
  //     });
  //   }
  //   // try {
  //   //   this.setState({
  //   //     Confirm: false,
  //   //     OMIDFtes: false,
  //   //     OMIDFtesConfirm: false,
  //   //   });
  //   //   if (val == null) {
  //   //     this.setState({ defaultProcessName: val, eblNxtBTn: false });
  //   //   } else {
  //   //     this.setState((prevState) => {
  //   //       return {
  //   //         eblNxtBTn: this.checkAllSelected(
  //   //           prevState.defaultProcessName == "" ||
  //   //             prevState.defaultProcessName == null
  //   //             ? 1
  //   //             : 0
  //   //         ),
  //   //       };
  //   //     });
  //   //     this.setState({ defaultProcessName: val });
  //   //   }
  //   // } catch (error) {
  //   //   console.log("Error Encountered");
  //   // }
  // }
  SelectServiceComponent(e) {
    console.log("e.target.value", e.target.name);
    
    if (e.target.value == "") {
      this.setState({
        defaultProcessName: "",
        eblNxtBTn: false,
        ProcessName: [],
      });
    }
    else {
console.log("value", this.state.ProcessName.find((item) => item.id == e.target.value))
    this.setState({
      defaultProcessName: e.target.value,
       defaultName : this.state.ProcessName.find((item) => item.id == e.target.value).label,
       Process_Id :this.state.ProcessName.find((item) => item.id == e.target.value).id,
      eblNxtBTn: true,
    });
  }

  }

  checkAllSelected(prevValue) {
    try {
      this.setState({
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
      let stateCount = 0;
      let mandateValues = prevValue;
      for (var ctrls in this.refs) {
        if (ctrls != "Grd_ViewAssess") {
          if (this.refs[ctrls].props != undefined) {
            if (
              this.refs[ctrls].props.selected[0] != undefined &&
              this.refs[ctrls].props.selected[0].value != "" &&
              this.refs[ctrls].props.selected[0].value != undefined
            ) {
              mandateValues += 1;
            }
          } else {
            if (
              (this.refs[ctrls].value != "" &&
                this.refs[ctrls].value != undefined) ||
              ctrls == "idIndustry" ||
              ctrls == "idFTEs" ||
              ctrls == "idOG"
            ) {
              mandateValues += 1;
            }
          }

          stateCount += 1;
        }
      }
      let result = mandateValues == stateCount ? true : false;
      return result;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  ValidateMandateFields(reqFrm, DraftID) {
    try {
      let AssesmentMasterDetails = {
        DealId: 0,
        RegionId: this.state.Region_Id,
        CountryId: this.state.Country_Id,
        OGName: this.state.defaultName,
        IndustryName: this.state.IndustryName,
        ServiceComponentId: this.state.Process_Id,
        DeliveryLocationId: this.state.City_Id,
        LoggedInUserId: this.props.enterpriseId,
        PMMProcessDraftID: DraftID,
        OMID: this.state.OMIDNewValue,
        FTECount: this.state.FTEs,
        FlagId: 1,
        MMSID: 0,
        GroupID: 1,
        LanguageID: this.props.languageId,
      };
      let selectedDetails = {
        DealName: this.state.clientName,

        CountryId: this.state.Country,
        OGIndustry: this.state.Market,
        Capability: this.state.Offering_SSG,
        Tower: this.state.SubOffering,
        ServiceComponent: this.state.defaultProcessName,
        DeliveryLocationId: this.state.City,
        PMMProcessDraftID: DraftID,
        AsIs4: 0,
        NoIndProj: 0,
        MMSID: 0,
        GroupID: 1,
        LanguageID: this.props.languageId,
        OMID: this.state.OMIDNewValue,
      };
console.log("selectedDetails1", selectedDetails)
      let reqSrc = reqFrm.target.innerHTML;
      var objThis = this;
      // request("/Common/SaveNewAssessment", "POST", AssesmentMasterDetails)
      fetch(BaseURL + "/Common/SaveNewAssessment", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          "Content-Type": "application/json",
        },

        body: JSON.stringify(AssesmentMasterDetails),
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.outputmessage == i18n.t("success")) {
            selectedDetails.PMMProcessDraftID = response.ScopeIDGenrated;

            this.setState({
              Confirm: false,
              message: response.outputmessage,
              DraftID: response.ScopeIDGenrated,
              reqSrc: reqSrc,
              data: AssesmentMasterDetails,
              selectedDetails: selectedDetails,
              IsLockedStatus: response.Outputcode,
            });

            this.props.propsPMMConfigDetails(selectedDetails);
            objThis.props.history.push("/BOI");

            $("#loader-div").hide();
          } else {
            selectedDetails.PMMProcessDraftID = response.ScopeIDGenrated;
            this.setState({ IsLockedStatus: response.Outputcode });
            console.log("message", response.outputmessage);
            this.setState({
              Confirm: true,
              message: response.outputmessage,
              DraftID: response.ScopeIDGenrated,
              reqSrc,
              data: AssesmentMasterDetails,
              selectedDetails: selectedDetails,
            });
          }
          this.props.propsPMMConfigDetails(selectedDetails);
          $("#loader-div").hide();
        })

        .catch((error) => {
          console.log("Error Encountered");
          $("#loader-div").hide();
        });
      $("#loader-div").show();
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  SaveNewAssessment = (event) => {
    try {
      if (this.refs.idFTEs.value == "" || this.refs.idFTEs.value == "0") {
        alert(i18n.t("pleaseenterFTEsvalue"));
        return false;
      } else if (parseFloat(this.refs.idFTEs.value) < 0) {
        alert(i18n.t("pleaseenterFTEsvaluegreater"));
        return false;
      } else if (this.refs.idFTEs.value != "") {
        let ftesNumStatus = true;
        if (
          !isNaN(parseFloat(this.refs.idFTEs.value)) &&
          isFinite(this.refs.idFTEs.value)
        ) {
          ftesNumStatus = null;
        } else {
          ftesNumStatus = false;
        }
        if (ftesNumStatus == false) {
          alert(i18n.t("pleaseenternumericvalue"));
          return false;
        }
      }

      if (
        this.state.OMIDNewValue != "" &&
        this.state.defaultProcessName != ""
      ) {
        this.ValidateMandateFields(event, 0);
      } else {
        alert(i18n.t("pleasefillallthemandatefields"));
      }
    } catch (error) {
      console.log("Error Encountered");
    }
  };

  reSizeGrid() {
    this.refs.Grd_ViewAssess.gridOptions.api.sizeColumnsToFit();
  }

  gridReadyFun = (eve) => {
    $("#loader-div").hide();

    let objThis = this;
    var SearchedString = "";
    let data = {
      PMMProcessDraftID: 0,
      DealID: 0,
      RegionID: 0,
      CountryID: 0,
      CapabilityID: 0,
      TowerID: 0,
      IndustryID: 0,
      SCID: 0,
      SearchedString: SearchedString,
      PermissionLevel: 3,
      GroupID: this.props.groupId,
      LanguageID: this.props.languageId,
    };

    eve.api.showLoadingOverlay();

    request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", "POST", data)
      .then((response) => {
        if (response.Table.length != 0) {
          eve.api.hideOverlay();
          response.Table == null
            ? (recordCount = 0)
            : (recordCount = response.Table.length);
          objThis.setState({ AssessmentGridData: response.Table });
        } else {
          eve.api.showNoRowsOverlay();
        }
      })
      .catch((error) => {
        console.log("Error Encountered");
        $("#loader-div").hide();
      });

    eve.api.sizeColumnsToFit();
  };

  async showUpdatedRecord() {
    try {
      var SearchedString = "";
      let data = {
        UserName: this.props.enterpriseId,
        PMMProcessDraftID: 0,
        DealID: 0,
        RegionID: 0,
        CountryID: 0,
        CapabilityID: 0,
        TowerID: 0,
        IndustryID: 0,
        SCID: 0,
        SearchedString: SearchedString,
        PermissionLevel: 3,
        GroupID: this.props.groupId,
        LanguageID: this.props.languageId,
      };
      await request(
        "/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess",
        "POST",
        data
      )
        .then((response) => {
          console.log("response", response.Table);
          response.Table == null
            ? (recordCount = 0)
            : (recordCount = response.Table.length);
          this.setState({
            
            AssessmentGridData: response.Table,
            OMIDFtes: false,
            OMIDFtesConfirm: false,
            // PMMProcessDraftID : res
          });
        })
        .catch((error) => {
          console.log("Error Encountered");
          $("#loader-div").hide();
        });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onCellClicked(e) {

    //debugger;
    try {
      if (e.colDef.headerName == i18n.t("startAssessment")) {
        console.log("e.data", e.data);
        this.setState({ OMIDFtes: false, OMIDFtesConfirm: false });
        var objThis = this;

        if (
          this.props.groupId == "1" &&
          (e.data.OMID == null || e.data.FTECount == null || e.data.OMID == 0)
        ) {
          this.setState({ Confirm: false, OMIDFtesConfirm: true });
          return false;
        }
        if (
          this.props.groupId != "1" &&
          (e.data.MMSID == null || e.data.FTECount == null || e.data.MMSID == 0)
        ) {
          this.setState({ Confirm: false, OMIDFtesConfirm: true });
          return false;
        }

        let AssesmentMasterDetails = {
          DealId: e.data.DealID,
          RegionId: e.data.RegionID,
          CountryId: e.data.CountryID,
          OGName: e.data.OGName,
          IndustryName: e.data.IndustryName,
          ServiceComponentId: e.data.ServiceComponentID,
          DeliveryLocationId: e.data.DeliveryLocationId,
          LoggedInUserId: this.props.enterpriseId,
          PMMProcessDraftID: 0,
          FlagId: 1,
          OMID: e.data.OMID ? e.data.OMID : 0,
          FTECount: e.data.FTECount,
          MMSID: e.data.MMSID ? e.data.MMSID : "0",
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };

        let selectedDetails = {
          DealName: { label: e.data.DealName, value: e.data.DealID },
          Region: { label: e.data.RegionName, value: e.data.RegionID },
          Country: { label: e.data.CountryName, value: e.data.CountryID },
          OGIndustry: {
            OGID: e.data.OGID,
            //"OGName": e.data.OGName,
            IndustryId: e.data.IndustryId,
            IndustryName: e.data.IndustryName,
          },
          Capability: {
            label: e.data.CapabilityName,
            value: e.data.CapabilityID,
          },
          Tower: { label: e.data.TowerName, value: e.data.TowerID },
          ServiceComponent: e.data.ServiceComponentID,
          DeliveryLocationId: e.data.DeliveryLocationId,
          PMMProcessDraftID: e.data.PMMProcessDraftID,
          OMID: e.data.OMID ? e.data.OMID : 0,
          FTECount: e.data.FTECount,
          MMSID: e.data.MMSID ? e.data.MMSID : "0",
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };
        console.log("selectedDetails2", selectedDetails);
        request("/Common/SaveNewAssessment", "POST", AssesmentMasterDetails)
          .then((res) => {
            $("#loader-div").show();
            if (res.outputmessage == i18n.t("success")) {
              selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
              this.setState({
                message: res.outputmessage,
                reqSrc: i18n.t("startAssessment"),
                DraftID: res.ScopeIDGenrated,
                data: AssesmentMasterDetails,
                selectedDetails: selectedDetails,
                IsLockedStatus: res.Outputcode,
              });
              request(
                "/Common/SaveNewAssessment",
                "POST",
                AssesmentMasterDetails
              )
                .then((res) => {
                  selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                  this.props.propsPMMConfigDetails(selectedDetails);
                  objThis.props.history.push("/BOI");
                  $("#loader-div").hide();
                })
                .catch((error) => {
                  $("#loader-div").hide();
                });
            } else {
              selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
              this.setState({
                Confirm: true,
                reqSrc: i18n.t("startAssessment"),
                message: res.outputmessage,
                DraftID: res.ScopeIDGenrated,
                data: AssesmentMasterDetails,
                selectedDetails: selectedDetails,
                IsLockedStatus: res.Outputcode,
              });
            }
            this.props.propsPMMConfigDetails(selectedDetails);
            $("#loader-div").hide();
          })
          .catch((error) => {
            alert(error.statusText);
            $("#loader-div").hide();
          });

        $("#loader-div").show();
      } else if (
        e.colDef.headerName == i18n.t("OMIDFTEs") ||
        e.colDef.headerName == i18n.t("MMSIDFTEs")
      ) {
        this.setState({
          OMIDFtes: false,
          Confirm: false,
          OMIDFtesConfirm: false,
        });

        let AssesmentMasterDetails = {
          DealId: e.data.DealID,
          RegionId: e.data.RegionID,
          CountryId: e.data.CountryID,
          //OGName: e.data.OGName,
          IndustryName: e.data.IndustryName,
          ServiceComponentId: e.data.ServiceComponentID,
          DeliveryLocationId: e.data.DeliveryLocationId,
          LoggedInUserId: this.props.enterpriseId,
          PMMProcessDraftID: 0,
          FlagId: 1,
          OMID: e.data.OMID ? e.data.OMID : 0,
          FTECount: e.data.FTECount,
          MMSID: e.data.MMSID ? e.data.MMSID : "0",
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };

        let selectedDetails = {
          DealName: { label: e.data.DealName, value: e.data.DealID },
          Region: { label: e.data.RegionName, value: e.data.RegionID },
          Country: { label: e.data.CountryName, value: e.data.CountryID },
          OGIndustry: {
            OGID: e.data.OGID,
            OGName: e.data.defaultProcessName,
            IndustryId: e.data.IndustryId,
            IndustryName: e.data.IndustryName,
          },
          Capability: {
            label: e.data.CapabilityName,
            value: e.data.CapabilityID,
          },
          Tower: { label: e.data.TowerName, value: e.data.TowerID },
          ServiceComponent: e.data.ServiceComponentID,
          DeliveryLocation: {
            label: e.data.DeliveryLocationName,
            value: e.data.DeliveryLocationId,
          },
          PMMProcessDraftID: e.data.PMMProcessDraftID,
          OMID: e.data.OMID ? e.data.OMID : 0,
          OMIDValue: e.data.OMIDValue ? e.data.OMIDValue : 0,
          FTECount: e.data.FTECount,
          MMSID: e.data.MMSID ? e.data.MMSID : "0",
          GroupID: this.props.groupId,
          LanguageID: this.props.languageId,
        };
console.log("selectedDetails3", selectedDetails)
        this.setState({ selectedRowDetails: selectedDetails });
        this.setState({ OMIDFtes: true, OMIDFtesConfirm: false });
      }
     
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  onQuickFilterText(event) {
    try {
      this.setState({
        quickFilterText: event.target.value,
        Confirm: false,
        OMIDFtes: false,
        OMIDFtesConfirm: false,
      });
    } catch (error) {
      console.log("Error Encountered");
    }
  }

  async DownloadExcel() {
    try {            
        // window.tfo_event("PMM-D-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
        var SearchedString = "";
        let excelData = {
            "UserName": this.props.enterpriseId,
            "PMMProcessDraftID": 0,
            "DealId": 0,
            "RegionID": 0,
            "CountryID": 0,
            "CapabilityId": 0,
            "TowerID": 0,
            "IndustryID": 0,
            "SCId": 0,
            "IsLocked": 0,
            "SearchedString": SearchedString,
            "PermissionLevel": 3,
            "GroupID": this.props.groupId,
            "LanguageID":this.props.languageId
        }
        let jsonExcelData = JSON.stringify(excelData);
        $("#loader-div").show();
        fetch(BaseURL + "/Common/DownloadtoExcelAssessmentByUserAccess", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'charset': 'utf-8',
                "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
            },
            body : jsonExcelData
        })
        .then(response => response.blob())
            .then((blob) => {
                console.log(blob);
                const BlobUrl = window.URL.createObjectURL(blob);
                var downLink = document.createElement("a");
                downLink.href = BlobUrl;
                downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                document.body.appendChild(downLink);
                downLink.click();
                downLink.remove();
                $("#loader-div").hide();
                alert(i18n.t("filedownloaded"));
                }
            )
        // await request("/Common/DownloadtoExcelAssessmentByUserAccess", 'POST', excelData)
        //     .then(response => {
        //         if (response.Table1.length == 0) {
        //             alert(i18n.t("norecordsfound"));
        //         }
        //         else {
        //             var filePath = response.Table1[0].Value;
        //             window.location.href = filePath;
        //         }
        //     })
        //     .catch(error => {
        //         console.log("Error Encountered");
        //     });
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
  filterCallbackTypeahead(option, props) {
    try {
      if (props.selected.length) {
        return true;
      }
      return (
        option[props.labelKey]
          .toLowerCase()
          .indexOf(props.text.toLowerCase()) !== -1
      );
    } catch (error) {
      console.log("Error Encountered");
    }
  }
  handlingTooltip = (e) => {
    try {
      $(e.target).tooltip("show");
    } catch (error) {
      console.log("Error Encountered");
    }
  };

  render() {
    console.log("this.state.defaultProcessName", this.state.defaultProcessName,this.state.ProcessName);
    var columnDefs = [];
    let isHidden = this.props.groupId == "1" ? true : false;
    columnDefs = [
      {
        headerName: i18n.t("startAssessment"),
        cellRenderer: this.CreateEditDetails.bind(this),
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("PMMProcessDraftID"),
        field: "PMMProcessDraftID",
        suppressMovable: true,
        suppressResize: true,
        hide: true,
      },
      {
        headerName: i18n.t("PMMTransactionID"),
        field: "PMMTransactionID",
        suppressMovable: true,
        suppressResize: true,
        hide: true,
      },
      {
        headerName: i18n.t("Client Name"),
        field: "DealName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("Market"),
        field: "RegionName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("City"),
        field: "DeliveryLocationName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("MainOffering"),
        field: "CapabilityName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("Offering/SSG"),
        field: "CapabilityName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("Sub Offering"),
        field: "TowerName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("Process"),
        field: "SCName",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("OM ID"),
        field: "OMIDValue",
        suppressMovable: true,
        suppressResize: true,
        hide: !isHidden,
      },
      {
        headerName: i18n.t("mMSID"),
        field: "MMSID",
        suppressMovable: true,
        suppressResize: true,
        hide: isHidden,
      },
      {
        headerName: i18n.t("FTEs"),
        field: "FTECount",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("LastSavedBy"),
        field: "updatedBy",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("LastSavedOn"),
        field: "updatedDate",
        suppressMovable: true,
        suppressResize: true,
      },
      {
        headerName: i18n.t("OMIDFTEs"),
        cellRenderer: function (params) {
          if (params.node.data.PMMProcessDraftID != 0) {
            return '<a style="cursor:pointer;color:#e69d3a">Update</a>';
          }
        },
        suppressMovable: true,
        suppressResize: true,
        hide: !isHidden,
      },
      {
        headerName: i18n.t("MMSIDFTEs"),
        cellRenderer: function (params) {
          if (params.node.data.PMMProcessDraftID != 0) {
            return '<a style="cursor:pointer;color:#e69d3a">Update</a>';
          }
        },
        suppressMovable: true,
        suppressResize: true,
        hide: isHidden,
      },
    ];

    var assessmentGridData = this.state.AssessmentGridData;
   console.log("assessmentGridData", assessmentGridData,this.state.AssessmentGridData,this.state.PMMProcessDraftID)

    var AssessmentDataGrid = (
      <div style={{ height: "380px" }} className="ag-theme-material padding5">
        <AgGridReact
          ref="Grd_ViewAssess"
          id="grd_ViewAssess"
          enableSorting="true"
          pagination="true"
          paginationPageSize="10"
          enableColResize={false}
          columnDefs={columnDefs}
          rowData={assessmentGridData}
          quickFilterText={this.state.quickFilterText}
          rowmodeltype="pagination"
          onCellClicked={this.onCellClicked.bind(this)}
          onGridReady={this.gridReadyFun}
          localeText={AgGridLocale()}
          overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t(
            "dataLoading"
          )}</span>`}
        />
      </div>
    );

    return (
      <section className="content">
        <div className="innerpage-hd">
          {" "}
          <Link className="menu2 btn btn-info btn-back" to="/Home">
            <img src={backArrowIcon} /> {i18n.t("bACKTOHOME")}
          </Link>
          <ul className="pagecounter">
            <li>
              {" "}
              <span
                className="current"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Landing Page"
                title={i18n.t("takeNewAssessment")}
              >
                ...
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="BOI"
                title={i18n.t("BOIKeyBusinessMetric")}
              >
                1
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Leading Indicator "
                title={i18n.t("lEADINGINDICATOR")}
              >
                2
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="PMM"
                title={i18n.t("pMMASSESSMENT")}
              >
                3
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Storyboard"
                title={i18n.t("sTORYBOARDGAPASSESSMENT")}
              >
                4
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Potential Projects"
                title={i18n.t("pOTENTIALPROJECTS")}
              >
                5
              </span>
            </li>
            <li>
              <span
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
                data-content="Project Prioritization"
                title={i18n.t("pROJECTPRIORITIZATION")}
              >
                6
              </span>
            </li>
          </ul>
          {this.state.Confirm ? (
            <ConfirmationBox
              show={this.state.Confirm}
              bodyMessage={this.state.message}
              DraftId={this.state.DraftID}
              reqSrc={this.state.reqSrc}
              data={this.state.data}
              propsPMMConfigDetails={this.props.propsPMMConfigDetails}
              selectedDetails={this.state.selectedDetails}
              propsIsLocked={this.state.IsLockedStatus}
            />
          ) : null}
          {this.state.OMIDFtes ? (
            <UpdateOMIDAndFTEs
              show={this.state.OMIDFtes}
              selectedDetails={this.state.selectedRowDetails}
              propsIsLocked={this.state.IsLockedStatus}
              propshowUpdatedRecord={this.showUpdatedRecord.bind(this)}
            />
          ) : null}
          {this.state.OMIDFtesConfirm ? (
            <UpdateOMIDAndFTEsConfirm
              show={this.state.OMIDFtesConfirm}
              selectedDetails={this.state.selectedRowDetails}
            />
          ) : null}
        </div>
        <section className="bodydata">
          <div className="bodydata-full">
            <div className="box-plain">
              <h4>{i18n.t("takeNEWSAVEDAssessment")} </h4>

              <div className="row">
                <div className="box">
                  <div className="box-in">
                    <div className="col-lg-4 form-horizontal">
                      {/* {this.props.groupId == "1" ? ( */}
                      <div className="form-group">
                        <label
                          className="control-label col-sm-3"
                          id="lbl_selectOMID"
                          title={i18n.t("pleaseselectOMIDforchosenSubOffering")}
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("OM ID")}:
                        </label>
                        <div
                          className="col-sm-4"
                          title={i18n.t("pleaseselectOMIDforchosenSubOffering")}
                        >
                          {/* <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlOMId" id="ddl_omid" name="form-field-name" labelKey="label" onChange={this.SelectOMID1()} options={this.state.lstOMID} selected={this.state.OMIDValue} filterBy={this.filterCallbackTypeahead.bind(this)} /> */}
                          <input
                            ref="ddlOMId"
                            id="ddl_omid"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.OMIDNewValue}
                            onChange={(e) => {
                              this.handleOMIDChange(e);
                            }}
                          />
                        </div>
                        <div>
                          <button
                            className="btn btn-primary"
                            id="btn_search"
                            onClick={this.ValidateOMID.bind(this)}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                      {/* ) : (
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            id="lbl_MMSID"
                          >
                            <span style={{ color: "red" }}>*</span>
                            {i18n.t("mMSID")}:
                          </label>
                          <div className="col-sm-7">
                            <input
                              ref="ddlOMId"
                              id="ddl_omid"
                              className="form-control"
                              type="text"
                              maxLength="100"
                              value={this.state.OMIDValue}
                              onChange={(e) => {
                                this.handleOMIDChange(e);
                              }}
                            />
                          </div>
                        </div>
                      )} */}
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_clientName"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Client Name")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlClient"
                            id="ddl_client"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.clientName}
                            disabled
                            title={i18n.t(this.state.clientName)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_clientName"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Contract")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlContract"
                            id="ddl_contract"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.contractName}
                            disabled
                            title={i18n.t(this.state.contractName)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_industry"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Industry")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="idIndustry"
                            id="txt_industryName"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.SubIndustryName}
                            disabled
                            title={i18n.t(this.state.SubIndustryName)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_industry"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Sub Industry")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="idSubIndustry"
                            id="txt_subIndustryName"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.IndustryName}
                            disabled
                            title={i18n.t(this.state.IndustryName)}
                          />
                        </div>
                      </div>

                      {/* <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_og" ><span style={{ color: "red" }}>*</span>{i18n.t('oG')}:</label>
                                                <div className="col-sm-8">
                                                    <input ref="idOG" id="txt_ogName" className="form-control" type="text" maxLength="100" disabled value={this.state.OGIndustry.OGName == null ? "" : this.state.OGIndustry.OGName} />
                                                </div>
                                            </div> */}
                    </div>
                    <div className="col-lg-4 form-horizontal">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_clientBusinessRegion"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Market")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlMarket"
                            id="ddl_market"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.Market}
                            disabled
                            title={i18n.t(this.state.Market)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_clientBusinessRegion"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Market Unit")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlMaketUnit"
                            id="ddl_marketUnit"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.MarketUnit}
                            disabled
                            title={i18n.t(this.state.MarketUnit)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_clientBusinessRegion"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Country")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlRegion"
                            id="ddlRegion"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.Country}
                            disabled
                            title={i18n.t(this.state.Country)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_accDeliveryLocation"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("City")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlDlvryLoc"
                            id="ddl_deliveryLoc"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.City}
                            disabled
                            title={i18n.t(this.state.City)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_fillFTEs"
                          title={i18n.t(
                            "PleasefillinFTEsforchosenSubOfferingProcess"
                          )}
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("FTEs")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="idFTEs"
                            id="txt_fte"
                            className="form-control"
                            value={this.state.FTEs}
                            onChange={(e) => {
                              this.handleFTEsChange(e);
                            }}
                            type="text"
                            onInput={(e) => {
                              return (e.target.value = e.target.value.replace(
                                /[^\w\s\@\.-]/gi,
                                ""
                              ));
                            }}
                            maxLength="100"
                            title={i18n.t(
                              "PleasefillinFTEsforchosenSubOfferingProcess"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 form-horizontal">
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_capability"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Main Offering")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlCapability"
                            id="ddl_capability"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.MainOffering}
                            disabled
                            title={i18n.t(this.state.MainOffering)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_tower"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Offering/SSG")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlOffering_SSG"
                            id="ddl_offeringSSG"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.Offering_SSG}
                            disabled
                            title={i18n.t(this.state.Offering_SSG)}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_serviceComponent"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Sub Offering")}:
                        </label>
                        <div className="col-sm-7">
                          <input
                            ref="ddlSubOffering"
                            id="ddl_subOffering"
                            className="form-control"
                            type="text"
                            maxLength="100"
                            value={this.state.SubOffering}
                            disabled
                            title={i18n.t(this.state.SubOffering)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          id="lbl_serviceComponent"
                        >
                          <span style={{ color: "red" }}>*</span>
                          {i18n.t("Process")}:
                        </label>
                        <div className="col-sm-7">
                          {/* <Typeahead
                            emptyLabel={i18n.t("noMatchesFound")}
                            ref="ddlServiceComp"
                            id="ddl_serviceComponent"
                            name="form-field-name"
                            labelKey="label"
                            onChange={this.SelectServiceComponent.bind(this)}
                            options={this.state.ProcessName}
                            selected={this.state.defaultProcessName}
                            filterBy={this.filterCallbackTypeahead.bind(this)}
                          /> */}
                          {/* <Dropdown
                           id="ddl_serviceComponent"
                            ref="ddlServiceComp"
                            options={this.state.ProcessName}
                            onChange={this.SelectServiceComponent.bind(this)}
                            value={this.state.defaultProcessName}
                            placeholder={i18n.t("select")}
                            title={i18n.t(this.state.defaultProcessName)}
                          /> */}
                          {/* <select
                            className="form-control"
                            id="ddl_serviceComponent"
                            ref="ddlServiceComp"
                            value={this.state.defaultProcessName}
                            onChange={(e) => {
                              this.SelectServiceComponent(e);
                            }}
                           
                          />
                           <option value="0">Select</option>
                            {this.state.ProcessName.map((item, i) => (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            ))} */}
                          <select
                            className="form-control"
                            id="ddl_serviceComponent"
                            ref="ddlServiceComp"
                            value={this.state.defaultProcessName}
                            onChange={(e) => {
                              console.log("ProcessName", this.state.ProcessName);
                             
                              this.SelectServiceComponent(e);
                            }}
                            title= {this.state.defaultName}

                          >

                         
                            <option value="0">Select</option>
                            {this.state.ProcessName.map(
                              (item, i) => (
                                console.log("item", item),
                               
                                (
                                  <option key={i} value={item.id}>
                                    {item.label}
                                  </option>
                                )
                              )
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="btn-group-md startAssessmentbtn pull-right">
                        {this.state.eblNxtBTn ? (
                          <a
                            type="button"
                            className="btn btn-success"
                            onClick={this.SaveNewAssessment}
                            disabled={false}
                          >
                            {i18n.t("startAssessment")}
                          </a>
                        ) : (
                          <a
                            type="button"
                            className="btn btn-success"
                            disabled={true}
                          >
                            {i18n.t("startAssessment")}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h4 className="btn-group-md">
                {i18n.t("takeSavedAssessment")}
                <span className="badge">{recordCount}</span>
                <input
                  type="text"
                  placeholder={i18n.t("search")}
                  id="txt_searchAssessment"
                  className="fright searchBox"
                  data-field="searchOG"
                  onChange={this.onQuickFilterText.bind(this)}
                />
                <div className="btn-group btn-group-pmmasmt">
                  <button
                    type="button"
                    id="btn_downloadAssesment"
                    title={i18n.t("Downloadbelowlistofsavedassessments")}
                    className="btn btn-default btn-downld"
                    onClick={this.DownloadExcel}
                  >
                    <img src={downloadIcon} />
                  </button>
                </div>
              </h4>

              <div className="AsmtData viewprevassmt">{AssessmentDataGrid}</div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    languageId: state.languageId,
    enterpriseId: state.enterpriseId,
    groupId: state.groupId,
    roleName: state.roleName,
    pageName: state.pageName,
    groupName: state.groupName,
  };
}

CreateNewAssessment = connect(mapStateToProps)(withRouter(CreateNewAssessment));
export default CreateNewAssessment;
