/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import ReactSelect from 'react-select';
import $ from 'jquery';

export default class PMMUploadGrid extends React.Component {

    componentDidMount() {
    }

    render() {
        var columnDefs = [];
        var pmmGridData = [];
        pmmGridData = this.props.propsPMMGridData;

        if (pmmGridData != undefined && pmmGridData.length > 0) {
            var columnsIn = pmmGridData[0];
            for (var headerText in columnsIn) {
                if (headerText == "RowID") {
                    columnDefs.push({ headerName: headerText, field: headerText, hide: "true" });
                }
                else {
                    columnDefs.push({ headerName: headerText, field: headerText });
                }
            }
        }

        var PMMDataGrid = (
            <div style={{ height: '200px' }} className="ag-fresh padding5">
                <AgGridReact enableSorting="true" id="grd_PMMDataGrid"
                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={pmmGridData}
                    rowmodeltype="pagination"
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )

        return (
            <div>
                <fieldset className="grid-fieldset-scroll">
                    <div className="col-md-12">
                        {PMMDataGrid}
                    </div>
                </fieldset>
            </div>
        );
    }
}
