/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ViewDraftAssessmentGrid from './ViewDraftAssessmentGrid';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import request from '../../services/Service';
import loderIcon from '../../images/loader-strokes.gif';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { BaseURL } from '../../Constant';

var recordCount = 0;

class ViewPreviousAssessmentDraft extends React.Component {
    constructor(props) {

        super(props);
        try {
            recordCount = 0;
            this.state = {
                prevAssessmentGridData: [],
                disableCompare: true,
                showComparePopup: false,
                headerValue: "",
                lstDeal: [],
                lstIndustry: [],
                lstRegion: [],
                lstCapability: [],
                lstCountry: [],
                lstTower: [],
                lstServiceComp: [],
                lstDlvryLoc: [],
                DealValue: [],
                ProcessValue:[],
        lstprocess:[],
                IndustryValue: "",
                RegionValue: [],
                MainOfferingValue: [],
                lstMainoffering:[],
                OGIndustry: "",
                OG: "",
                Industry: "",
                CapabilityValue: [],
                CountryValue: { "label": i18n.t('all'), "value": "0" },
                TowerValue: [],
                ServiceCompValue: [],
                DlvryLocValue: { "label": i18n.t('all'), "value": "0" },
                eblNxtBTn: false,
                Confirm: false,
                message: "",
                DraftID: 0,
                isReport: false,
                showLoaderFlag: false,
                OMIDNewValue: "",
                MainOfferingid: 0,
                Capability_Id: 0,
                subOfferingId: 0,
                ClientId:0,
        clientName: "",
        Market: "",
        MainOffering: "",
        Offering_SSG: "",
        SubOffering: "",
        defaultProcessName: [],
        Process_Id: 0,
        Capability_Id: 0,
        Tower_Id: 0,
        Industry_Id: 0,
        Marketid: 0,
        Country_Id: 0,
        ProcessName: [],
        City_Id: 0,
            };
            this.HandleDropdownValues = this.HandleDropdownValues.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
            this.handleOMIDChange = this.handleOMIDChange.bind(this);
            this.ValidateOMID = this.ValidateOMID.bind(this);
            this.onDealChange = this.onDealChange.bind(this);
             this.SelectMarket =this.SelectMarket.bind(this);
            this.SelectProcess = this.SelectProcess.bind(this);
          this.SelectTower = this.SelectTower.bind(this);
           this.SelectMainOffering = this.SelectMainOffering.bind(this);
        }
        catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
      this.HandleDropdownValues();

        try {            
            //window.tfo_event("PMM-VSA-8", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            $("#loader-div").show();
            let marketData ={
              UserName: this.props.enterpriseId,
              ClientNumber:this.state.ClientId,
              GroupID: this.props.groupId,
             LanguageID: this.props.languageId};
             request("/Common/GetMarket", "POST", marketData)
             .then((response) => {
              console.log("exce",response)
               response.length > 0
                 ? this.setState({ lstMarket: response })
                 : this.setState({ lstMarket: [] });
               $("#loader-div").hide();
             })
             .catch((error) => {
               console.log(error);
               $("#loader-div").hide();
             });

            var objThis = this;
            let dealData = {
                "UserName": this.props.enterpriseId,
                "DealId": 0,
                "PermissionLevel": "3,2,1",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            let userData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetReportAccessByUser", 'POST', userData)
                .then(response => {

                    response.Table.length > 0 ? this.setState({ isReport: response.Table[0].IsReport }) : this.setState({ isReport: "" });
                    $("#loader-div").hide();

                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    GetAssessmentDetails() {
        try {
            if  ((this.state.OMIDNewValue === "" || this.state.OMIDNewValue == null) && 
            (this.state.DealValue === "" || this.state.DealValue == null || this.state.DealValue.length === 0))
             {
            alert(i18n.t("pleaseselectOMID//client"));
            return false;
        }

            var objThis = this;
            this.setState({ showComparePopup: false, showLoaderFlag: true });
            var DealID = this.state.ClientId;
      var RegionID = this.state.Marketid;

      var CountryID = this.state.OMID;

      var CapabilityID = this.state.MainOfferingid;
      var TowerID = this.state.subOfferingId;

      var IndustryID = this.state.Industry_Id;
     var SCID = this.state.Process_Id;
      var SearchedString = "";
            let viewAssessmentDraftData ={
                UserName:this.props.enterpriseId,
                PMMProcessDraftID:0,
               // PMMProcessID: 0,
                DealID:DealID ,
                RegionID:RegionID, 
                CountryID:CountryID,
                CapabilityID:CapabilityID,
                TowerID:TowerID,
                IndustryID:IndustryID,
                SCID:SCID,
                SearchedString:SearchedString,
                PermissionLevel:"3,2",
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId,
                SubOfferingIds:this.state.SubOfferingIds,
            }
            request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", 'POST', viewAssessmentDraftData)
                .then(response => {

                    response.Table == null ? recordCount = 0 : recordCount = response.Table.length;
                    objThis.setState({ prevAssessmentGridData: response.Table, showLoaderFlag: false });

                })
                .catch(error => {
                    console.log(error);
                    objThis.setState({ showLoaderFlag: false })

                });
        }
        catch (error) {
            console.log(error);
        }
    }

    
    handleOMIDChange(e) {
        if (e.target.value == "") {
          this.setState({
            OMIDNewValue: "",
            clientName: "",
            Market: "",
    
            MainOffering: "",
            Offering_SSG: "",
            SubOffering: "",
            defaultProcessName: [],
          });
        } else {
          this.setState({
            OMIDNewValue: e.target.value,
          });
        }
      }
    
      ValidateOMID() {
        fetch(
          BaseURL + "/Common/GetOMIdDetails1l?OMID=" + this.state.OMIDNewValue,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              clientName: data[0].client_name_1,
              Market: data[0].market_name,
              MainOffering: data[0].main_offering_name,
              Offering_SSG: data[0].offering_ssg_name,
              SubOffering: data[0].sub_offering_name,
              City_Id: data[0].city_id,
              ClientId:data[0].client_number,
              Marketid: data[0].market_id,
              OMID: data[0].om_master_id,
              Process_Id: data[0].taxonomy_id,
              MainOfferingid: data[0].main_offering_id,
              subOfferingId: data[0].sub_offering_id,
              Industry_Id: data[0].offering_ssg_id,
              DealValue: [{label: data[0].client_name_1,value: data[0].client_name_1}],
              MarketValue:[{label: data[0].market_name,value: data[0].market_name}],
               MainOfferingValue:[{label: data[0].main_offering_name,value: data[0].main_offering_name}],
               CapabilityValue:[{label: data[0].offering_ssg_name,value: data[0].offering_ssg_name}],
               TowerValue:[{label: data[0].sub_offering_name,value: data[0].sub_offering_name}],
               ProcessValue:[{label: data[0].process_name,value: data[0].process_name}]
            });
          })
          .catch((error) => {
            alert("Please enter an Active OMID to proceed with the PMM Assessment");
            console.log("Error Encountered");
          });
    
        fetch(
          BaseURL +
            "/Common/GetOMIdProcessDetails1l?OMID=" +
            this.state.OMIDNewValue,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            function getProcessName(val) {
              let processName = [];
              val.forEach((element) => {
                processName.push({
                  id: element.taxonomy_id,
                  label: element.process_name,
                });
              });
              return processName;
            }
            this.setState({
              ProcessName: getProcessName(data),
            });
          })
    
          .catch((error) => {
            console.log("Error Encountered");
          });
      }
      SelectServiceComponent(e) {
        console.log("e.target.value", e.target.name);
        
        if (e.target.value == "") {
          this.setState({
            defaultProcessName: "",
            eblNxtBTn: false,
            ProcessName: [],
          });
        }
        else {
    console.log("value", this.state.ProcessName.find((item) => item.id == e.target.value))
        this.setState({
          defaultProcessName: e.target.value,
           defaultName : this.state.ProcessName.find((item) => item.id == e.target.value).label,
          eblNxtBTn: true,
        });
      }
    
      }

   
    // HandleDropdownValues(val) {
    //   fetch(
    //     BaseURL + "/Common/GetOMIdMainOffering?ClientNumber=" + encodeURIComponent(this.state.ClientId) + "&UserName=" + encodeURIComponent(this.props.enterpriseId),      
  
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data);
    //       function getMainOfferingValue(data) {
    //         return data.map((item) => {
    //           return {
    //             label: item.main_offering_name,
    //             value: item.main_offering_id,
    //           };
    //         });
    //       }
    //        this.setState({ lstMainoffering: getMainOfferingValue(data)
    //       });
    //     })
    //     .catch((error) => {
    //       console.log("Error Encountered");
    //     });
       
         
  
    //   //const MainOfferingValues = this.state.MainOfferingValue.map((item) => item.value);
    //   //const FinalValue = MainOfferingValues.find (item => item.value === );
  
    
     
    // }
    HandleDropdownValues(val) {
      const requestData = {
          ClientNumber: this.state.ClientId,
          UserName: this.props.enterpriseId,
      };
  
      fetch(`${BaseURL}/Common/GetMainOfferingPost`,
         {
          method: "POST", 
          headers: {
              Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
              "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
      })
          .then((response) => {
              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
          })
          .then((data) => {
              console.log(data,"data1");
              const getMainOfferingValue = (data) => {
                  return data.map((item) => ({
                      label: item.label,
                      value: item.value,
                  }));
              };
              this.setState({ lstMainoffering: getMainOfferingValue(data) });
          })
          .catch((error) => {
              console.error("Error Encountered:", error);
          });
  }
    SelectDeal(val) {
      console.log(val);
      try {
          $("#loader-div").show(); // Show loader
    
          // Reset state
          this.setState({
              showComparePopup: false,
              lstServiceComp: [],
              ServiceCompValue: [],
              lstCapability: [],
              CapabilityValue: [],
              lstTower: [],
              TowerValue: [],
              prevAssessmentGridData: [],
              MarketValue: [], // Clear selected market value
              Marketid: null,
              MainOffering: "",
           MainOfferingValue: [],
           Process:[],
            defaultProcessName: [],
            Process_Id: 0,
            ProcessValue:[],
          });
    
          if (val.length !== 0) {
              const dealValue = val[0].value;
              this.setState({
                  DealValue: val,
                  ClientId: dealValue,  // Set ClientId to dealValue
                  Marketid: dealValue,   // Set Marketid to dealValue
              });
    
              const marketData = {
                  UserName: this.props.enterpriseId,
                  ClientNumber: dealValue,  // Use the dealValue to filter market data
                  GroupID: this.props.groupId,
                  LanguageID: this.props.languageId,
              };
    
              // Fetch market data
              request("/Common/GetMarket", 'POST', marketData)
                  .then(response => {
                      console.log("Market Response:", response);
                      this.setState({
                          lstMarket: response.length > 0 ? response : [], // Update state with relevant data
                      });
                  })
                  .catch(error => {
                      console.error("Error fetching market data:", error);
                  })
                  .finally(() => {
                      $("#loader-div").hide(); // Hide loader
                  });
          } else {
              this.ClearFilter();
              $("#loader-div").hide(); // Hide loader
          }
      } catch (error) {
          console.error("Error Encountered:", error);
          $("#loader-div").hide(); // Ensure loader is hidden on error
      }
    }
    SelectMarket(val) {
      
      try {
          $("#loader-div").show(); // Show loader
          console.log("vala",val);
          // Reset state
          this.setState({
              
              prevAssessmentGridData: [],
          });
    
          if (val.length !== 0) 
            {
              const MarketValue = val[0].value;
              this.setState({
                  MarketValue: val,
                    // Set ClientId to dealValue
                  Marketid: MarketValue,   // Set Marketid to dealValue
              });
    
              const requestData = {
                  UserName: this.props.enterpriseId,
                  ClientNumber: this.state.ClientId, // Use the dealValue to filter market data
                  
              };
    
              // Fetch market data
              fetch(`${BaseURL}/Common/GetMainOfferingPost`,
                {
                 method: "POST", 
                 headers: {
                     Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                     "Content-Type": "application/json",
                 },
                 body: JSON.stringify(requestData),
             })
                 .then((response) => {
                     if (!response.ok) {
                         throw new Error(`HTTP error! Status: ${response.status}`);
                     }
                     return response.json();
                 })
                 .then((data) => {
                     console.log(data,"data1");
                     const getMainOfferingValue = (data) => {
                         return data.map((item) => ({
                             label: item.label,
                             value: item.value,
                         }));
                     };
                     this.setState({ lstMainoffering: getMainOfferingValue(data) });
                 })
                  .catch(error => {
                      console.error("Error fetching market data:", error);
                  })
                  .finally(() => {
                      $("#loader-div").hide(); // Hide loader
                  });
          } else {
              this.ClearFilter();
              $("#loader-div").hide(); // Hide loader
          }
      } catch (error) {
          console.error("Error Encountered:", error);
          $("#loader-div").hide(); // Ensure loader is hidden on error
      }
    }
    async  SelectMainOffering(val) {
      this.setState({
        lstServiceComp: [],
        ServiceCompValue: [],
        lstCapability: [],
        CapabilityValue: [],
        lstTower: [],
        TowerValue: [],
        ProcessValue:[],
       })
      if (val.length  === 0) {
      
        this.setState({
          MainOfferingid: 0,
          mainOfferingName: "",
  
        })
      }
  
     
      else{
    await  this.setState({
      MainOfferingid: val[0].value,
        MainOfferingValue:val,
        mainOfferingName: val[0].label,
    
      });
    
  
      console.log("mainOfferingId2",this.state.mainOfferingId);
     // this.setState({MainOfferingid});
      fetch(
        BaseURL +
          "/Common/GetOMIdOfferingSSG?MainOfferingID=" +
          this.state.MainOfferingid,
    
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("dataa",data)
          function getCapabilityValue(data) {
            return data.map((item) => {
              return {
                label: item.offering_ssg_name,
                value: item.offering_ssg_id,
              };
            });
          }
          this.setState({ lstCapability: getCapabilityValue(data) });
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
      }
     
    }
    async SelectCapability(val) {
      this.setState({
        lstServiceComp: [],
        ServiceCompValue: [],
        
        lstTower: [],
        TowerValue: [],
        ProcessValue:[],
       })
      if (val.length === 0) {
        this.setState({
          Industry_Id: 0,
          capabilityName: "",
        });
      }
      else{
        
      await this.setState({
        Industry_Id: val[0].value,
        capabilityName: val[0].label,
        CapabilityValue:val,
      }
    );
      fetch(
        BaseURL +
          "/Common/GetOMIdSubOffering?OfferingSSGID=" +
          this.state.Industry_Id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("fet",data);
          function getTowerValue(data) {
            return data.map((item) => {
              return {
                label: item.sub_offering_name,
                value: item.sub_offering_id,
              };
            });
          }
          this.setState({
             lstTower: getTowerValue(data), 
            },
            () =>{console.log("tower",this.state.TowerValue)}
          );
        })
        .catch((error) => {
          console.log("Error Encountered");
        });
       
  
      }
    }
    async  SelectTower(val) {
      this.setState({
        lstServiceComp: [],
        ServiceCompValue: [],
        ProcessValue:[],
       })
      if (val.length === 0) {
        this.setState({
          subOfferingId: 0,
         });
      }
      else{
      await this.setState({
        subOfferingId: val[0].value,
       TowerValue:val,
      },
    ()=>{
      let processData ={
        SubOfferingIds:this.state.subOfferingId,
        GroupID: this.props.groupId,
       LanguageID: this.props.languageId};
  
      
       request("/Common/GetProcessBasedOnSubOffering", "POST", processData)
        .then((response) => {
         response.length > 0
           ? this.setState({ lstprocess: response })
           : this.setState({ lstprocess: [] });
         $("#loader-div").hide();
       })
       .catch((error) => {
        console.log("Error Encountered");
        
         $("#loader-div").hide();
       });
    }
    )
    }
      }
  SelectProcess(val) {
    try {
  
        if (val.length != 0) {
          this.setState({
            ProcessValue: val,
            Process_Id:val[0].value,
            prevAssessmentGridData: [],
          });
          
        }
        else {
          this.ClearFilter();
          $("#loader-div").hide();
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
  }

  ClearFilter() {
    try {
      this.setState({
        disableCompare: true,
        lstIndustry: [],
        lstRegion: [],
        lstCapability: [],
        lstTower: [],
        lstServiceComp: [],
        lstDlvryLoc: [],
        DealValue: [],
        MarketValue:[],
        RegionValue: [],
        CountryValue: { label: i18n.t("all"), value: "0" },
        CapabilityValue: [],
        TowerValue: [],
        ServiceCompValue: [],
        prevAssessmentGridData: [],
        SelectMainOffering:"",
        showComparePopup: false,
        OMIDNewValue: "",
        clientName: "",
        Market: "",
        MainOffering: "",
       MainOfferingValue: [],
        Offering_SSG: "",
        SubOffering: "",
        Process:[],
        defaultProcessName: [],
        Process_Id: 0,
        MainOfferingid: 0,
        subOfferingId: 0,
        Industry_Id: 0,
        ClientId:0,
        Marketid: 0,
        OMID: 0,
        ProcessName: [],
        City_Id: 0,
        ProcessValue:[],
          },()=>{this.HandleDropdownValues()});
      recordCount = 0;
    } catch (error) {
      console.log("Error Encountered");
    }
  }

    ShowComparisonPopup() {

      try {
        if (
          this.state.Marketid == 0 &&
          this.state.Marketid.length == 0 
         
        ) {
          if (
            this.state.MainOfferingid == 0  ||
            this.state.MainOfferingid.length == [0]
          ) {
            alert(i18n.t("pleaseSelectCapability"));
            return false;
          }
          if (
            this.state. subOfferingId == 0 ||
            this.state. subOfferingId.length == 0
          ) {
            alert(i18n.t("pleaseSelectTower"));
            return false;
          }
          if (
            this.state.Process_Id == 0 ||
            this.state.Process_Id.length == 0
          ) {
            alert(i18n.t("pleaseSelectServiceComponent"));
            return false;
          }
        }
        var header = {
          Deal: this.state.ClientId,
          Region: this.state.Marketid,
          DeliveryLocation: this.state.City_Id,
          Country: this.state.OMID,
          Capability: this.state.MainOfferingid,
          Tower: this.state.subOfferingId,
          ServiceComponent: this.state.Process_Id,
        };
        this.setState({ headerValue: header });
        this.setState({ showComparePopup: true });
        this.props.propsSelectedAssessmentDetails(header);
        this.props.history.push("/CompareRegionGrid");
      } catch (error) {
        console.log("Error Encountered");
      }
    }

    async DownloadExcel() {

        try {            
            //window.tfo_event("PMM-D-19", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var DealID = this.state.ClientId;
            var RegionID = this.state.Marketid;
            var CountryID = this.state.OMID;
            var CapabilityID = this.state.MainOfferingid;
            var TowerID = this.state.subOfferingId;
            var IndustryID = this.state.Industry_Id;
            var SCID = this.state.Process_Id;
            var SearchedString = "";
            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessDraftID": 0,
                "DealId": DealID,
                "RegionID": RegionID,
                "CountryID": CountryID,
                "CapabilityId": CapabilityID,
                "TowerID": TowerID,
                "IndustryID": IndustryID,
                "SCId": SCID,
                "IsLocked": 0,
                "SearchedString": SearchedString,
                "PermissionLevel": "2,3",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonExcelData = JSON.stringify(excelData);

            // await request("/Common/DownloadtoExcelAssessmentByUserAccess", 'POST', excelData)
            //     .then(response => {
            //         if (response.Table1.length == 0) {
            //             alert(i18n.t('norecordsfound'));
            //         }
            //         else {
            //             var filePath = response.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
            $("#loader-div").show();
                fetch(BaseURL + "/Common/DownloadtoExcelAssessmentByUserAccess", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        //'charset': 'utf-8',
                        "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                    },
                    body : jsonExcelData
                })
                .then(response => response.blob())
                    .then((blob) => {
                        const BlobUrl = window.URL.createObjectURL(blob);
                        var downLink = document.createElement("a");
                        downLink.href = BlobUrl;
                        downLink.download = `ProcessMaturityModel_Draft.xlsx`;
                        document.body.appendChild(downLink);
                        downLink.click();
                        downLink.remove();
                        $("#loader-div").hide();
                        alert(i18n.t("filedownloaded"));
                        }
                    )
            
        }
        catch (error) {
            console.log(error);
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {


        return (
            <section className="content">
                <div className="innerpage-hd">
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page">...</span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetricVIEW")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">1</span></li>
                        <li><span title={i18n.t("LeadingIndicatorView")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>

                    <Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back">
                        <img src={backArrowIcon} />{i18n.t('bACKTOHOME')}</a></Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <section className="multiple-tabs-section no-padding-lr">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentHome">{i18n.t('submittedAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="active" to="/ViewPreviousAssessmentDraft">{i18n.t('SavedDraftAssessments')}</Link>
                                    </li>
                                    {this.state.isReport != false ? <li className="nav-item">
                                        <Link className="" to="/PMMReports">{i18n.t('reports')}</Link>
                                    </li> : null}
                                </ul>
                            </section>
                            <h4>{i18n.t('FILTERS')}</h4>
                            <div className="box">
                <div className="box-in viewPrj-filter">
                  <span>
                    <div className="form-group col-sm-2">
                      <label id="lbl_OMID">
                        <span style={{ color: "red" }}>*</span>
                        {i18n.t("OMID")}
                      </label>

                      {/* <ReactSelect ref="ddlDeal" id="ddl_Deal_ViewPreviousAssessmentSubmitted" name="form-field-name" options={this.state.lstDeal} onChange={this.SelectDeal.bind(this)} value={this.state.DealValue} clearable={false} /> */}
                      <input
                        ref="ddlOMId"
                        id="ddl_omid"
                        className="form-control"
                        type="text"
                        maxLength="100"
                        value={this.state.OMIDNewValue}
                        onChange={(e) => {
                          this.handleOMIDChange(e);
                        }}
                      />
                      <br />
                      <button
                        className="btn btn-primary"
                        id="btn_search"
                        onClick={this.ValidateOMID.bind(this)}
                      >
                        {i18n.t("OM Search")}
                      </button>
                      <a
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={this.ClearFilter.bind(this)}
                >
                  {i18n.t("clear")}
                </a>
                    </div>
                  </span>

                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_clientBusinessRegion">
                      <span style={{ color: "red" }}>*</span>
                        {i18n.t("Client Name")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')} 
                       id="ddl_Deal_ViewPreviousAssessmentSubmitted"
                        ref="ddlDeal" name="form-field-name" 
                        labelKey="label" 
                        onChange={this.SelectDeal.bind(this)} 
                        options={this.state.lstDeal} 
                        selected={this.state.DealValue}
                         filterBy={this.filterCallbackTypeahead.bind(this)} 
                         placeholder={i18n.t("selectPlace")} /> 
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_MarketRegion">
                        {i18n.t("Market")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMarket.bind(this)}
                    options={this.state.lstMarket} 
                    selected={this.state.MarketValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Main Offering")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectMainOffering.bind(this)}
                    options={this.state.lstMainoffering} 
                    selected={this.state.MainOfferingValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_capability">
                        {i18n.t("Offering/SSG")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectCapability.bind(this)}
                    options={this.state.lstCapability} 
                    selected={this.state.CapabilityValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_tower">{i18n.t("Sub Offering")}</label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                    onChange={this.SelectTower.bind(this)}
                    options={this.state.lstTower} 
                    selected={this.state.TowerValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} /> 
                    </div>
                  </span>
                  <span>
                    <div className="form-group col-md-2">
                      <label id="lbl_serviceComponent">
                        {i18n.t("Process")}
                      </label>
                      <Typeahead emptyLabel={i18n.t('noMatchesFound')}
                  id="ddl_Market_ViewPreviousAssessmentSubmitted"
                   labelKey="label" 
                   onChange={this.SelectProcess.bind(this)}
                    options={this.state.lstprocess} 
                    selected={this.state.ProcessValue}
                     filterBy={this.filterCallbackTypeahead.bind(this)}
                     placeholder={i18n.t("selectPlace")} />
                    </div>
                  </span>
                </div>
              </div>
                            <h4 className="btn-group-md">{i18n.t('viewSavedDRAFTAssessment')}
                                <span className="badge">{recordCount}</span>
                                <div className="btn-group btn-group-pmmasmt">
                                    <button id="btn_downloadListOfAllSavedAssessmentsBasedOnYourAccessRights" type="button" className="btn btn-default btn-downld" onClick={this.DownloadExcel.bind(this)} title={i18n.t("DownloadlistofallsavedassessmentsPlease")}>
                                        <img src={downloadIcon} />
                                    </button>
                                </div>
                                <a type="button" className="btn btn-info pull-right" onClick={this.ClearFilter.bind(this)}>{i18n.t('clear')}</a>
                                <input id="txt_search" type="button" className="btn btn-danger pull-right" value={i18n.t("search")} title={i18n.t("PleaseselectatleastDealName")} onClick={this.GetAssessmentDetails.bind(this)} />
                            </h4>
                            <div className="AsmtData viewprevassmt">
                                {
                                    this.state.showLoaderFlag == true ?
                                        <div>
                                            <center><img src={loderIcon} /></center>
                                        </div>
                                        :
                                        <ViewDraftAssessmentGrid propsprevAssessmentGridData={this.state.prevAssessmentGridData} propsSelectedAssessmentDetails={this.props.propsSelectedAssessmentDetails} />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
    }
}

ViewPreviousAssessmentDraft = connect(mapStateToProps)(withRouter(ViewPreviousAssessmentDraft))
export default ViewPreviousAssessmentDraft;